const { default: React } = require("react");
const { useUpdatableLead } = require("../lib/leads/useUpdatableLead");
const { default: StatusValue } = require("./leads/values/status_value");

// todo(3) finish
export default function LeadRow(props) {
    const {
        id,
        firstname,
        lastname,
        email,
        phone_number_concatenated,
        zipcode,
        year_of_birth,
        housing_status,
        commentaire,
        statut,
        income_tax,
        assigned_to,
        profession,
        family,
        legal_status,
        adresse,
        enfant,
        city,
        departement,
        company_name,
        company_industry,
        region,
        job_started_on,
        data_source,
        priority,
    } = props.lead;
    const {
        setConfirmation,
        list,
        setPopupCreateReminderLead,
        reminders,
        setToast,
        handleLeadUpdated,
        handleUpdateStatutCounts,
        columns,
    } = props;

    const {
        updateLeadNUI,
        newPriority,
        updatePriority,
        updateFirstname,
        updateLastname,
        handleCopy,
        handleTransferToSalesman,
        newStatut,
        updateStatut,
        newPhone,
        setNewPhone,
        newEmail,
        setNewEmail,
        newCity,
        setNewCity,
        newDepartement,
        setNewDepartement,
        newBirthYear,
        setNewBirthYear,
        newIncomeTax,
        setNewIncomeTax,
        newHousingStatus,
        setNewHousingStatus,
        newFamily,
        setNewFamily,
        newZipcode,
        setNewZipcode,
        newLegalStatus,
        setNewLegalStatus,
        newCompanyIndustry,
        setNewCompanyIndustry,
        newJobStartedOn,
        setNewJobStartedOn,
        newComment,
        setNewComment,
        updateComment,
        newRegion,
        setNewRegion,
        newProfession,
        setNewProfession,
        newAdress,
        setNewAdress,
        newCompanyName,
        setNewCompanyName,
    } = useUpdatableLead({
        id,
        priority,
        lastname,
        firstname,
        commentaire,
        email,
        phone_number_concatenated,
        zipcode,
        year_of_birth,
        housing_status,
        statut,
        income_tax,
        profession,
        family,
        legal_status,
        adresse,
        enfant,
        city,
        departement,
        company_name,
        company_industry,
        region,
        job_started_on,
        handleLeadUpdated,
        handleUpdateStatutCounts, // todo(3) finish this table and use this among others thing, then reread all leads.js
        setToast,
        setConfirmation,
    });

    // todo(3) reread and improve if need be
    const getRowContent = (idx, column, value) => {
        if (column === "statut") {
            return <StatusValue
                leadId={id}
                displayOpeningArrow={false}
                newStatus={newStatut}
                updateStatut={updateStatut}
                setConfirmation={setConfirmation}
                setToast={setToast}
                positionAtRight={idx < 3}
            />;
        }

        return value;
    }

    const getCopyPastRow = () => {
        return <td key="action_copy_paste" className="nova_table_case short">
            <div className="nova_fontions-lead">
                <img onClick={handleCopy} className="nova_action_img" src="./images/nova/copy_paste.svg" loading="lazy" alt="" />
            </div>
        </td>;
    }

    const getAddRemindeRow = () => {
        return <td key="action_add_reminder" className="nova_table_case short">
            <div onClick={() => setPopupCreateReminderLead(props.lead)} className="nova_fontions-lead">
                <img className="nova_action_img" src="./images/nova/reminder_black.svg" loading="lazy" alt="" />
            </div>
        </td>;
    }

    const getTransfer2salesmanRow = () => {
        return <td key="action_transfer2salesman" className="nova_table_case short">
            <div className="nova_fontions-lead">
                <img onClick={handleTransferToSalesman} className="nova_action_img" src="./images/nova/Icons-copie-3_1.svg" loading="lazy" alt="" />
            </div>
        </td>;
    }

    return <tr key={id} className="nova_table_row">
        {getAddRemindeRow()}
        {getCopyPastRow()}
        {getTransfer2salesmanRow()}
        {columns.map((column, idx) => {
            const isExpandable = column === "statut" || column === "priority";
            const classSuffix = isExpandable ? " no_overflow" : "";
            return <td className={`nova_table_case${classSuffix}`} key={column}>
                {getRowContent(idx, column, props.lead[column])}
            </td>;
        })}
    </tr>;
}
