import React, { useEffect, useRef } from 'react';


export default function Toast({ text, severity, duration, setToast }) {
    /*
        severity in: success, info, warning, error
    */
    const timeoutId = useRef(null);

    const defaultDuration = 5; // seconds

    const handleClose = () => {
        setToast(null);
    };

    // hide after duration after the last time text or severity or duration is updated
    useEffect(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        timeoutId.current = setTimeout(() => {
            handleClose();
        }, (duration || defaultDuration) * 1000);
    }, [text, severity, duration]);

    const getTitle = () => {
        if (severity === "success") {
            return "Succès";
        } else if (severity === "info") {
            return "Information";
        } else if (severity === "warning") {
            return "Attention";
        } else if (severity === "error") {
            return "Erreur";
        }
    }

    const getClassSuffix = () => {
        if (severity === "success") {
            return " success";
        } else if (severity === "info") {
            return " info";
        } else if (severity === "warning") {
            return " warning";
        } else if (severity === "error") {
            return "";
        }
    }

    return (
        <div className="toast_positionner">
            <div className={`nova_alert${getClassSuffix()}`}>
                <div className="nova_icon-text-alert">
                    <img src={`./images/nova/toast_${severity}.svg`} className="nova_toast_icon" loading="lazy" alt="" />
                    <div className="nova_flex-v-text">
                        <div className="nova_title-alert">{getTitle()}</div>
                        <div className="nova_description-alert">{text}</div>
                    </div>
                </div>
                <div onClick={handleClose} className="nova_close-alerte w-inline-block">
                    <img src="./images/nova/cross_darkgrey.svg" loading="lazy" alt="" className="nova_toast_close_buttn" />
                </div>
            </div>
        </div>
    );
}
