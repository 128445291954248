import React, { useState } from "react";
import { LeadsFieldsInputType, LeadsFieldsShouldCapitalizeFirstLetter, TestAircall } from "../context/enums";
import { capitalizeFirstLetter } from "../lib/tools/strings";


function LeadField(props) {

    const { label, value, champ, isLast, updateCallback, updateUICallback, setToast } = props;
    const [newValue, setNewValue] = useState(value);

    const updateChamp = () => {
        updateCallback(newValue);
        updateUICallback(champ, newValue).catch(err => {
            setToast({ text: "Echec, mise à jour des données.", severity: "error" });
            console.error(err);
        });
    }

    // if (LeadsFieldsWithDefinedValues[champ]) { // todo(1) remettre drop down list
    //     const optionsObject = LeadsFieldsWithDefinedValues[champ];
    //     return <div className="la_holder-input-info">
    //         <div className="la_text-block-32">Situation familiale</div>
    //         <select className="la_text-field-4 situation-familiale w-select"
    //             onChange={(e) => setNewValue(e.target.value)}
    //             onBlur={updateChamp}
    //             value={newValue}>
    //             <option key={"empty"} value="">{"Inconnue"}</option>
    //             {Object.keys(optionsObject).map((key) => (
    //                 <option key={key} value={key}>
    //                     {optionsObject[key]}
    //                 </option>
    //             ))}
    //             {!Object.keys(optionsObject).includes(newValue)
    //                 ? <option key={"other__value"} value={newValue}>{newValue}</option>
    //                 : null}
    //         </select>
    //     </div>;
    // }

    const shouldCapitalizeFirstLetter = Boolean(LeadsFieldsShouldCapitalizeFirstLetter[champ]);
    const newValueCleaned = shouldCapitalizeFirstLetter && newValue ? capitalizeFirstLetter(newValue) : newValue;

    const inputType = LeadsFieldsInputType[champ] ? LeadsFieldsInputType[champ] : "text";

    const mainClassSuffix = isLast ? " noborder" : "";
    const hideLabelInBigScreen = newValueCleaned && newValueCleaned.length > 15;
    const styleSuffix = hideLabelInBigScreen ? " hide_label_in_small_screen" : "";
    return <div className={`nova_holder-info-lead${mainClassSuffix}`}>
        <div className="nova_holder-text-info">
            <div className={`nova_info-text${styleSuffix}`}>{label}</div>
        </div>
        <div className="nova_info-input w-form">
            <input className={`nova_input-info-lead${styleSuffix} w-input`}
                maxLength={255}
                placeholder="-"
                type={inputType}
                value={newValueCleaned || ""}
                onBlur={updateChamp}
                onChange={(e) => setNewValue(e.target.value)} />
            {(champ === "phone_number_concatenated" && TestAircall) ? <div className="try_aircall"></div> : null}
        </div>
    </div>;
}

export default LeadField;
