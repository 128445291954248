import { useEffect, useState } from "react";
import { getUserMetrics } from "../../api/axios";


// todo(3) bcp de code en commun avec useDaysUsage dont le nom est pas clair
export function useUserDaysUsage({ userId, duration, job }) {
    const [daysUserUsageData, setDaysUserUsageData] = useState([]);

    useEffect(() => {
        if (!userId || userId === "__undefined__") {
            return;
        }

        setDaysUserUsageData([]);
        const cleanedJob = job === "__undefined__" ? null : job;
        getUserMetrics(userId, duration, cleanedJob).then(data => {
            const cleanedData = data.data.map(row => {
                return {
                    day: row.day,
                    work_done: parseInt(row.work_done),
                    rdv_taken: parseInt(row.rdv_taken),
                    signings: parseInt(row.signings),
                    rdv_booked: parseInt(row.rdv_booked),
                    rdv_booked_per_work_done: parseFloat(row.rdv_booked_per_work_done),
                };
            });
            setDaysUserUsageData(cleanedData);
        });
    }, [userId, duration, job]);

    return [
        daysUserUsageData,
    ];
}
