import React, { useRef, useState } from 'react';

const DraggableTable = () => {
  const [columns, setColumns] = useState(['Column 1', 'Column 2', 'Column 3']);
  const [data, setData] = useState([
    ['Row 1, Col 1', 'Row 1, Col 2', 'Row 1, Col 3'],
    ['Row 2, Col 2__________________1', 'Row 2, Col 2__________________2__________________2__________________2__________________', 'Row 2, Col 3'],
  ]);

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const tempColumns = useRef([...columns]); // Temporary column state

  const handleDragStart = (e, index) => {
    dragItem.current = index;
    console.log("Drag Start:", columns[index]);
    e.dataTransfer.effectAllowed = 'move'; // Ensure drag effect is 'move', remove the ugly + icon while dragging
  };

  const handleDragEnter = (e, index) => {
    dragOverItem.current = index;
    console.log("Drag Enter:", columns[index]);

    // reorder the columns headers live
    if (dragItem.current !== index) {
      const columnCopy = [...tempColumns.current];
      const dragItemContent = columnCopy[dragItem.current];
      columnCopy.splice(dragItem.current, 1);
      columnCopy.splice(index, 0, dragItemContent);

      tempColumns.current = columnCopy; // Update temporary state
      setColumns(tempColumns.current); // Update the visible columns
      // dragItem.current = index; // Update dragItem index
    }
  };

  const handleDragEnd = (e) => {
    if (dragItem.current === null || dragOverItem.current === null) return; //prevent errors
    const dataCopy = data.map(row => [...row]); //deep copy of the data array.

    // used to reorder the columns headers as well when we dropped
    // const columnCopy = [...columns];
    // const dragItemContent = columnCopy[dragItem.current];
    // columnCopy.splice(dragItem.current, 1);
    // columnCopy.splice(dragOverItem.current, 0, dragItemContent);

    const newData = dataCopy.map(row => {
      const draggedItem = row[dragItem.current];
      row.splice(dragItem.current, 1);
      row.splice(dragOverItem.current, 0, draggedItem);
      return row;
    });

    dragItem.current = null;
    dragOverItem.current = null;
    // setColumns(columnCopy);
    setData(newData);
  };

  return (
    <div className='nova_crm'>
      <div>
        <div className='test_full_width'>
          <h1>hello</h1>
          <p>end</p>
        </div>
        <div className='nova_holder-crm-infoleads' style={{ overflowX: 'auto' }}> {/* Container for the table */}
          <table className="nova_table">
            <thead className="nova_thead">
              <tr className="nova_up-bar-info2">
                {columns.map((column, index) => (
                  <th
                    className="nova_th"
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragEnter={(e) => handleDragEnter(e, index)}
                    onDragEnd={handleDragEnd}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="nova_holder-vertical-leads2">
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="nova_table_row">
                  {row.map((cell, cellIndex) => (
                    <td className="nova_table_case" key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DraggableTable;
