import React from "react";
import { AllStatus, LeadStatus, StatusColor } from "../../context/enums";
import { getStatutLabel } from "../../lib/tools/app_context";

export default function StatusDropdown({
    handleStatusClicked,
    automaticalyVisible,
    isStatusUpdate,
    useAllStatus,
    positionAtRight,
}) {
    const getStatusBlock = (status) => {
        const statusSuffix = status === AllStatus ? "all" : StatusColor[status];
        return <div key={status} onClick={() => handleStatusClicked(status)} className='nova_status-type-2nd-border'>
            <div key={status} className={`nova_status-type ${statusSuffix}`}>
                <div className={`nova_dot-status ${statusSuffix}`}></div>
                <div className={`nova_status-text ${statusSuffix}`}>{getStatutLabel(status)}</div>
            </div>
        </div>;
    }

    const getStatusFilterElements = (startIndex, endIndex) => {
        return Object.values(LeadStatus).slice(startIndex, endIndex).map((status) => {
            return getStatusBlock(status);
        })
    }

    const getClassSuffix = () => {
        let result = "";
        if (positionAtRight) {
            result += " position_right"
        }
        if (automaticalyVisible) {
            result += " visible";
        }
        if (isStatusUpdate) {
            result += " status_update";
        }
        return result;
    }

    const secondRowEnd = useAllStatus ? 3 : 4;
    const thirdRowEnd = useAllStatus ? 7 : 8;
    return <div className={`nova_hovered_filter_container${getClassSuffix()}`}>
        <div className="nova_status-modal">
            <div className="nova_holder-status-modals">
                {useAllStatus ? getStatusBlock(AllStatus) : null}
                {getStatusFilterElements(0, secondRowEnd)}
            </div>
            <div className="nova_holder-status-modals">
                {getStatusFilterElements(secondRowEnd, thirdRowEnd)}
            </div>
            <div className="nova_holder-status-modals">
                {getStatusFilterElements(thirdRowEnd, 12)}
            </div>
        </div>
    </div>;
}
