import React from "react";


export default function Step({ step, totalSteps }) {
    const getSteps = () => {
        const steps = [];
        for (let i = 0; i < totalSteps; i++) {
            steps.push(<div key={i} className={`nova_steps ${i === step - 1 ? "active" : ""}`}></div>);
        }
        return steps;
    }

    return <div className="nova_holder-login-steps">
        <div className="nova_line-steps">
            {getSteps()}
        </div>
        <div className="text-block-44">Etape {step} <span className="text-span-10">of {totalSteps} </span></div>
    </div>;
}
