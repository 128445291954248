import React, { useContext, useEffect, useState } from "react";

import {
    clearNRP,
    getCompanyLeadsCount,
    getCompanyUsers,
    getNRPCount,
    getUnassignedCompanyLeadsCount
} from "./api/axios";

import { Grid, InputLabel } from '@mui/material';
import AuthContext from "./context/AuthProvider";
import { useDifferentLists } from './lib/leads/useDifferentLists';

import AssignLeads from "./component/admin/assign_leads";
import ImportLeads from "./component/admin/import_leads";
import UnassignLeads from "./component/admin/unassign_leads";
import "./style/Collaborators.css";
import ManagementContainer from "./component/management/management_container";
import NovaInput from "./component/ux/common/nova_input";
import NovaButton from "./component/ux/common/nova_button";

function Stat(props) {
    const { label, value, color } = props;

    return (
        <div className="stat">
            <p className="stat-label">{label}</p>
            <p className="stat-value" style={{ color }}>{value}</p>
        </div>);
}

// function Input(props) {
//     const { label, onChange } = props;

//     return (
//         <div className="input">
//             <p className="input-label">{label}</p>
//             <input className="input-value" placeholder={label} onChange={onChange} />
//         </div>);
// }

export default function LeadsAttribution({ setToast, setConfirmation }) {

    const { auth, listsIds, color } = useContext(AuthContext);

    const [collaborators, setCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState("__undefined__");

    const [nrpCount, setNrpCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [notAssignedCount, setNotAssignedCount] = useState(0);

    useEffect(() => {
        if (!auth) {
            return;
        }

        getCompanyUsers().then(res => setCollaborators(res.data));
        getCompanyLeadsCount(auth.company_id).then((res) => { if (res) setTotalCount(res.data) });
        getUnassignedCompanyLeadsCount(auth.company_id).then((res) => { if (res) setNotAssignedCount(res.data) });
        getNRPCount(auth.company_id).then((res) => { if (res) setNrpCount(res.data) });
    }, [auth]);

    const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists] = useDifferentLists({ auth, accessibleListIds: listsIds, addAllLeadsList: false });

    const getCollaborateurComponent = () => {
        if (selectedCollaborator === "__undefined__") {
            return null;
        }

        const collaborateurRows = collaborators.filter(colab => colab.id === selectedCollaborator);
        if (collaborateurRows.length === 0) {
            return null;
        }

        const collaborateurInfo = collaborateurRows[0];

        return <AssignLeads
            key={`assign_${listIdIdx}_${collaborateurInfo.id}`}
            user={collaborateurInfo}
            setToast={setToast}
            setConfirmation={setConfirmation}
            list={list}
            color={color}
            auth={auth} />;
    }

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        let suffix = `${collab.email}`;
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname} - ${suffix}`;
    }

    const getMainComponent = (list) => {
        if (!auth) {
            return null;
        }

        if (allLists === null) {
            return null;
        }

        return <Grid container spacing={2} style={{ padding: '10px' }}>
        <ManagementContainer title={"Attribuer des leads"} description={"Attribuer des leads"}>
            {getAccessibleListsIds().length >= 2
                ? <Grid item xs={12} key="select_list">
                    <InputLabel>Sélectionnez une liste de leads:</InputLabel>
                    <NovaInput
                            type="select"
                            value={listIdIdx}
                            onChange={(ev) => setListIdIdx(ev.target.value)}
                            options={getAccessibleListsIds().map((listId, idx) => {
                                const list = allLists.find(listDetails => listDetails.id === listId);
                                return { value: idx, label: list.name };
                            })}
                        />
                    <br />
                </Grid> : null}

            <Grid item xs={12} key="select_worker">
                <InputLabel>Sélectionnez un collaborateur:</InputLabel>
                <NovaInput
                        type="select"
                        value={selectedCollaborator}
                        onChange={(ev) => setSelectedCollaborator(ev.target.value)}
                        options={[{ value: "__undefined__", label: "Veuillez choisir" },
                            ...collaborators.map(collab => ({ value: collab.id, label: getCollabdisplayString(collab) }))]}
                    />
            </Grid>
            {getCollaborateurComponent()}
            </ManagementContainer>
  

            <UnassignLeads
                key={`unassign_${listIdIdx}`}
                auth={auth}
                collaborators={collaborators}
                setToast={setToast}
                setConfirmation={setConfirmation}
                color={color} />

            <ImportLeads key={`import_leads_${listIdIdx}`} setToast={setToast} />

            <Grid item xs={12} key="space_after_un_assignment">
                <br />
                <br />
            </Grid>
        </Grid>
    }

    if (!auth || !color || listsIds === null) {
        return null;
    }

    if (allLists === null) {
        return null;
    }

    const listId = getAccessibleListsIds()[listIdIdx];
    let list = allLists?.filter((listDetails) => listDetails.id === listId)[0];

    const handCleanNRPClick = () => {
        clearNRP(auth.company_id)
            .then(() => getNRPCount(auth.company_id))
            .then((res) => {
                setToast({ text: "NRP retirés.", severity: "success" });
                setNrpCount(res.data);
            }).catch((err) => {
                console.error(err);
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
            });
    }

    return (
        <>
            <br />
            <ManagementContainer title={'Gestion des leads'} description="Attribution des leads">
            <div className="leads-stat">
                <Stat label={"Total leads"} value={totalCount} color={color} />
                <Stat label={"Leads non assigné"} value={notAssignedCount} color={color} />
            </div>
            <div className="nrp-col">
                <p className="nrps" style={{ color }}>{nrpCount + " NRP"}</p>
                <NovaButton onClick={handCleanNRPClick}>Récupérer tous les NRP</NovaButton>
            </div>
            </ManagementContainer>
            {getMainComponent(list)}
        </>
    );
}
