import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getRecentHotLeads, storeFeatureUsage } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { date2shortFrenchString } from "../../../lib/tools/datetime";

export default function AttributeHotLeads({ setToast, setConfirmation }) {
    const { auth, color } = useContext(AuthContext);

    const [hotLeads, setHotLeads] = useState([]);

    const fetchHotLeads = async () => {
        getRecentHotLeads().then(data => {
            console.log("get hot leads", data.data);
            setHotLeads(data.data);
        });
    };

    useEffect(() => {
        fetchHotLeads();
        // todo(1) refresh everytime it rebecomes visible
        const intervalId = setInterval(fetchHotLeads, 5 * 60 * 1000); // every 5 minutes

        return () => clearInterval(intervalId);
    }, [auth]);

    const handleAttributeLead = (leadId) => {
        setConfirmation({
            "text": "Transférez ce lead à un vendeur.",
            "showYesButton": false,
            "showNoButton": false,
            "specialPopup": { type: "transfer2Salesman", leadId, companyId: auth.company_id, emailNotification: true },
            "confirmCallback": (userId) => {
                fetchHotLeads();
                storeFeatureUsage(auth.id, "transferHot2salesman", JSON.stringify({ leadId: leadId, userId }));
            }
        });
    }

    function getAssignedToComponent(row) {
        if (!row.assigned_to) {
            return <Button onClick={() => handleAttributeLead(row.id)} variant="contained" style={customButtonStyle}>
                Attribuer
            </Button>
        }

        return `${row.assigned_to_firstname} ${row.assigned_to_lastname}`;
    }

    function getStatuText(statut) {
        if (statut === null) { // todo(1) not afunction that manage this ?
            return "Sans statut";
        }

        return statut;
    }

    const customButtonStyle = {
        backgroundColor: color,
        color: 'white',
        fontSize: '0.75rem', // smaller text
        padding: '4px 8px', // smaller padding
        minWidth: 'auto', // smaller button
    };

    if (!auth) {
        return null;
    }

    return (
        <>
            <br />
            <h3 className="title" style={{ color }}>Leads chauds récents</h3>
            <Grid container spacing={2} style={{ padding: '10px' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date de création</TableCell>
                                <TableCell>Assigné à</TableCell>
                                <TableCell>Téléphone</TableCell>
                                <TableCell>Revenus</TableCell>
                                <TableCell>Statut</TableCell>
                                <TableCell>Code postal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hotLeads.map((row) => {
                                return <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">{date2shortFrenchString(new Date(row.created_date))}</TableCell>
                                    <TableCell component="th" scope="row">{getAssignedToComponent(row)}</TableCell>
                                    <TableCell component="th" scope="row">{row.phone_number_concatenated}</TableCell>
                                    <TableCell component="th" scope="row">{row.income_tax}</TableCell>
                                    <TableCell component="th" scope="row">{getStatuText(row.statut)}</TableCell>
                                    <TableCell component="th" scope="row">{row.zipcode}</TableCell>
                                </TableRow>;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
}
