import React from "react";
import ManagemementHeader from "./management_header_block";

export default function ManagementContainer({ title, description, children }) {
  return (
    <div className='nova_admin-collaborateur-div'>
      <ManagemementHeader title={title} description={description} />
      <div className='nova-holder-buttons-collaboration'>
        {children}
      </div>
    </div>
  );
}