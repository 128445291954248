import React, { useContext, useState } from "react";
import { addMessageToThread, createChatbotThreads } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import ChatBotBottomMenu from "../chatbot/bottom_menu";
import InputMessage from "../chatbot/input_message";
import PopupChatbotWithMessages from "../chatbot/popup_chatbot_with_messages";
import ChatBotTopMenu from "../chatbot/top_menu";
import ViewButtons from "../chatbot/view_buttons";


const AddLeadsMessge = { role: "assistant", type: "display_add_b2bmed" };


// todo(3) renommer c'est pas une popup
export default function PopupChatbot({ handleCloseButton }) { // todo(1) lui ajouter une key qu il se recree a chaque fois?
    const { auth } = useContext(AuthContext);

    const [isWaiting, setIsWaiting] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState("");
    const [conversationId, setConversationId] = useState(null); // if null then no thread was started

    const reinitializeConversation = () => {
        setIsWaiting(false);
        setMessages([]);
        setInputText("");
        setConversationId(null);
    }

    const handleAddUserMessage = () => {
        setInputText("");
        setIsWaiting(true);
        if (conversationId === null) {
            setMessages([{ role: "user", text: inputText }]);
            createChatbotThreads(inputText).then((data) => {
                setConversationId(data.data.conversation_id);
                setMessages(data.data.messages);
                setIsWaiting(false);
                // reuploadLeads(); // todo(1) gerer cela quand une instruction est renvoyée
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
        } else {
            setMessages(prevMessages => [...prevMessages, { role: "user", text: inputText }]);
            addMessageToThread(inputText, conversationId).then((data) => {
                setMessages(data.data.messages);
                setIsWaiting(false);
                // reuploadLeads(); // todo(1) gerer cela quand une instruction est renvoyée
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleAddUserMessage();
        }
    };

    const getIntroPart = () => {
        return <div className="nova_holder-up-part-chatbot">
            <div className="nova_holder-icon-star-chatbot">
                <img src="./images/nova/star_1.svg" loading="lazy" alt="" />
            </div>
            <div className="niva_title-chatbot">Bonjour {auth.firstname}<br />Comment puis-je vous aider ?</div>
            <div className="nova_subtitle-chatbot">Bonjour, je suis l'IA de NOVA. Je peux vous aider à réaliser de nombreuses tâches comme ajouter ou enlever des leads.</div>
        </div>;
    }

    const getRightPart = () => {
        return <div className="nova_page-chatbot" style={{ gridRow: 'span 2' }}>
            <ViewButtons handleCloseButton={handleCloseButton} />
            <div className="nova_holder-content-chatbot">
                {getIntroPart()}
                <InputMessage
                    inputText={inputText}
                    setInputText={setInputText}
                    handleKeyDown={handleKeyDown}
                    handleAddUserMessage={handleAddUserMessage}
                />
            </div>
        </div>;
    }

    if (messages.length > 0) {
        return <PopupChatbotWithMessages
            messages={messages}
            handleCloseButton={handleCloseButton}
            inputText={inputText}
            setInputText={setInputText}
            handleKeyDown={handleKeyDown}
            setMessages={setMessages}
            handleAddUserMessage={handleAddUserMessage}
            isWaiting={isWaiting}
            setIsWaiting={setIsWaiting}
            reinitializeConversation={reinitializeConversation}
        />;
    }

    return <div className="nova_holder-software-grid chatbot">
        <ChatBotTopMenu />
        {getRightPart()}
        <ChatBotBottomMenu reinitializeConversation={reinitializeConversation} />
    </div>;
}
