import React, { useContext } from "react";
import { storeFeatureUsage } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { useAddLeads } from "../../../lib/add_leads/useAddLeads";
import { setNumericInput } from "../../../lib/tools/strings";
import AIMessage from "../basics/ai_message";
import AIMessageText from "../basics/ai_message_text";
import ButtonValid from "../basics/button_valid";
import MessageFormContainer from "../basics/message_form_container";
import MessageFormDropDown from "../basics/message_form_dropdow";
import MessageFormInput from "../basics/message_form_input";
import MessageFormSearch from "../basics/message_form_search";


export default function AddLeads({ setMessages, setIsWaiting, setInputText }) {
    const { auth } = useContext(AuthContext);

    const handleNoLeadCountProvided = (message) => {
        setMessages(prevMessage => [...prevMessage, { role: "assistant", text: message }]);
    }

    const handleAddLeadsStarted = () => {
        setInputText("");
        setIsWaiting(true);
        storeFeatureUsage(auth.id, "chatbot_add_b2bmed", null);
    }

    const handleSuccess = (newMessage) => {
        setMessages(prevMessage => [...prevMessage, { role: "assistant", text: newMessage }]);
        setIsWaiting(false);
    }

    const handleFailure = (errorMessage) => {
        setIsWaiting(false);
        setMessages(prevMessage => [...prevMessage, { role: "assistant", text: errorMessage }]);
    }

    const {
        leadsCount,
        setLeadsCount,
        setSelectedJobCategory,
        jobCatOptions,
        setSelectedDataSource,
        dataSourceOptions,
        selectedJob,
        setSelectedJob,
        jobsFound,
        handleAddB2BMedLeads,
    } = useAddLeads({
        handleNoLeadCountProvided,
        handleAddLeadsStarted,
        handleSuccess,
        handleFailure,
    });

    return <AIMessage containsForm={true}>
        <AIMessageText
            text="Sélectionnez les leads B2B Médical que vous voulez vous attribuez"
        />
        <MessageFormContainer>
            <MessageFormInput
                label="Nombre de leads"
                placeholder="Entrer le nombre"
                value={leadsCount}
                setValueFromRawEvent={true}
                setValue={(e) => setNumericInput(e, setLeadsCount)}
            />
            <MessageFormDropDown
                title="Catégories de métiers"
                initialLabel="Toutes les catégories"
                setValue={setSelectedJobCategory}
                options={jobCatOptions}
            />
            <MessageFormDropDown
                title="Sources de données"
                initialLabel="Toutes les sources"
                setValue={setSelectedDataSource}
                options={dataSourceOptions}
            />
            <MessageFormSearch
                label="Métier"
                placeholder="Entrez le métier"
                value={selectedJob}
                setValue={setSelectedJob}
                potentialValuesFound={jobsFound}
            />
            <ButtonValid label="Obtenir les leads" handleClick={handleAddB2BMedLeads} />
        </MessageFormContainer>
    </AIMessage>;
}
