
// todo(3) voir les trucs suivants dans status dropdown pk pas ici
// handleStatusClicked,
// automaticalyVisible,

import React from "react";
import { Priorities } from "../../context/enums";

// isStatusUpdate,
export default function PriorityDropdown({
    handlePriorityClicked,
    automaticalyVisible,
    // isStatusUpdate,
}) {
    const getPriorityFilterElements = () => {
        return Object.keys(Priorities).map((prio) => {
            const label = Priorities[prio];

            // no subclass exists for CSS '.nova_priorities.low'
            return <div key={prio}
                onClick={() => handlePriorityClicked(prio)}
                className={`nova_priorities active ${prio} w-inline-block`}>
                <div className="nova_prioprities-text">{label}</div>
            </div>;
        })
    }

    const getClassSuffix = () => {
        let result = "";
        if (automaticalyVisible) {
            result += " visible";
        }
        // if (isStatusUpdate) {
        //     result += " status_update";
        // }
        return result;
    }

    return <div className={`nova_hovered_filter_container${getClassSuffix()}`}>
        <div className="nova_priority-dropdown">
            {getPriorityFilterElements()}
            <div onClick={() => handlePriorityClicked("")}
                className="nova_priority-type bar w-inline-block">
                <div>Toutes</div>
            </div>
        </div>
    </div>;
}
