import { Box, CircularProgress } from "@mui/material";
import React from "react";
import AIMessage from "./basics/ai_message";
import AIMessageText from "./basics/ai_message_text";
import UserMessageText from "./basics/user_message_text";
import ChatBotBottomMenu from "./bottom_menu";
import InputMessage from "./input_message";
import AddLeads from "./messages/add_leads";
import RemoveLeads from "./messages/remove_leads";
import ReportBug from "./messages/report_bug";
import ChatBotTopMenu from "./top_menu";
import ViewButtons from "./view_buttons";


export default function PopupChatbotWithMessages({
    messages,
    handleCloseButton,
    inputText,
    setInputText,
    handleKeyDown,
    setMessages,
    handleAddUserMessage,
    isWaiting,
    setIsWaiting,
    reinitializeConversation,
}) {
    const getMessageComponent = (msg, idx) => {
        if (msg.role === "assistant" && msg.type === "display_add_b2bmed") {
            return <AddLeads
                key={idx}
                setMessages={setMessages}
                setIsWaiting={setIsWaiting}
                setInputText={setInputText}
            />;
        } else if (msg.role === "assistant" && msg.type === "display_bug_report") {
            return <ReportBug key={idx} />;
        } else if (msg.role === "assistant" && msg.type === "remove_leads") {
            return <RemoveLeads
                key={idx}
                setInputText={setInputText}
                setIsWaiting={setIsWaiting}
                setMessages={setMessages}
            />;
        } else if (msg.role === "assistant") {
            return <AIMessage key={idx} containsForm={false}>
                <AIMessageText text={msg.text} />
            </AIMessage>;
        } if (msg.role === "user") {
            return <UserMessageText key={idx} text={msg.text} />;
        }
    }

    const getChatInputPart = () => {
        return <div className="nova_absolut-holder-input-conversation">
            <InputMessage
                inputText={inputText}
                setInputText={setInputText}
                handleKeyDown={handleKeyDown}
                handleAddUserMessage={handleAddUserMessage}
            />
        </div>;
    }

    const getRightPart = () => {
        return <div className="nova_holder-conversation-chatbot" style={{ gridRow: 'span 2' }}>
            <ViewButtons handleCloseButton={handleCloseButton} />
            <div className="nova_holder-msg-conversation">
                {messages.map((msg, idx) => getMessageComponent(msg, idx))}
                {isWaiting && <Box key="wait" sx={{ display: 'flex', paddingTop: '10px', width: '100%' }}>
                    <CircularProgress sx={{ margin: '0 auto' }} />
                </Box>}
            </div>
            {getChatInputPart()}
        </div>;
    }

    return <div className="nova_holder-software-grid chatbot">
        <ChatBotTopMenu />
        {getRightPart()}
        <ChatBotBottomMenu reinitializeConversation={reinitializeConversation} />
    </div>
}
