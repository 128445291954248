import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext, useState } from 'react';
import AddWorker from './component/admin/add_worker';
import AttributeHotLeads from './component/admin/table/attribute_hot_leads';
import LeadsPageCenter from './component/leads/leads_page_center';
import MenuBottom from './component/menu_bottom';
import MenuTop from './component/menu_top';
import CompanyMetrics from './component/metrics/company_metrics';
import CustomersUsage from './component/metrics/customers_usage';
import SoftwareManagement from './component/metrics/software_management';
import PageTitle from './component/page_title';
import PopupChatbot from './component/popups/popup_chatbot';
import NotificationCenter from './component/reminders/notification_center';
import ConfirmNotification from './component/ux/confirmation';
import Toast from './component/ux/toast';
import AuthContext from "./context/AuthProvider";
import { UserType } from './context/enums';
import LeadsAttribution from './leads_attribution';
import { useReminders } from './lib/reminders/useReminders';
import { useVersionChecking } from './lib/versions/useVersionChecking';


const VIEWS_PROPERTIES = new Map();
VIEWS_PROPERTIES.set("leads_attribution", {
    buttonText: "Attribution des leads",
});
VIEWS_PROPERTIES.set("hot_leads_attribution", {
    buttonText: "Leads chauds",
});
VIEWS_PROPERTIES.set("all_leads", {
    buttonText: "Tous les leads",
});

const DEFAULT_VIEWS = [...VIEWS_PROPERTIES.keys()];


export default function AdminLeads() {
    const [viewIdx, setViewIdx] = useState(0);
    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [displayNotificationCenter, setDisplayNotificationCenter] = useState(false);
    const [displayChatbot, setDisplayChatbot] = useState(false);

    useVersionChecking({ setConfirmation, type: UserType.MANAGER });

    const { auth, color, openMenu } = useContext(AuthContext);

    const [reminders, reloadReminders, markReminderLocallyAsViewed] = useReminders({ auth });

    const getViews = () => {
        let result = [...DEFAULT_VIEWS];

        if (!auth) {
            return result;
        }

        return result;
    }

    const getCentralComponent = () => {
        let viewName = getViews()[viewIdx];
        if (viewName === "leads_attribution") {
            return <LeadsAttribution setToast={setToast} setConfirmation={setConfirmation} />;
        } else if (viewName === "add_worker") {
            return <AddWorker setToast={setToast} />;
        } else if (viewName === "company_metrics") {
            return <CompanyMetrics setToast={setToast} />;
        } else if (viewName === "companies_usage") {
            return <CustomersUsage setToast={setToast} />;
        } else if (viewName === "software_management") {
            return <SoftwareManagement setToast={setToast} />;
        } else if (viewName === "hot_leads_attribution") {
            return <AttributeHotLeads setToast={setToast} setConfirmation={setConfirmation} />;
        }

        return null;
    }

    const getTabsComponents = () => {
        return getViews().map((view, idx) => {
            let buttonText = VIEWS_PROPERTIES.get(view).buttonText;
            return <Tab key={view} label={buttonText} sx={{ color: '#090425', '&.Mui-selected': { color: '#090425' } }} />
        });
    }

    const getMainComponent = () => {
        if (!auth || auth.user_type !== UserType["MANAGER"]) {
            return null;
        }

        let viewName = getViews()[viewIdx]; // todo(1) code in double, we should remove old_body etc.. here
        if (viewName === "all_leads") {
            // todo(3) addsetPopupCreateReminderLead
            return <LeadsPageCenter
                setToast={setToast}
                setConfirmation={setConfirmation}
                setPopupCreateReminderLead={() => { }}
                addAllLeadsList={true}
            />;
        }

        return <div className="la_column_section">
            <div className="old_body">
                <Box>
                    <Tabs
                        value={viewIdx}
                        onChange={(event, newView) => setViewIdx(newView)}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{ "& .MuiTabs-indicator": { backgroundColor: '#090425' } }}
                        aria-label="scrollable auto tabs example">
                        {getTabsComponents()}
                    </Tabs>
                </Box>
                {getCentralComponent()}
            </div>
        </div>
    }

    if (!auth || auth.user_type !== UserType["MANAGER"]) {
        return null;
    }

    const mainClassexSuffix = openMenu ? "" : " menu_closed";
    return <>
        {displayChatbot ? <PopupChatbot handleCloseButton={() => setDisplayChatbot(false)} /> : null}
        <section className="nova_holder-software-grid">
            <NotificationCenter
                display={displayNotificationCenter}
                setDisplay={setDisplayNotificationCenter}
                reminders={reminders}
                reloadReminders={reloadReminders}
                markReminderLocallyAsViewed={markReminderLocallyAsViewed}
                setToast={setToast}
            />
            {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
            {confirmation !== null && <ConfirmNotification title={confirmation.title}
                text={confirmation.text}
                confirmCallback={confirmation.confirmCallback}
                setConfirmation={setConfirmation}
                color={color}
                showYesButton={confirmation.showYesButton}
                showNoButton={confirmation.showNoButton}
                specialPopup={confirmation.specialPopup}
                setToast={setToast} />}
            <MenuTop currentView={"admin_leads"} />
            <PageTitle
                title="Leads"
                setDisplayNotificationCenter={setDisplayNotificationCenter}
                setDisplayChatbot={setDisplayChatbot}
                reminders={reminders}
            />
            <MenuBottom currentView="admin_leads" />
            <div className={`nova_crm${mainClassexSuffix}`}>
                {getMainComponent()}
            </div>
        </section>
    </>;
};
