import React from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { date2shortFrenchString } from "../../../lib/tools/datetime";
import { findNextMultiple, getArrayMaxValue } from "../../../lib/tools/numbers";


export default function GraphRatioMeetingsPlanned({ daysUserUsageData }) {
    if (!daysUserUsageData) {
        return null;
    }

    // todo(2) peut etre mettre a 30 ?
    const maxYAxis = findNextMultiple(Math.round(getArrayMaxValue(daysUserUsageData.map(row => row.rdv_booked_per_work_done * 100)) * 1.2 + 1), 4);
    return (
        <ResponsiveContainer width="100%" height={280}>
            <BarChart
                data={daysUserUsageData.map(row => {
                    return {
                        ...row,
                        "Pourcentage de RDV pris par appel": (row["rdv_booked_per_work_done"] * 100).toFixed(0),
                        displayDate: date2shortFrenchString(new Date(row.day))
                    }
                })}
                margin={{ top: 15, right: 70, left: 0, bottom: 0 }}
                // barCategoryGap="50%"
            >
                <XAxis dataKey="displayDate" angle={45} height={90} textAnchor="start" />
                <YAxis domain={[0, maxYAxis]} />
                <Tooltip formatter={(value) => [`${value}%`, "RDV pris par appel"]} />
                {/* <Legend /> */}
                <Bar type="monotone" dataKey="Pourcentage de RDV pris par appel" fill="#334EFF" radius={8} /*barSize="30"*/ />
            </BarChart>
        </ResponsiveContainer>
    );
}
