import { useEffect, useRef } from "react";

export function useActionWhenMouseLeaves({ wait, mouseLeavesCallback }) {
    /*
        Tool to perform an action after wait seconds when the mouse leaves a certain area.
            The action is cancelled if the mouse enters the area again before the wait time is up.
    */
    const timeoutId = useRef(null);

    const handleMouseLeaveFiltersCheckboxes = () => {
        console.log("mouse leaves");
        timeoutId.current = setTimeout(() => {
            console.log("mouse leaves function triggered");
            mouseLeavesCallback();
        }, wait * 1000);
    };

    const handleMouseEnterFiltersCheckboxes = () => {
        clearTimeout(timeoutId.current);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId.current); //cleanup on unmount.
        };
    }, []);

    return [
        handleMouseLeaveFiltersCheckboxes,
        // to call when the mouse enters the area, to postpone the timeout
        handleMouseEnterFiltersCheckboxes,
    ]
}
