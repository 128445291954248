import { useContext, useState } from "react";
import { storeFeatureUsage, updateLead } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

export function useUpdatableLead({
    id,
    priority,
    lastname,
    firstname,
    commentaire,
    email,
    phone_number_concatenated,
    zipcode,
    year_of_birth,
    housing_status,
    statut,
    income_tax,
    profession,
    family,
    legal_status,
    adresse,
    enfant,
    city,
    departement,
    company_name,
    company_industry,
    region,
    job_started_on,
    handleLeadUpdated,
    handleUpdateStatutCounts,
    setToast,
    setConfirmation,
}) {
    const { auth } = useContext(AuthContext);

    const [newPriority, setNewPriority] = useState(priority);
    const [newLastname, setNewLastname] = useState(lastname);
    const [newFirstname, setNewFirstname] = useState(firstname);
    const [newComment, setNewComment] = useState(commentaire);
    const [newEmail, setNewEmail] = useState(email);
    const [newPhone, setNewPhone] = useState(phone_number_concatenated);
    const [newZipcode, setNewZipcode] = useState(zipcode);
    const [newBirthYear, setNewBirthYear] = useState(year_of_birth);
    const [newHousingStatus, setNewHousingStatus] = useState(housing_status);
    const [newStatut, setNewStatut] = useState(statut);
    const [newIncomeTax, setNewIncomeTax] = useState(income_tax);
    const [newProfession, setNewProfession] = useState(profession);
    const [newFamily, setNewFamily] = useState(family);
    const [newLegalStatus, setNewLegalStatus] = useState(legal_status);
    const [newAdress, setNewAdress] = useState(adresse);
    const [newChildren, setNewChildren] = useState(enfant);
    const [newCity, setNewCity] = useState(city);
    const [newDepartement, setNewDepartement] = useState(departement);
    const [newCompanyName, setNewCompanyName] = useState(company_name);
    const [newJobStartedOn, setNewJobStartedOn] = useState(job_started_on);
    const [newCompanyIndustry, setNewCompanyIndustry] = useState(company_industry);
    const [newRegion, setNewRegion] = useState(region);

    async function updateLeadNUI(champ, value) {
        handleLeadUpdated(id, champ, value);
        const json = JSON.stringify({ champ, value, user_id: auth.id });
        return await updateLead(id, json);
    }

    const updatePriority = (value) => {
        setNewPriority(value);
        updateLeadNUI("priority", value)
            .catch(err => {
                setToast({ text: "Echec, mise à jour des données.", severity: "error" });
                console.error(err);
            });
    }

    const updateComment = () => {
        updateLeadNUI('commentaire', newComment)
            .then(() => {
                setToast({ text: "Commentaire mis à jour", duration: 2000, severity: "success" });
            }).catch(err => {
                setToast({ text: "Echec, mise à jour du commentaire", severity: "error" });
                console.error(err);
            });
    }

    const updateStatut = (newStatus) => {
        setNewStatut(newStatus);
        handleUpdateStatutCounts(statut, newStatus);
        updateLeadNUI('statut', newStatus)
            .then(() => {
                setToast({ text: "Statut mis à jour", duration: 2000, severity: "success" });
            }).catch(err => {
                setToast({ text: "Echec, mise à jour du statut", severity: "error" });
                console.error(err);
            });
    }

    const updateLastname = () => {
        updateLeadNUI('lastname', newLastname)
            .catch(err => {
                setToast({ text: "Echec, mise à jour du nom", severity: "error" });
                console.error(err);
            });
    }

    const updateFirstname = () => {
        updateLeadNUI('firstname', newFirstname)
            .catch(err => {
                setToast({ text: "Echec, mise à jour du prénom", severity: "error" });
                console.error(err);
            });
    }

    const getLeadText = (addComment) => { // todo(1) rendre compatible B2B
        let text = `Noms: ${newFirstname + " " + newLastname}\n`;
        if (newEmail) {
            text += `Email: ${newEmail}\n`;
        }
        if (newPhone) {
            let phoneNb = newPhone.length < 10 ? "0" + newPhone : newPhone
            text += `Téléphone: ${phoneNb}\n`;
        }
        text += "\n";
        if (newHousingStatus) {
            text += `Statut de résidence: ${newHousingStatus}\n`;
        }
        if (newIncomeTax) {
            text += `Imposition: ${newIncomeTax}\n`;
        }
        if (newBirthYear) {
            text += `Année de naissance: ${newBirthYear}\n`;
        }
        if (newCity) {
            text += `Ville: ${city}\n`;
        }
        if (newZipcode) {
            text += `Code postal: ${newZipcode}\n`;
        }
        if (newProfession) {
            text += `Profession: ${newProfession}\n`;
        }
        if (newFamily) {
            text += `Situation familiale: ${newFamily}\n`;
        }
        if (newLegalStatus) {
            text += `Statut juridique: ${newLegalStatus}\n`;
        }
        if (newAdress) {
            text += `Addresse: ${newAdress}\n`;
        }
        if (newChildren) {
            text += `Enfant ?: ${newChildren}\n`;
        }
        if (addComment && newComment) {
            text += `\n\nCommentaire: ${newComment}\n`;
        }

        return text;
    }

    const handleCopy = () => {
        storeFeatureUsage(auth.id, "copypaste_lead", JSON.stringify({ leadId: id }));
        navigator.clipboard.writeText(getLeadText(true))
            .catch((error) => {
                console.error('Failed to copy text: ', error);
            });
    };

    const handleTransferToSalesman = () => {
        setConfirmation({
            "text": "Transférez ce lead à un vendeur.",
            "showYesButton": false,
            "showNoButton": false,
            "specialPopup": { type: "transfer2Salesman", leadId: id, companyId: auth.company_id },
            "confirmCallback": (userId) => {
                storeFeatureUsage(auth.id, "transfer2salesman", JSON.stringify({ leadId: id, userId }));
            }
        });
    };

    return {
        updateLeadNUI,
        newPriority,
        updatePriority,
        updateFirstname,
        updateLastname,
        handleCopy,
        handleTransferToSalesman,
        newStatut,
        updateStatut,
        newPhone,
        setNewPhone,
        newEmail,
        setNewEmail,
        newCity,
        setNewCity,
        newDepartement,
        setNewDepartement,
        newBirthYear,
        setNewBirthYear,
        newIncomeTax,
        setNewIncomeTax,
        newHousingStatus,
        setNewHousingStatus,
        newFamily,
        setNewFamily,
        newZipcode,
        setNewZipcode,
        newLegalStatus,
        setNewLegalStatus,
        newCompanyIndustry,
        setNewCompanyIndustry,
        newJobStartedOn,
        setNewJobStartedOn,
        newComment,
        setNewComment,
        updateComment,
        newRegion,
        setNewRegion,
        newProfession,
        setNewProfession,
        newAdress,
        setNewAdress,
        newCompanyName,
        setNewCompanyName,
    }
}

