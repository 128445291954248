import { useState } from "react";


export function useUpdatableLeads() {
    const [leads, setLeads] = useState([]);

    const handleLeadUpdated = (leadId, champ, value) => {
        setLeads(prevLeads => {
            return prevLeads.map((lead) => {
                if (lead.id === leadId) {
                    return { ...lead, [champ]: value };
                }
                return lead;
            });
        });
    }

    return [
        leads,
        setLeads,
        handleLeadUpdated
    ];
}
