import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './context/AuthProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import './style/normalize.css'; // order of css imported for webflow: normalize, webflow, invests-***
import './style/webflow.css';
import './style/investis-fr-935b2255af2d0-a0dba7ef0cf18.webflow.css';
import './style/nova-df9e7e.webflow.css';
import './style/fix_webflow.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import DraggableTable from './DraggableTable.js';
import LeadsPage from './LeadsPage.js';
import Login from './Login.js';
import MainDashboard from './MainDashboard.js';
import Subscribe from './Subscribe.js';
import AdminLeads from './admin_leads.js';
import AdminPerformance from './admin_performance.js';
import AdminUsers from './admin_users.js';
import { PrivateRoute } from './component/privateRoute.js';
import SuperAdmin from './superadmin.js';
import NewLeadsPage from './NewLeadsPage.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute> <MainDashboard /> </PrivateRoute>,
  },
  {
    path: "/leads",
    element: <PrivateRoute> <LeadsPage /> </PrivateRoute>,
  },
  {
    path: "/new_leads",
    element: <PrivateRoute> <NewLeadsPage /> </PrivateRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/admin_users",
    element: <PrivateRoute> <AdminUsers /> </PrivateRoute>,
  },
  {
    path: "/admin_leads",
    element: <PrivateRoute> <AdminLeads /> </PrivateRoute>,
  },
  {
    path: "/admin_performance",
    element: <PrivateRoute> <AdminPerformance /> </PrivateRoute>,
  },
  {
    path: "/superadmin",
    element: <PrivateRoute> <SuperAdmin /> </PrivateRoute>,
  },
  {
    path: "/draggable_table",
    element: <PrivateRoute> <DraggableTable /> </PrivateRoute>,
  },
  // {
  //   path: "/chatbot",
  //   element: <PrivateRoute> <TestChatbotPage /> </PrivateRoute>,
  // },
  // {
  //   path: "/calendar",
  //   element: <PrivateRoute> <TestCalendar /> </PrivateRoute>,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
