import React from "react";

export default function RightHalfScreenImage({ imgSrc, isImageLargeWithCenteredContent }) {
    const classSuffix = isImageLargeWithCenteredContent ? "" : "normal";
    return <div className="nova_rightside-onboarding-v2">
        <img src={`./images/nova/${imgSrc}`}
            loading="lazy"
            alt=""
            className={`nova_right-screen-img ${classSuffix}`} />
    </div>;
}
