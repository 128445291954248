import React, { useState } from "react";


export default function Dropdown({ initialText, mainImgSrc, options, handleChoice }) {
    /*
        :param options: [{key, label, value}]
        :params handleChoice: (value) => {}
    */
    const [mainLabel, setMainLabel] = useState(initialText);
    const [displayDropD, setDisplayD] = useState(false);

    const getDropdownOption = (key, value, label, shouldBeSmall) => {
        const classSuffix = shouldBeSmall ? " small" : "";
        return <div key={key}
            onClick={() => {
                setDisplayD(false);
                handleChoice(value);
                setMainLabel(label);
            }}
            className={`nova_dropbox-links-lastupdate${classSuffix} w-inline-block`}>
            <div className="nova_text-linkbox">{label}</div>
        </div>;
    }

    const getDropdownListElements = () => {
        if (!displayDropD) return null;

        const shouldBeSmall = options.length > 10;
        return <div className="nova_hovered_filter_container visible">
            <div className="nova_holder-last-update-dropbox">
                <div className="nova_holder-links-dropbox">
                    {options.map(opt => getDropdownOption(opt.key, opt.value, opt.label, shouldBeSmall))}
                </div>
            </div>
        </div>;
    }

    // todo(3) pk  _8
    return <div onMouseLeave={() => setDisplayD(false)} className="nova_attribute_dropdown">
        <div onClick={() => setDisplayD(true)} className="dropdown-2 w-dropdown">
            <div className="dropdown-toggle-4 w-dropdown-toggle">
                <div className="icon-6 w-icon-dropdown-toggle"></div>
                <div className="nova_holder-icon-text _8">
                    {mainImgSrc ? <img src={`./images/nova/${mainImgSrc}`} loading="lazy" alt="" /> : null}
                    <div className="text-block-35">{mainLabel}</div>
                </div>
            </div>
        </div>
        {getDropdownListElements()}
    </div>;

}
