import React from 'react';
import { Cell, Legend, Pie, PieChart } from 'recharts';


const COLORS = ['#334EFF', '#51C889']; // NRP orange, others blue

const NRPPieCharts = ({ nrpCount, totalCount }) => {
    const notNRPCount = totalCount - nrpCount;

    const data = [
        { name: 'Autres', value: notNRPCount },
        { name: 'NRP', value: nrpCount },
    ];

    // todo(1) mettre width a 100%
    return (
        <PieChart width={400} height={280}>
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#000000"
                dataKey="value"
                label={({ name, percent, value }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
                {data.map((entry, index) => (
                    <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend />
        </PieChart>
    );
};

export default NRPPieCharts;
