import React, { useEffect, useState } from "react";

import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
} from "@mui/material";
import {
  assignLeads,
  getAvailableLeadsCountByDepartement,
  getUserLeadsCount,
  storeFeatureUsage,
} from "../../api/axios";
import {
  CanAddLeadsHighIncomeTaxOnly,
  Jobs,
  Region,
} from "../../context/enums";
import addIcon from "../../images/add_icon.svg";
import rmIcon from "../../images/rm_icon.svg";
import { setDepartementInput, setNumericInput } from "../../lib/tools/strings";
import "../../style/Collaborators.css";
import NovaInput from "../ux/common/nova_input";

// todo(1) classe qui faisait parfois un peu trop d'appels d'API
export default function AssignLeads(props) {
  const { user, setToast, setConfirmation, list, color, auth } = props;

  const [userLeadsCount, setUserLeadsCount] = useState(null);
  const [regionLeadsCount, setRegionLeadsCount] = useState(0);

  const [countLeadsToAssign, setCountLeadsToAssign] = useState(100);
  const [region, setRegion] = useState("__all__");
  const [departement, setDepartement] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [job, setJob] = useState("__undefined__");
  const [highTaxOnly, setHighTaxOnly] = useState(false);

  function updateUserLeadsCount(userId) {
    getUserLeadsCount(userId).then((res) => {
      setUserLeadsCount(res.data);
    });
  }

  useEffect(() => {
    setUserLeadsCount(null);
    updateUserLeadsCount(user.id);
  }, [user.id]);

  function updateRegionLeadsCount(updateRegion, listId, companyId) {
    const json = JSON.stringify({
      region: updateRegion,
      company_id: companyId,
      list_id: listId,
    });
    getAvailableLeadsCountByDepartement(json).then((res) => {
      setRegionLeadsCount(res.data);
    });
  }

  // en cas d'erreur sur ces api et les autres, on met pas à jour l'UI
  useEffect(() => {
    setRegionLeadsCount(0);
    updateRegionLeadsCount(region, list.id, auth.company_id);
  }, [region, user, list.id, auth]);

  const addLeadsToCollaboratorImpl = (region, job, nbleads) => {
    const cleanedJob = job === "__undefined__" ? null : job;
    const cleanedRegion = region === "__all__" ? null : region;
    const json = JSON.stringify({
      user_id: user.id,
      region: cleanedRegion,
      departement: departement,
      len: nbleads,
      company_id: auth.company_id,
      list_id: list.id,
      min_age: minAge,
      max_age: maxAge,
      job: cleanedJob,
      high_tax_only: highTaxOnly,
    });

    assignLeads(json)
      .then((res) => {
        const queriedLeadCount = res.data.queried;
        const assignedLeadCount = res.data.assined;

        if (assignedLeadCount === queriedLeadCount) {
          setToast({ text: "Leads attribués.", severity: "success" });
        } else {
          let message = `Seul ${assignedLeadCount} leads ont put être attribués, il en manquais ${
            queriedLeadCount - assignedLeadCount
          } pour remplir votre demande.`;
          setToast({ text: message, severity: "warning" });
        }

        updateUserLeadsCount(user.id);
        updateRegionLeadsCount(region, list.id, auth.company_id);
      })
      .catch((err) => {
        console.log("leadd aded with failure");
        setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
        console.error(err);
      });
  };

  const addLeadsToCollaborator = () => {
    if (regionLeadsCount === 0) {
      setToast({ text: `Il n'y a aucun lead disponible.`, severity: "error" });
      return;
    }

    if (regionLeadsCount < countLeadsToAssign) {
      setToast({
        text: `Il n'y a que ${regionLeadsCount} leads disponibles.`,
        severity: "warning",
      });
    }
    setConfirmation({
      title: "Attribution de leads",
      text: "Etes-vous sur de vouloir attribuer des leads à cet utilisateur ?",
      showYesButton: true,
      showNoButton: true,
      specialPopup: null,
      confirmCallback: () => {
        addLeadsToCollaboratorImpl(region, job, countLeadsToAssign);
        storeFeatureUsage(
          auth.id,
          "admin_assign_leads_to_collaborator",
          JSON.stringify({
            countLeadsToAssign,
            userLeadsCount,
            region,
            minAge,
            maxAge,
            userId: user.id,
          })
        );
      },
    });
  };

  return (
    <>
      {userLeadsCount !== null ? (
        <Grid item xs={12} key='user_lead_count'>
          <p>
            {user.firstname} a {userLeadsCount} leads.
          </p>
          <br />
        </Grid>
      ) : null}

      <Grid item xs={12} key='select_region'>
        <InputLabel>Région préférée:</InputLabel>
        <NovaInput
          type='select'
          name='region'
          value={region}
          onChange={(ev) => setRegion(ev.target.value)}
          options={[
            { label: "Toute la France", value: "__all__" },
            ...(list.split_by_department
              ? Object.values(Region).map((r) => ({
                  label: r.display,
                  value: r.CodePostal,
                }))
              : []),
          ]}
        />
        <br />
    
        <InputLabel>
          La région a {regionLeadsCount} leads disponibles.
        </InputLabel>
      </Grid>

      <Grid item xs={12} key='select_departement'>
        <NovaInput
          type='text'
          placeholder='Département (exemple: 08)'
          name='departement'
          value={departement}
          onChange={(ev) => setDepartementInput(ev, setDepartement)}
        />
      </Grid>

      <Grid item xs={12} key='select_min_age'>
        <NovaInput
          type='text'
          placeholder='Âge minimum désiré'
          name='minAge'
          value={minAge}
          onChange={(ev) => setNumericInput(ev, setMinAge)}
        />
      </Grid>

      <Grid item xs={12} key='select_max_age'>
        <NovaInput
          type='text'
          placeholder='Âge maximum désiré'
          name='maxAge'
          value={maxAge}
          onChange={(ev) => setNumericInput(ev, setMaxAge)}
        />
      </Grid>

      {CanAddLeadsHighIncomeTaxOnly ? (
        <Grid item xs={12} key='high_incom_tax_only'>
          <FormControlLabel
            control={
              <Checkbox
                name='excludeCustomerLeads'
                checked={highTaxOnly}
                onChange={(e) => setHighTaxOnly(e.target.checked)}
              />
            }
            label='Uniquement des leads +5k impots annuels'
          />
          <br />
        </Grid>
      ) : null}

      <Grid item xs={12} key='select_job'>
        <InputLabel>Sélectionnez un métier:</InputLabel>
        <NovaInput
          type='select'
          label='Sélectionnez un métier'
          name='job'
          value={job}
          onChange={(ev) => setJob(ev.target.value)}
          options={[
            { label: "Tous les métiers", value: "__undefined__" },
            ...Jobs.map((job) => ({
              label: job,
              value: job,
            })),
          ]}
        />
      </Grid>

      <Grid item xs={12} key='select_count_leads_to_assign'>
        <div className='buttons'>
          {countLeadsToAssign > 0 && (
            <img
              className='icons'
              src={rmIcon}
              alt='rm Icon'
              onClick={() => setCountLeadsToAssign(countLeadsToAssign - 50)}
              style={{ cursor: "pointer" }}
            />
          )}
          <input
            id='add-leads-btn'
            disabled={false}
            onClick={() => addLeadsToCollaborator()}
            type='button'
            value={"Ajouter " + countLeadsToAssign + " leads de cette liste"}
            style={{ backgroundColor: color, cursor: "pointer" }}
          />
          <img
            className='icons'
            src={addIcon}
            alt='Add leads Icon'
            onClick={() => setCountLeadsToAssign(countLeadsToAssign + 50)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </Grid>
    </>
  );
}
