import React from "react";

export default function InputText({ value, setValue, placeHolder }) {
    return <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="nova_input-name w-input"
        maxLength={255}
        placeholder={placeHolder}
        type="text" />;
}
