import React, { useContext, useState } from "react";
import Calendar from "react-calendar/dist/cjs/Calendar.js";
import { addReminder, storeFeatureUsage } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { date2UTCDate, getTodayUTC } from "../../lib/tools/datetime";
import { getNameInitials } from "../../lib/tools/strings";
import "../../style/Calendar.css";
import Dropdown from "../ux/forms/dropdown";
import InputText from "../ux/forms/input_text";


const PopupAddReminder = ({ lead, setToast, successCallback, closingCallback }) => {
    const { auth } = useContext(AuthContext);

    const [hour, setHour] = useState("__undefined__");
    const [minute, setMinute] = useState("__undefined__");
    const [reason, setReason] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);  // datetime object

    const handleFinished = () => {
        closingCallback();
    };

    const handleCreateClick = () => {
        if (selectedDate === null) {
            setToast({ text: "Aucune date séléctionnée", severity: "warning" });
            return;
        }

        const cleanedHour = hour === "__undefined__" ? null : Number(hour);
        const cleanedMinute = minute === "__undefined__" ? null : Number(minute);
        storeFeatureUsage(auth.id, "reminder_add", { leadId: lead.id, selectedDate, cleanedHour, cleanedMinute, reason });
        addReminder(auth.id, lead.id, selectedDate, cleanedHour, cleanedMinute, reason).then(() => {
            handleFinished();
            successCallback();
            setToast({ text: "Rappel ajouté", severity: "success" });
        }).catch(err => {
            console.error(err);
            setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
        });
    }

    const handleDateChange = (date) => {
        setSelectedDate(date2UTCDate(date));
    };

    const isTileDisabled = ({ date, view }) => {
        return false;
    };

    const getTopPart = () => {
        return <div className="popup_add_event_topp">
            <div className="popup_add_event_top_left">
                <img src="./images/nova/calendar_add.svg" loading="lazy" alt="" />
                <div className="nova_popup_title">Créer un rappel</div>
            </div>
            <div className="nova_popup-close-button-standalone w-inline-block" onClick={handleFinished}>
                <img src="./images/nova/cross_darkgrey.svg" loading="lazy" alt="" />
            </div>
        </div>;
    }

    const getLeadsPart = () => {
        return <div className="mova_add_reminder_left">
            <div className="nova_grey_subtitle">Lead</div>
            <div className="nova_holder-client">
                <div className="nova_user-abreviation _48">
                    <div className="nova_abvreviation-user">{getNameInitials(lead.firstname, lead.lastname)}</div>
                </div>
                <div className="nova_text-nae-client">{lead.firstname} {lead.lastname}</div>
            </div>
        </div>;
    }

    const allHours = Array.from({ length: 18 }, (_, i) => i + 6);

    const getInputPart = () => {
        // options should have key value and label
        const hoursOptions = [{ key: "__undefined__", value: "__undefined__", label: "Pas d'heure" }]
            .concat(allHours.map((hour) => {
                return { key: hour, value: hour, label: `${hour} h` };
            }));

        const minutesOptions = Array.from({ length: 12 }, (_, i) => i * 5).map((minute) => {
            return { key: minute, value: minute, label: `${minute} m` };
        });

        return <div className="nova_add_reminder_right">
            <div className="nova_icon-text-part-calendar">
                <img src="./images/nova/calendar.svg" loading="lazy" alt="" className="nova_icon-16" />
                <div className="nova_text-icon-bold">Choisir date et horraire</div>
            </div>
            <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                minDate={getTodayUTC()}
                selectRange={false}
                tileDisabled={isTileDisabled}
                locale='fr-FR'
                maxDetail='month'
                showDoubleView={false} // Hide the double view mode
            />

            <div className="nova_content-dropdown">
                <div className="nova_icon-text-part-calendar">
                    <img src="./images/nova/time.svg" loading="lazy" alt="" className="nova_icon-16" />
                    <div className="nova_text-icon-bold">Durée</div>
                </div>
                <div className="nova_dropdown-input-timing">
                    <Dropdown initialText="Heure (optionnel)" mainImgSrc={null} options={hoursOptions} handleChoice={setHour} />
                    <Dropdown initialText="Minute (optionnel)" mainImgSrc={null} options={minutesOptions} handleChoice={setMinute} />
                </div>
            </div>

            <div className="nova_content-dropdown">
                <div className="nova_icon-text-part-calendar">
                    <img src="./images/nova/reason.svg" loading="lazy" alt="" className="nova_icon-16" />
                    <div className="nova_text-icon-bold">Raison</div>
                </div>
                <div className="nova_dropdown-input-timing">
                    <InputText value={reason} setValue={setReason} placeHolder="Raison" />
                </div>
            </div>
        </div>;
    }

    const getBottomPart = () => {
        const classSuffix = selectedDate === null ? " unclickable" : "";
        return <div onClick={handleCreateClick} className="popup_add_event_bottom">
            <div className={`nova_button bold${classSuffix} w-button`}>Suivant</div>
        </div>;
    }

    if (!auth) {
        return null;
    }

    return <div className="fixed_shadow">
        <div className="popup_positionner">
            <div className="nova_add_event_popup">
                {getTopPart()}
                <div className="nova_popup_ad_rminder_middle">
                    {getLeadsPart()}
                    {getInputPart()}
                </div>
                {getBottomPart()}
            </div>
        </div>
    </div>;
};

export default PopupAddReminder;
