import { useContext, useEffect, useState } from "react";
import { getCompanyLeads, getUserLeads, storeFeatureUsage } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { AllStatus, LeadStatus, ListsNicknames } from "../../context/enums";
import { DataSourceDefaultValue, JobCatDefaultValue, JobDefaultValue, TaxDefaultValue } from "../../context/filter_constants";
import { setDepartementRawInput, setNumericRawInput } from "../tools/strings";
import { useDifferentLists } from "./useDifferentLists";
import { useUpdatableLeads } from "./useUpdatableLeads";

export function useLeadsLists({ initialItemsPerPage, startsWithAllStatus, addAllLeadsList = false }) {
    const { auth, listsIds } = useContext(AuthContext);

    const [listIdIdx, getAccessibleListsIds, setListIdIdx, allLists, reloadListsAccessibles] = useDifferentLists({ auth, accessibleListIds: listsIds, addAllLeadsList: addAllLeadsList });

    const initialStatus = startsWithAllStatus ? AllStatus : LeadStatus["SANS_STATUT"];
    const [statut, setStatut] = useState(initialStatus);
    const [input, setInput] = useState(""); // for search
    const [region, setRegion] = useState(null);
    const [highTaxOnly, setHighTaxOnly] = useState(TaxDefaultValue);
    const [jobCategory, setJobCategory] = useState(JobCatDefaultValue);
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [departement, setDepartement] = useState("");
    const [job, setJob] = useState(JobDefaultValue);
    const [dataSource, setDataSource] = useState(DataSourceDefaultValue);
    const [priority, setPriority] = useState("");

    const [itemParPage] = useState(initialItemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxPageFetched, setMaxPageFetched] = useState(-1);

    const [leads, setLeads, handleLeadUpdated] = useUpdatableLeads();

    const getCountActiveExtraFilters = () => {
        // statut and priority are not an additionnal filter
        let result = 0;
        if (minAge !== "") result++;
        if (maxAge !== "") result++;
        if (departement !== "") result++;
        if (region !== null) result++;
        if (highTaxOnly !== TaxDefaultValue) result++;
        if (jobCategory !== JobCatDefaultValue) result++;
        if (job !== JobDefaultValue) result++;
        if (dataSource !== DataSourceDefaultValue) result++;
        return result;
    }

    const getListLeads = (listId, searchField, statusField, limit, offset, concatLead, departementField, regionField, minAgeField, maxAgeField, newJobCat, newJob, highTaxOnly, dataSource, priorityField) => {
        console.log('getLidsLeads called with :>> listId ', listId, "searchField", searchField,
            "statusField", statusField, "limit", limit, "offset", offset, "concatLead", concatLead,
            "departementField", departementField, "regionField", regionField, "minAgeField", minAgeField,
            "maxAgeField", maxAgeField, "newJobCat", newJobCat, "newJob", newJob, "highTaxOnly", highTaxOnly,
            "dataSource", dataSource, "priorityField", priorityField);
        function processResult(res) {
            if (concatLead) {
                setLeads([...leads, ...res.data]);
            } else {
                setLeads(res.data);
            }
        }

        const jobCatCleaned = newJobCat === JobCatDefaultValue ? null : newJobCat;
        const jobCleaned = newJob === JobDefaultValue ? null : newJob;
        const regionCleaned = regionField === null ? null : regionField;
        const dataSourceCleaned = dataSource === DataSourceDefaultValue ? null : dataSource;
        const cleanStatus = statusField === AllStatus ? null : statusField;
        if (listId === ListsNicknames["ALL"]) { // todo(2) remove this list de merde
            getCompanyLeads(auth.company_id, null, searchField, cleanStatus, limit, offset, departementField, regionCleaned, minAgeField, maxAgeField, jobCatCleaned, jobCleaned, highTaxOnly, dataSourceCleaned, priorityField)
                .then(processResult);
        } else {
            getUserLeads(auth.id, listId, searchField, cleanStatus, limit, offset, departementField, regionCleaned, minAgeField, maxAgeField, jobCatCleaned, jobCleaned, highTaxOnly, dataSourceCleaned, priorityField)
                .then(processResult);
        }
    }

    const setup = () => {
        if (!auth || listsIds === null) {
            return;
        }

        // only the first time when maxPageFetched is -1 do we need to load and replace leads
        if (0 !== maxPageFetched + 1) {
            return;
        }

        getListLeads(getAccessibleListsIds()[listIdIdx],
            input,
            statut,
            itemParPage,
            currentPage * itemParPage,
            false,
            departement,
            region,
            minAge,
            maxAge,
            jobCategory,
            job,
            highTaxOnly,
            dataSource,
            priority);
        setMaxPageFetched(0);
    }

    useEffect(() => {
        setup();
    }, [auth, listsIds]);

    const handlePagesModeClickPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const handlePagesModeClickNextPage = (nextPageIdx) => {
        setCurrentPage(nextPageIdx);
        fetchPageIfNeedBe(nextPageIdx);
    }

    const fetchPageIfNeedBe = (pageIdx) => {
        if (pageIdx !== maxPageFetched + 1) {
            return;
        }

        console.log("fetching page ", pageIdx, "itemParPage", itemParPage);
        getListLeads(getAccessibleListsIds()[listIdIdx],
            input,
            statut,
            itemParPage,
            pageIdx * itemParPage,
            true,
            departement,
            region,
            minAge,
            maxAge,
            jobCategory,
            job,
            highTaxOnly,
            dataSource,
            priority);
        setMaxPageFetched(maxPageFetched + 1);
    }

    const resetExtraFiltersValues = () => {
        setRegion(null);
        setHighTaxOnly(TaxDefaultValue);
        setJobCategory(JobCatDefaultValue);
        setMinAge("");
        setMaxAge("");
        setDepartement("");
        setJob(JobDefaultValue);
        setDataSource(DataSourceDefaultValue);
    }

    const resetAllFiltersValues = () => {
        // we don't reset search in case the user is searching through all lists
        resetExtraFiltersValues();
        setStatut(initialStatus);
        setPriority("");
    }

    const handleResetAllFilters = () => { // todo(1) rename handle reset extra filters no ?
        setCurrentPage(0);
        setLeads([]);

        resetExtraFiltersValues();

        getListLeads(getAccessibleListsIds()[listIdIdx],
            input,
            statut,
            itemParPage,
            currentPage * itemParPage,
            false,
            departement,
            region,
            minAge,
            maxAge,
            jobCategory,
            job,
            highTaxOnly,
            dataSource,
            priority);
        setMaxPageFetched(0);
    }

    const handleMaxAgeFilterChange = (maxAgeField) => {
        setCurrentPage(0);
        setLeads([]);
        setNumericRawInput(maxAgeField, setMaxAge);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAge, maxAgeField, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleMinAgeFilterChange = (minAgeField) => {
        setCurrentPage(0);
        setLeads([]);
        setNumericRawInput(minAgeField, setMinAge);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAgeField, maxAge, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleDepartementFilterChange = (departementField) => {
        setCurrentPage(0);
        setLeads([]);
        setDepartementRawInput(departementField, setDepartement);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departementField, region, minAge, maxAge, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleRegionFilterChange = (regionField) => {
        setCurrentPage(0);
        setLeads([]);
        setRegion(regionField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, regionField, minAge, maxAge, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleDataSourceChange = (sourceField) => {
        setCurrentPage(0);
        setLeads([]);
        setDataSource(sourceField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, job, highTaxOnly, sourceField, priority);
        setMaxPageFetched(0);
    }

    const handleSearchChange = (e) => {
        let searchField = e.target.value;

        setCurrentPage(0);
        setLeads([]);
        setInput(searchField);
        getListLeads(getAccessibleListsIds()[listIdIdx], searchField, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleStatusChange = (statusField) => {
        setCurrentPage(0);
        setLeads([]);
        setStatut(statusField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statusField, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleJobCategoryChange = (jobCategoryField) => {
        setCurrentPage(0);
        setLeads([]);
        setJobCategory(jobCategoryField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategoryField, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleJobChange = (jobField) => {
        setCurrentPage(0);
        setLeads([]);
        setJob(jobField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, jobField, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handleTaxFilterChange = (taxField) => {
        setCurrentPage(0);
        setLeads([]);
        setHighTaxOnly(taxField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, job, taxField, dataSource, priority);
        setMaxPageFetched(0);
    }

    const handlePriorityChange = (priorityField) => {
        if (priority !== "") storeFeatureUsage(auth.id, "filter_priorities", JSON.stringify({ priority: priorityField }));

        setCurrentPage(0);
        setLeads([]);
        setPriority(priorityField);
        getListLeads(getAccessibleListsIds()[listIdIdx], input, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, job, highTaxOnly, dataSource, priorityField);
        setMaxPageFetched(0);
    }

    const handleListIdChange = (newViewIdx) => {
        let listId = getAccessibleListsIds()[newViewIdx];
        // todo(2) sauvegarder filtres par listes et les recharger et reset filtres ici

        resetAllFiltersValues();
        setCurrentPage(0);
        setLeads([]);
        setListIdIdx(newViewIdx);
        getListLeads(listId, input, statut, itemParPage, 0, false, departement, region, minAge, maxAge, jobCategory, job, highTaxOnly, dataSource, priority);
        setMaxPageFetched(0);
    }

    const getViewedLeads = () => {
        return leads.slice(currentPage * itemParPage, (currentPage + 1) * itemParPage);
    }

    return {
        leads, // todo(3) devrait pas être retourné, mais leads.length ou un truc du genre
        getViewedLeads,
        allLists, // on devrait retourner list a la place et gerer la logique ici pour obtenir list
        getAccessibleListsIds,
        listIdIdx,
        handleStatusChange,
        handleResetAllFilters,
        handleRegionFilterChange,
        handleTaxFilterChange,
        handleJobCategoryChange,
        handleMinAgeFilterChange,
        handleMaxAgeFilterChange,
        handleDepartementFilterChange,
        handleLeadUpdated,
        handleListIdChange,
        handleSearchChange,
        handlePagesModeClickPreviousPage,
        handlePagesModeClickNextPage,
        handlePriorityChange,
        handleJobChange,
        handleDataSourceChange,
        dataSource,
        job,
        minAge,
        maxAge,
        statut,
        departement,
        jobCategory,
        region,
        highTaxOnly,
        priority,
        currentPage,
        maxPageFetched,
        itemParPage,
        reloadListsAccessibles,
        getCountActiveExtraFilters,
    };
}
