import React from "react";

export default function ManagemementHeader({ title, description }) {
  return (
    <div className='nova_horizontal-text'>
      <div>{title}</div>
      <div className='text-block-57'>
        {description}
      </div>
    </div>
  );
}
