import React from "react";
import { useActionWhenMouseLeaves } from "../../../lib/ui/useActionWhenMouseLeaves";

// todo(3) finish all those comments, they are meant to factorize code in double

// todo(3) all this in double to reuse in getSearchFilter(), he will need key\
// todo(3) function getDropDownFilterOptions to remove there too called by getSearchFilter()
// handleMouseLeaveJob and handleMouseEnterJob are now inside here
export default function NovaSearchInput({
    showDrodown,
    setShowDropdown,
    classSuffix,
    selectedValue,
    selectedCallback,
    choices,
    label,
    className = "",
}) {
    const [handleMouseLeave, handleMouseEnter] =
        useActionWhenMouseLeaves({ wait: 0., mouseLeavesCallback: () => setShowDropdown(false) });

    // todo(3) used in double already somewhere else in getSearchFilter() file
    const getDropDownFilterOptions = (selectedCallback, choices, showDropDown, areChoicesStrings) => {
        /*
            choices : [{value:, label:}]
        */
        const classSuffix = showDropDown ? " visible" : "";
        return <div className={`nova_hovered_filter_container${classSuffix}`}>
            <div className="nova_holder-last-update-dropbox">
                <div className="nova_holder-links-dropbox">
                    {choices.map(nextCh => {
                        const key = areChoicesStrings ? nextCh : nextCh.value;
                        const label = areChoicesStrings ? nextCh : nextCh.label;
                        return <div key={key}
                            onClick={() => selectedCallback(nextCh)}
                            className="nova_dropbox-links-lastupdate w-inline-block">
                            <div className="nova_text-linkbox">{label}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>;
    }

    // todo(1) maybe add placeholder
    return <div className={`nova_input-wrapper ${className}`}>
        {label && <label className="">{label}</label>}
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={() => setShowDropdown(true)}
            onClick={() => setShowDropdown(true)}
            className={`nova_holder-dropdown-filter${classSuffix} w-form`}>
            <input className="nova_filter_input w-input"
                onFocus={() => setShowDropdown(true)}
                onChange={e => {
                    selectedCallback(e.target.value);
                    setShowDropdown(true);
                }}
                maxLength={255}
                value={selectedValue}
                type="text" />
            {getDropDownFilterOptions(selectedCallback, choices, showDrodown && choices.length, true)}
        </div>
    </div>;
}
