import React, { useContext, useState } from "react";
import LeadsPageCenter from "./component/leads/leads_page_center";
import MenuBottom from "./component/menu_bottom";
import MenuTop from "./component/menu_top";
import PageTitle from "./component/page_title";
import PopupAddReminder from "./component/popups/popup_add_reminder";
import PopupChatbot from "./component/popups/popup_chatbot";
import NotificationCenter from "./component/reminders/notification_center";
import ConfirmNotification from "./component/ux/confirmation";
import Toast from "./component/ux/toast";
import AuthContext from "./context/AuthProvider";
import { UserType } from "./context/enums";
import { useReminders } from "./lib/reminders/useReminders";
import { useVersionChecking } from "./lib/versions/useVersionChecking";


/*
    IMPORTANT: UI sync with user interactions  --  leads returned from the database must have same fields as in Lead.js
      so that when the user update a lead, the changes are reflected in the UI, see handleLeadUpdated

    IMPORTANT: [Code lifecycle] New filter  --  when adding new filteres, think of managing resetting filters
        and removing one filter by closing it
*/


function LeadsPage() {
    const { auth, listsIds, openMenu, color } = useContext(AuthContext);

    const [popupCreateReminderLead, setPopupCreateReminderLead] = useState(null);
    const [displayNotificationCenter, setDisplayNotificationCenter] = useState(false);
    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [displayChatbot, setDisplayChatbot] = useState(false);

    useVersionChecking({ setConfirmation, type: UserType.USER });

    const [reminders, reloadReminders, markReminderLocallyAsViewed] = useReminders({ auth });

    if (!auth) {
        return null;
    }

    // todo(3) reminder
    // todo(2) refaire au propre confirmation et toast, iciet dans toutes les pages admin, et nettoyer PopupAddReminder
    // todo(2) improve how RemindersView triggers popup add reminder   -- now works with setPopupCreateReminderLeadId which requires a lead_id
    // todo(2) mettre de cote tout ce qui est reminder, dans un contexte peut etre ?

    return <>
        {displayChatbot ? <PopupChatbot handleCloseButton={() => setDisplayChatbot(false)} /> : null}
        <section className="nova_holder-software-grid">
            <NotificationCenter
                display={displayNotificationCenter}
                setDisplay={setDisplayNotificationCenter}
                reminders={reminders}
                reloadReminders={reloadReminders}
                markReminderLocallyAsViewed={markReminderLocallyAsViewed}
                setToast={setToast}
            />
            {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
            {confirmation !== null && <ConfirmNotification title={confirmation.title}
                text={confirmation.text}
                confirmCallback={confirmation.confirmCallback}
                setConfirmation={setConfirmation}
                color={color}
                showYesButton={confirmation.showYesButton}
                showNoButton={confirmation.showNoButton}
                specialPopup={confirmation.specialPopup}
                setToast={setToast} />}
            {popupCreateReminderLead !== null ? <PopupAddReminder key={popupCreateReminderLead.id}
                lead={popupCreateReminderLead}
                setToast={setToast}
                successCallback={() => { reloadReminders(auth) }} //  todo(1) mettre de cote avec tout ca popupCreateReminderLeadId*/}
                closingCallback={() => setPopupCreateReminderLead(null)}
            /> : null}
            <MenuTop currentView={"leads_view"} />
            <PageTitle
                title="Leads"
                setDisplayNotificationCenter={setDisplayNotificationCenter}
                setDisplayChatbot={setDisplayChatbot}
                reminders={reminders}
            />
            <MenuBottom currentView="leads_view" />
            <LeadsPageCenter
                setToast={setToast}
                setConfirmation={setConfirmation}
                setPopupCreateReminderLead={setPopupCreateReminderLead}
            />
        </section>
    </>;
}

export default LeadsPage;
