import React from "react";
import AIMessage from "../basics/ai_message";
import AIMessageText from "../basics/ai_message_text";


export default function ReportBug() {
    const reportBugUrl = 'https://ballistic-death-b8e.notion.site/193ecce8803c80589bc1d9d88e1a4ea2';

    return <AIMessage containsForm={false}>
        <AIMessageText
            text="Vous pouvez reporter un bug en cliquant sur le bouton ci-dessous."
        />
        <div className="nova_holder-submit-button">
            <div onClick={() => window.open(reportBugUrl, '_blank')}
                className={`nova_button w-button`}>
                Reporter un bug
            </div>
        </div>
    </AIMessage>;
}
