import React, { useContext, useState } from "react";
import { updateUserViewPreferences } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { AllStatus, Priorities } from "../../../context/enums";
import { getStatutLabel } from "../../../lib/tools/app_context";
import PriorityDropdown from "../priority_dropdown";
import StatusDropdown from "../status_dropdown";
import AdditionnalFiltersPopup from "./additionnal_filters_popup";

export default function LeadsFilters({
    displayToggleViewButtons,
    list,
    leads,
    allLists,
    listIdIdx,
    listsIds,
    getAccessibleListsIds,
    view,
    setView,
    handleStatusChange,
    handleResetAllFilters,
    handleRegionFilterChange,
    handleTaxFilterChange,
    handleJobCategoryChange,
    handleMinAgeFilterChange,
    handleMaxAgeFilterChange,
    handleDepartementFilterChange,
    handlePriorityChange,
    handleJobChange,
    handleDataSourceChange,
    dataSource,
    job,
    minAge,
    maxAge,
    statut,
    departement,
    jobCategory,
    region,
    highTaxOnly,
    priority,
    totalCount,
    getStatusLeadsCount,
    reloadListsAccessibles,
    getCountActiveExtraFilters,
}) {
    const { auth } = useContext(AuthContext);

    const [filterOpened, setFilterOpened] = useState("none"); // none | status | priority | extra

    const toggleViewImpl = (newView) => {
        setView(newView);
        updateUserViewPreferences(auth.id, list.id, "view", newView)
            .then(() => reloadListsAccessibles(auth));
    }

    const getToggleViewComponent = () => {
        if (!displayToggleViewButtons) return null;

        const tableSelectorClassSuffix = view === "table" ? " selected" : "";
        const cardsSelectorClassSuffix = view === "cards" ? " selected" : "";
        return <div key="toggle_view" className="nova_holder-side-to-side">
            <div className={`nova_first-side${tableSelectorClassSuffix} w-inline-block`} onClick={() => toggleViewImpl("table")}>
                <img src="./images/nova/Icons-15.svg" loading="lazy" alt="" />
            </div>
            <div className={`nova_first-side${cardsSelectorClassSuffix} w-inline-block`} onClick={() => toggleViewImpl("cards")}>
                <img src="./images/nova/Icons-16.svg" loading="lazy" alt="" />
            </div>
        </div>;
    }

    const getStatusFilter = () => {
        const isActive = statut !== AllStatus;
        const classSuffix = isActive ? " active" : "";
        const imgSrc = isActive ? "./images/nova/cross_blue.svg" : "./images/nova/arrow_down.svg";

        const leadsCount = (statut === AllStatus) ? totalCount : getStatusLeadsCount(statut);
        const leadsCountString = leadsCount === undefined ? "" : ` (${leadsCount})`;

        function handleArrowClick(e) {
            if (isActive) {
                e.stopPropagation();
                handleStatusChange(AllStatus);
            }
        }

        function handleClickStatus(newStatus) {
            setFilterOpened("none");
            handleStatusChange(newStatus);
        }

        function handleClickButton() {
            if (filterOpened === "status") {
                setFilterOpened("none");
            } else {
                setFilterOpened("status");
            }
        }

        return <div key="status_filter" className="nova_holder-dropdown">
            <div onClick={handleClickButton} className={`dropdown${classSuffix}`}>
                <div className="text-block-17">
                    {getStatutLabel(statut)}{leadsCountString}
                </div>
                <img onClick={handleArrowClick} src={imgSrc} loading="lazy" alt="" />
            </div>
            {filterOpened === "status" ? <StatusDropdown
                automaticalyVisible={true}
                handleStatusClicked={handleClickStatus}
                isStatusUpdate={false}
                useAllStatus={true}
                positionAtRight={false}
            /> : null}
        </div>;
    }

    // todo(3) update string when one is selected
    const getPriorityFilter = () => {
        const isActive = priority !== "";
        const classSuffix = isActive ? " active" : "";
        const imgSrc = isActive ? "./images/nova/cross_blue.svg" : "./images/nova/arrow_down.svg";

        function handleArrowClick(e) {
            if (isActive) {
                e.stopPropagation();
                handlePriorityChange("");
            }
        }

        function handleClickPriority(newPriority) {
            setFilterOpened("none");
            handlePriorityChange(newPriority);
        }

        function handleClickButton() {
            if (filterOpened === "priority") {
                setFilterOpened("none");
            } else {
                setFilterOpened("priority");
            }
        }

        return <div key="priority_filter" className="nova_holder-dropdown">
            <div onClick={handleClickButton} className={`dropdown${classSuffix}`}>
                <div className="text-block-17">
                    Priorité {priority === "" ? "" : Priorities[priority]}
                </div>
                <img onClick={handleArrowClick} src={imgSrc} loading="lazy" alt="" />
            </div>
            {filterOpened === "priority" ? <PriorityDropdown
                automaticalyVisible={true}
                handlePriorityClicked={handleClickPriority}
            // isStatusUpdate={false}
            /> : null}
        </div>;
    }

    const getAdditionalFilters = () => {
        if (leads === null) {
            return null;
        }

        if (allLists == null || listsIds === null) {
            return null;
        }

        let listId = getAccessibleListsIds()[listIdIdx];
        let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

        const isActive = getCountActiveExtraFilters() > 0;
        const classSuffix = isActive ? " active" : "";
        const imgSrc = isActive ? "./images/nova/more_filters_blue.svg" : "./images/nova/more_filters.svg";

        function getLabel() {
            const count = getCountActiveExtraFilters();
            if (count === 0) return "Filtres";
            if (count === `0`) return "` filtre";
            return `${getCountActiveExtraFilters()} filtres`;
        }

        function handleResetFilterClick() {
            setFilterOpened("none");
            handleResetAllFilters();
        }

        function handleClickButton() {
            if (filterOpened === "extra") {
                setFilterOpened("none");
            } else {
                setFilterOpened("extra");
            }
        }

        return <div key="additional_filters" className="nova_filter_container">
            <div onClick={handleClickButton} className={`nova_holder-filters ${classSuffix} w-inline-block`}>
                <img src={imgSrc} loading="lazy" alt="" />
                <div className="text-block-18">{getLabel()}</div>
            </div>
            {filterOpened === "extra" ? <AdditionnalFiltersPopup
                key={list.id} // Reset Filters when list.id is updated
                list={list}
                handleResetAllFilters={handleResetFilterClick}
                handleRegionFilterChange={handleRegionFilterChange}
                handleTaxFilterChange={handleTaxFilterChange}
                handleJobCategoryChange={handleJobCategoryChange}
                minAge={minAge}
                maxAge={maxAge}
                jobCategory={jobCategory}
                region={region}
                highTaxOnly={highTaxOnly}
                handleMinAgeFilterChange={handleMinAgeFilterChange}
                handleMaxAgeFilterChange={handleMaxAgeFilterChange}
                departement={departement}
                handleDepartementFilterChange={handleDepartementFilterChange}
                job={job}
                handleJobChange={handleJobChange}
                handleDataSourceChange={handleDataSourceChange}
                dataSource={dataSource}
            /> : null}
        </div>;
    }

    return <div className="nova_holder-tools">
        {getToggleViewComponent()}
        {getStatusFilter()}
        {getPriorityFilter()}
        {getAdditionalFilters()}
    </div>;
}
