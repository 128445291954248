import React from "react";

export default function ChatBotTopMenu() {
    return <div className="nova_brand-cont">
        <div className="nova_brand_positioner">
            <div className="nova_brand-menu w-inline-block">
                <img src="./images/nova/star.svg" loading="lazy" alt="" className="nova_icon-24" />
                <div className="text-block-51">Nova AI</div>
            </div>
            {/* <div className="nova_close-button w-inline-block">
                <img src="./images/nova/Icons-12.svg" loading="lazy" alt="" />
            </div> */}
        </div>
        <div className="nova_menu_content">
            <div className="nova_holder-fonctions"></div>
        </div>
    </div>;
}
