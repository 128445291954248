import React, { useContext, useEffect, useState } from "react";
import { markAsViewed } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { isReminderActive } from "../../lib/reminders/tools";
import { date2FrenchMonthString, date2dayDigitString, date2shortFrenchString, isDateAfterNow, isDateAfterOrEqToday } from "../../lib/tools/datetime";
import { useVisibility } from "../../lib/ui/useVisibility";


/*
    Notification Center has z-index: 999
*/


// todo(2) set most code aside in context..... so that now loaded on each page and set aside the code view
export default function NotificationCenter({ display, setDisplay, reminders, reloadReminders, markReminderLocallyAsViewed, setToast }) {
    const { auth } = useContext(AuthContext);
    const isVisible = useVisibility();

    const [view, setView] = useState("future");  // "future" or "past"

    useEffect(() => {
        if (isVisible) {
            reloadReminders(auth);
        }
    }, [auth, isVisible]);

    const getTopPart = () => {
        return <div className="nova_up-part-notification">
            <div className="nova_text-notification">Notifications</div>
            <div className="nova_holder-linkblocks-notifications">
                {/* todo(2) rajouter <div className="nova_bell-notifications w-inline-block">
                    <img src="./images/nova/reminder_grey.svg" loading="lazy" alt="" />
                    <div className="nova_text-notification-bell">Ajouter un rappel</div>
                </div> */}
                <div onClick={() => setDisplay(false)} className="nova_holder-close-button w-inline-block">
                    <img src="./images/nova/cross_grey.svg" loading="lazy" width="20" alt="" />
                </div>
            </div>
        </div>
    }

    const getSelectTimeFrameRow = () => {
        const futureActiveCount = futureReminders.filter(isReminderActive).length;
        const previousActiveCount = previousReminders.filter(isReminderActive).length;

        const futureSuffix = view === "future" ? " selected" : "";
        const pastSuffix = view === "past" ? " selected" : "";
        return <div className="nova_holder-notification-events">
            <div className={`nova_notification-button${futureSuffix} w-inline-block`} onClick={() => setView("future")}>
                <div className="nova_text-event">Evenements à venir</div>
                <div className="nova_event-number-box">
                    <div className="nova_number-events">{futureActiveCount}</div>
                </div>
            </div>
            <div className={`nova_notification-button${pastSuffix} w-inline-block`} onClick={() => setView("past")}>
                <div className="nova_text-event">Evenements passés</div>
                <div className="nova_event-number-box">
                    <div className="nova_number-events">{previousActiveCount}</div>
                </div>
            </div>
        </div>
    }

    const getReminderHourText = (reminder) => {
        if (reminder.hour === null) {
            return "";
        } else if (reminder.minute === null) {
            return `${reminder.hour}h`;
        } else {
            return `${reminder.hour}h ${reminder.minute}m`;
        }
    }

    const getDateImage = (reminder) => {
        return <div className="nova_holder-date">
            <div className="nova_holder-month">
                <div className="nova_month-in-box">{date2FrenchMonthString(reminder.reminder_date)?.slice(0, 3)}</div>
            </div>
            <div className="nova_holder-day-in-month">
                <div className="nova_day-in-month">{date2dayDigitString(reminder.reminder_date)}</div>
            </div>
            {isReminderActive(reminder) ? <div className="nova_blue-dot-notification"></div> : null}
        </div>;
    }

    const markAsViewedSafe = (nextReminder) => {
        markReminderLocallyAsViewed(nextReminder.id, !nextReminder.marked_as_viewed)
        markAsViewed(nextReminder.id, !nextReminder.marked_as_viewed)
            .catch(err => {
                setToast({ text: "Echec, marquer comme vu évenement.", severity: "error" });
                console.error(err);
            });
    }

    const getEventCardActions = (reminder) => {
        if (isDateAfterNow(reminder.reminder_datetime)) return null;
        const imgSrcSuffix = reminder.marked_as_viewed ? "_opened" : "";

        {/* reschedule todo(2) remettre <div className="nova_repporte-notification w-inline-block">
            <img src="./images/nova/reschedule.svg" loading="lazy" alt="" />
        </div> */}

        return <div onClick={() => markAsViewedSafe(reminder)} className="nova_repporte-notification w-inline-block">
            <img src={`./images/nova/mail${imgSrcSuffix}.svg`} loading="lazy" alt="" />
        </div>;
    }

    const getEventCard = (reminder) => { // todo(1) afficher phone ?
        const classSuffix = isReminderActive(reminder) ? " active" : "";
        return <div key={reminder.id} className={`nova_notification-box${classSuffix}`}>
            <div className="nova_notification_card_left">
                {getDateImage(reminder)}
                <div className="nova_holder-content-notification">
                    <div className="nova_title-notification">Rappel concernant {reminder.lead_firstname} {reminder.lead_lastname}</div>
                    <div className="nova_notification-specs">
                        <img src="./images/nova/calendar.svg" loading="lazy" alt="" />
                        <div className="nova_date-text">{date2shortFrenchString(reminder.reminder_date)}</div>
                        <div className="nova_time-text-notification">{getReminderHourText(reminder)}</div>
                    </div>
                    {reminder.reason ? <div className="nova_description-notification">{reminder.reason}</div> : null}
                </div>
            </div>
            {getEventCardActions(reminder)}
        </div>
    }

    const getScrollableEventsList = () => {
        let cards = [];
        if (view === "future") {
            cards = futureReminders.map(getEventCard);
        } else {
            cards = previousReminders.map(getEventCard);
        }

        return <div className="nova_holder-notifications-box">
            {cards}
        </div>
    }

    if (!display) return null;

    const futureReminders = reminders.filter(rem => isDateAfterOrEqToday(rem.reminder_datetime));
    const previousReminders = reminders.filter(rem => !isDateAfterOrEqToday(rem.reminder_datetime)).reverse();

    return <div className="fixed_shadow">
        <div className="nova_holder-notifications">
            <div className="nova_notification">
                {getTopPart()}
                {getSelectTimeFrameRow()}
                {getScrollableEventsList()}
            </div>
        </div>
    </div>
};
