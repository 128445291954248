import { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthProvider";
import { AdminVersion, UserType, Version } from "../../context/enums";


export function useVersionChecking({ setConfirmation, type }) {
    const { auth, wasAuthenticatedOnce } = useContext(AuthContext);

    const isUptodateVersion = (auth) => {
        if (!auth || !auth.version || !auth.admin_version) {
            return true;
        }

        if (type === UserType.MANAGER) {
            return auth.admin_version === AdminVersion;
        } else if (type === UserType.USER) {
            return auth.version === Version;
        }
    }

    useEffect(() => {
        if (!wasAuthenticatedOnce) {
            return;
        }

        let isUptoDate = isUptodateVersion(auth);

        if (!isUptoDate) {
            setConfirmation({
                "title": "Version obsolète",
                "text": "La version de Lead Access que vous utilisez n'est plus à jour, veuillez rafraîchir la page.",
                "showYesButton": false,
                "showNoButton": false,
                "specialPopup": null,
                "confirmCallback": () => { }
            });
        }
    }, [auth, wasAuthenticatedOnce]);
}
