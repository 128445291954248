import React from "react";

export default function InputMessage({ inputText, setInputText, handleKeyDown, handleAddUserMessage }) {
    return <div className="nova_down-part-chatbot">
        {/* ajouter utilisateur <div className="nova_button-add-users w-inline-block">
                <img src="./images/nova/Icons_15.svg" loading="lazy" alt="" />
                <div className="text-block-52">Add users</div>
            </div> */}
        <div className="nova_holder-input-conversation-chatbot">
            <div className="form-block-6 w-form">
                <div className="nova_relative-holder-form">
                    <input className="nova_text-field-conversation-chatbot no_attachment w-input"
                        maxLength={255}
                        placeholder="Demandez-moi ce que vous voulez..."
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        type="text" />
                    <div className="nova_absolut-holder-button-send">
                        <div className="nova_button-send-chatbot w-inline-block" onClick={handleAddUserMessage}>
                            <div className="text-block-53">Envoyer</div>
                            <img src="./images/nova/send_message_white.svg" loading="lazy" alt="" />
                        </div>
                    </div>
                </div>
                {/* ajouter attachement <div className="nova_div-holder-attachement">
                        <div className="nova_attachement-button w-inline-block">
                            <img src="./images/nova/Icons_9.svg" loading="lazy" alt="" />
                            <div className="text-block-54">Add attachment</div>
                        </div>
                    </div> */}
            </div>
        </div>
    </div>;
}
