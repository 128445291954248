import React, { useContext, useState } from "react";
import { logout } from "../api/axios";
import AuthContext from "../context/AuthProvider";
import { getNameInitials } from "../lib/tools/strings";

export default function MenuBottom({ currentView }) {
    const { auth, setAuth, openMenu } = useContext(AuthContext);

    const [displayFloatingUserSettings, setDisplayFloatingUserSettings] = useState(false);

    const handleLogout = () => {
        logout().then(() => setAuth({}));
    }

    const getFloatingUserSettings = () => {
        if (!displayFloatingUserSettings) return null;

        const classSuffix = openMenu ? "" : " closed";
        return <div className={`nova_setting-disconnect positioned${classSuffix}`}>
            <div onClick={handleLogout} className="nova_link-settings w-inline-block">
                <img src="./images/nova/disconnect.svg" loading="lazy" alt="" />
                <div>Se déconnecter</div>
            </div>
        </div>;
    }

    const getUserComponent = () => {
        if (openMenu) {
            return <div className="nova_user_stickyness">
                <div className="nova_user-card_container">
                    <div className="nova_user-card">
                        <div className="nova_avatar-text">
                            <div className="nova_holder-image-lead">
                                <div className="text-block-19">{getNameInitials(auth.firstname, auth.lastname)}</div>
                            </div>
                            <div className="nova_holder-texts">
                                <div className="nova_name-avatar">{auth.firstname} {auth.lastname}</div>
                                <div className="nova_email-avatar">{auth.email}</div>
                            </div>
                        </div>
                        <div onClick={() => setDisplayFloatingUserSettings(prev => !prev)}
                            className="nova_dots-avatar w-inline-block">
                            <img src="./images/nova/three_dots.svg" loading="lazy" alt="" />
                        </div>
                    </div>
                    {getFloatingUserSettings()}
                </div>
            </div>;
        } else {
            return <div className="nova_user_stickyness">
                <div className="nova_closed_menu_bottom_conainr">
                    {getFloatingUserSettings()}
                    <div onClick={() => setDisplayFloatingUserSettings(prev => !prev)}
                        className="nova_dots-avatar w-inline-block">
                        <img src="./images/nova/three_dots.svg" loading="lazy" alt="" />
                    </div>
                    <div className="nova_holder-image-lead">
                        <div className="text-block-19">{getNameInitials(auth.firstname, auth.lastname)}</div>
                    </div>
                </div>
            </div>;
        }
    }

    if (!auth || !auth?.user_type) {
        return null;
    }

    if (openMenu) {
        return <div className="nova_menu">
            <div className="nova_menu_top"></div>
            {getUserComponent()}
        </div>;
    } else {
        return <div className="nova_menu_closed">
            <div className="nova_menu_top"></div>
            {getUserComponent()}
        </div>;
    }
}
