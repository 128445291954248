import React, { useState } from "react";


export default function MessageFormDropDown({ title, initialLabel, setValue, options }) {
    const [label, setLabel] = useState(initialLabel);

    return <div className="nova_holder-form-conversation-chatbot">
        <label className="field-label-2">{title}</label>
        <div className="nova_relative-box-dropbox">
            <div className="text-field-3">{label}</div>
            <div className="nova_dropbox-formulaire">
                {options.map((opt) => {
                    function handleClick() {
                        setLabel(opt.label);
                        setValue(opt.value);
                    }
                    return <div key={opt.key} onClick={handleClick} className="nova_drop-navlink w-inline-block">
                        <div className="nova_text">{opt.label}</div>
                    </div>;
                })}
            </div>
            <div className="nova_dropbox-button w-inline-block">
                <img src="./images/nova/arrow_darkgrey_down.svg" loading="lazy" alt="" />
            </div>
        </div>
    </div>;
}
