import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getCompanyUsers, updatePassword } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import ManagementContainer from "../management/management_container";
import NovaInput from "../ux/common/nova_input";
import NovaButton from "../ux/common/nova_button";


export default function UpdatePassword({ setToast }) {
    const [email, setEmail] = useState("__undefined__");
    const [password, setPassword] = useState("");
    const [users, setUsers] = useState([]);

    const { auth, color } = useContext(AuthContext);

    useEffect(() => {
        if (!auth) {
            return;
        }

        getCompanyUsers(auth.company_id).then(data => {
            setUsers(data.data);
        })
    }, [auth]);

    const reinitialize = () => {
        setPassword("");
    }

    const handleUpdatePassword = () => {
        if (!email || email === "__undefined__") {
            setToast({ text: "Echec, veuillez choisir un utilisateur.", severity: "error" });
            return;
        }

        console.log('password.length :>> ', password.length);
        if (!password || password.length <= 4) {
            setToast({ text: "Echec, mot de passe trop court.", severity: "error" });
            return;
        }

        updatePassword(email, password)
            .then(() => {
                reinitialize();
                setToast({ text: "Mot de passe mis à jour", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err);
            });
    };

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname}`;
    }
        // Create options array for NovaInput dropdown
    const userOptions = [
        { value: "__undefined__", label: "Veuillez choisir un utilisateur" },
        ...users.map(user => ({
            value: user.email,
            label: getCollabdisplayString(user)
        }))
    ];


    return (
        <ManagementContainer title={"Mettre à jour un mot de passe"}>
            <Grid key="accountType" item xs={12}>
            <NovaInput
                    label="Type d'utilisateur:"
                    type="select"
                    name="email"
                    placeholder="Veuillez choisir un utilisateur"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    options={userOptions}
                    className="w-full"
                />
            </Grid>

            <Grid item xs={12}>
                <NovaInput type="password" placeholder="Mot de passe" value={password} onChange={(ev) => setPassword(ev.target.value)} />
            </Grid>

            <Grid item xs={12}>
                <NovaButton onClick={handleUpdatePassword}>
                    Mettre à jour
                </NovaButton>
            </Grid>
        </ManagementContainer>
    );
}
