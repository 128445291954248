import { useEffect, useState } from "react";
import { getAllListsAccessibles } from "../../api/axios";
import { ListsNicknames, UserType } from "../../context/enums";


export function useDifferentLists({ auth, accessibleListIds, addAllLeadsList }) {
    const [listIdIdx, setListIdIdx] = useState(0);
    const [allLists, setAllLists] = useState(null);

    const reloadListsAccessibles = (myauth) => {
        if (!myauth || !myauth.id || !myauth.company_id) {
            return;
        }

        // todo(2) clean cette partie lists, getAllListsAccessibles() est appelé 2 fois en double a 2 endroits differents
        getAllListsAccessibles(myauth.company_id, myauth.id).then((data) => {
            let result = [...data];

            if (addAllLeadsList && myauth.user_type === UserType["MANAGER"]) {
                result.push({
                    id: ListsNicknames["ALL"],
                    name: "Tous les leads de l'entreprise",
                    split_by_department: false,
                    nickname: ListsNicknames["ALL"],
                });
            }
            setAllLists(result);
        });
    }

    useEffect(() => {
        reloadListsAccessibles(auth);
    }, [auth, addAllLeadsList]);

    const getAccessibleListsIds = () => {
        if (!auth) {
            return [1];
        }

        if (accessibleListIds === undefined || accessibleListIds === null) {
            return [1];
        }

        let result = [...accessibleListIds];

        if (addAllLeadsList && auth.user_type === UserType["MANAGER"]) {
            result.push(ListsNicknames["ALL"]);
        }

        return result;
    }

    return [
        listIdIdx,
        getAccessibleListsIds,
        setListIdIdx,
        allLists,
        reloadListsAccessibles,
    ];
}
