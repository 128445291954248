import React, { useContext, useState } from "react";
import { storeFeatureUsage } from "../api/axios";
import AuthContext from "../context/AuthProvider";
import { ListsNicknames } from "../context/enums";
import { useUpdatableLead } from "../lib/leads/useUpdatableLead";
import { getDataSourceDisplayValue, getDisplayedDepartement } from "../lib/tools/app_context";
import { isDefined } from "../lib/tools/basics";
import { getCommentLinesCount, getNameInitials } from "../lib/tools/strings";
import LeadField from "./lead_field";
import StatusValue from "./leads/values/status_value";


function Lead(props) {
    const {
        id,
        firstname,
        lastname,
        email,
        phone_number_concatenated,
        zipcode,
        year_of_birth,
        housing_status,
        commentaire,
        statut,
        income_tax,
        assigned_to,
        profession,
        family,
        legal_status,
        adresse,
        enfant,
        city,
        departement,
        company_name,
        company_industry,
        region,
        job_started_on,
        data_source,
        priority,
    } = props.lead;
    const {
        setConfirmation,
        list,
        setPopupCreateReminderLead,
        reminders,
        setToast,
        handleLeadUpdated,
        handleUpdateStatutCounts,
    } = props;

    const { auth } = useContext(AuthContext);

    const [isNameFocused, setIsNameFocused] = useState(false);

    const {
        updateLeadNUI,
        newPriority,
        updatePriority,
        updateFirstname,
        updateLastname,
        handleCopy,
        handleTransferToSalesman,
        newStatut,
        updateStatut,
        newPhone,
        setNewPhone,
        newEmail,
        setNewEmail,
        newCity,
        setNewCity,
        newDepartement,
        setNewDepartement,
        newBirthYear,
        setNewBirthYear,
        newIncomeTax,
        setNewIncomeTax,
        newHousingStatus,
        setNewHousingStatus,
        newFamily,
        setNewFamily,
        newZipcode,
        setNewZipcode,
        newLegalStatus,
        setNewLegalStatus,
        newCompanyIndustry,
        setNewCompanyIndustry,
        newJobStartedOn,
        setNewJobStartedOn,
        newComment,
        setNewComment,
        updateComment,
        newRegion,
        setNewRegion,
        newProfession,
        setNewProfession,
        newAdress,
        setNewAdress,
        newCompanyName,
        setNewCompanyName,
    } = useUpdatableLead({
        id,
        priority,
        lastname,
        firstname,
        commentaire,
        email,
        phone_number_concatenated,
        zipcode,
        year_of_birth,
        housing_status,
        statut,
        income_tax,
        profession,
        family,
        legal_status,
        adresse,
        enfant,
        city,
        departement,
        company_name,
        company_industry,
        region,
        job_started_on,
        handleLeadUpdated,
        handleUpdateStatutCounts,
        setToast,
        setConfirmation,
    });

    // const [showLowPriority, setShowLowPriority] = useState(false);
    // const [showMiddlePriority, setShowMiddlePriority] = useState(false);
    // const [showHighPriority, setShowHighPriority] = useState(false);

    const handleNamesBlur = () => {
        updateLastname();
        updateFirstname();
    }

    const handleNamesContainerBlur = () => {
        setIsNameFocused(false);
    }

    const getTopComponent = () => {
        return <div className="nova_holder-user-fonctions-lead">
            <div className="nova_lead-user">
                <div className="nova_user-abreviation">
                    <div className="nova_abvreviation-user">{getNameInitials(firstname, lastname)}</div>
                </div>
                <div className="nova_holder-name-updates">
                    <div className="nova_name-lead">{firstname} {lastname}</div>
                    {/* <div className="nova_update-user">
                        <img src="./images/nova/Frame-32.svg" loading="lazy" alt="" />
                        <div className="nova_last-update-text">Last update: (todo(3) nrp time a compter aussi)</div>
                        <div className="nova_last-update-text black">Became Sans statut, 3d ago</div>
                    </div> */}
                </div>
            </div>
            <div className="nova_holder-fonctions-lead">
                {/* <a href="#" className="nava_x-times-calls w-inline-block">
                    <div className="nova_x-time-box">
                        <div className="nova_x-calls-text">6 todo(3) reset nrp count tous les mois avant de faire ca</div>
                    </div>
                    <div className="nova_times-text">Times</div>
                </a> */}
                {/* <div className="nova_vertical-line-design"></div> */}
                <div onClick={() => setPopupCreateReminderLead(props.lead)} className="nova_fontions-lead-card w-inline-block">
                    <img src="./images/nova/reminder_black.svg" loading="lazy" alt="" />
                </div>
                {/* <div className="nova_fontions-lead-card w-inline-block">
                    <img src="./images/nova/reminder.svg" className="nova_lead_action_image"  loading="lazy" alt="" />
                </div> */}
                <div className="nova_fontions-lead-card w-inline-block">
                    <img onClick={handleCopy} className="nova_lead_action_image" src="./images/nova/copy_paste.svg" loading="lazy" alt="" />
                </div>
                <div onClick={handleTransferToSalesman} className="nova_fontions-lead-card w-inline-block">
                    <img src="./images/nova/Icons-copie-3_1.svg" className="nova_lead_action_image" loading="lazy" alt="" />
                </div>
                {/* <div className="nova_fontions-lead-card w-inline-block">
                    <img src="./images/nova/Icons-copie-4.svg" className="nova_lead_action_image" loading="lazy" alt="" />
                </div> */}
            </div>
        </div>
    }

    const getStatusCard = () => {
        return <div className="nova_status-lead">
            <div className="nova_icon-text">
                <img src="./images/nova/Frame.svg" loading="lazy" alt="" />
                <div className="nova_status-type-text">Statut</div>
            </div>
            <StatusValue
                leadId={id}
                displayOpeningArrow={true}
                newStatus={newStatut}
                updateStatut={updateStatut}
                setConfirmation={setConfirmation}
                setToast={setToast}
                positionAtRight={false}
            />
        </div>
    }

    const getPriorityCard = () => {
        // const lowClassSuffix = showLowPriority ? " active" : "";
        // const mediumClassSuffix = showMiddlePriority ? " active" : "";
        // const highClassSuffix = showHighPriority ? " active" : "";


        //     <div onClick={() => setShowLowPriority(prev => !prev)}
        //     className={`nova_priorities_hover${lowClassSuffix} w-inline-block`}>
        //     <div className="nova_prioprities-text">Faible</div>
        // </div>
        // <div onClick={() => setShowMiddlePriority(prev => !prev)}
        //     className={`nova_priorities_hover${mediumClassSuffix} medium w-inline-block`}>
        //     <div className="nova_prioprities-text">Moyenne</div>
        // </div>
        // <div onClick={() => setShowHighPriority(prev => !prev)}
        //     className={`nova_priorities_hover${highClassSuffix} high w-inline-block`}>
        //     <div className="nova_prioprities-text">Haute</div>
        // </div>

        const lowClassSuffix = priority === "low" ? " active" : "";
        const mediumClassSuffix = priority === "medium" ? " active" : "";
        const highClassSuffix = priority === "high" ? " active" : "";
        return <div className="nova_status-lead priority">
            <div className="nova_icon-text">
                <img src="./images/nova/priority.svg" loading="lazy" alt="" />
                <div className="nova_status-type-text">Priorité</div>
            </div>
            <div className="nova_level-priority">
                <div onClick={() => updatePriority("low")}
                    className={`nova_priorities_hover${lowClassSuffix} w-inline-block`}>
                    <div className="nova_prioprities-text">Faible</div>
                </div>
                <div onClick={() => updatePriority("medium")}
                    className={`nova_priorities_hover${mediumClassSuffix} medium w-inline-block`}>
                    <div className="nova_prioprities-text">Moyenne</div>
                </div>
                <div onClick={() => updatePriority("high")}
                    className={`nova_priorities_hover${highClassSuffix} high w-inline-block`}>
                    <div className="nova_prioprities-text">Haute</div>
                </div>
            </div>
        </div>
    }

    const getInformationTitle = () => {
        return <div className="nova_contact-info-edit-lead">
            <div className="text-block-28">Informations du contact</div>
            {/* <div className="nova_edit-contact w-inline-block">
                <div className="text-block-29">Edit</div>
                <img src="./images/nova/Icons-copie-5.svg" loading="lazy" alt="" />
            </div> */}
        </div>
    }

    function getInfoField(champ, isLast) {
        if (champ === "phone_number_concatenated") {
            return <LeadField
                key={champ}
                label="Téléphone"
                isLast={isLast}
                champ={champ}
                value={newPhone.length < 10 ? "0" + newPhone : newPhone}
                updateCallback={setNewPhone}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "email") {
            return <LeadField
                key={champ}
                label="Email"
                isLast={isLast}
                champ={champ}
                value={newEmail}
                updateCallback={setNewEmail}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "city") {
            return <LeadField
                key={champ}
                label="Ville"
                isLast={isLast}
                champ={champ}
                value={newCity}
                updateCallback={setNewCity}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "departement") {
            return <LeadField
                key={champ}
                label="Département"
                isLast={isLast}
                champ={champ}
                value={getDisplayedDepartement(newDepartement)}
                updateCallback={setNewDepartement}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "region") {
            return <LeadField
                key={champ}
                label="Région"
                isLast={isLast}
                champ={champ}
                value={newRegion}
                updateCallback={setNewRegion}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "year_of_birth") {
            return <LeadField
                key={champ}
                label="Année de naissance"
                isLast={isLast}
                champ={champ}
                value={newBirthYear}
                updateCallback={setNewBirthYear}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "income_tax") {
            return <LeadField
                key={champ}
                label="Imposition"
                isLast={isLast}
                champ={champ}
                value={newIncomeTax}
                updateCallback={setNewIncomeTax}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "housing_status") {
            return <LeadField
                key={champ}
                label="Statut de résidence"
                isLast={isLast}
                champ={champ}
                value={newHousingStatus}
                updateCallback={setNewHousingStatus}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "family") {
            return <LeadField
                key={champ}
                label="Situation familiale"
                isLast={isLast}
                champ={champ}
                value={newFamily}
                updateCallback={setNewFamily}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "zipcode") {
            return <LeadField
                key={champ}
                label="Code postal"
                isLast={isLast}
                champ={champ}
                value={newZipcode}
                updateCallback={setNewZipcode}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "profession") {
            return <LeadField
                key={champ}
                label="Profession"
                isLast={isLast}
                champ={champ}
                value={newProfession}
                updateCallback={setNewProfession}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "legal_status") {
            return <LeadField
                key={champ}
                label="Statut juridique"
                isLast={isLast}
                champ={champ}
                value={newLegalStatus}
                updateCallback={setNewLegalStatus}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "adresse") {
            return <LeadField
                key={champ}
                label="Addresse"
                isLast={isLast}
                champ={champ}
                value={newAdress}
                updateCallback={setNewAdress}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "company_indudstry") {
            return <LeadField
                key={champ}
                label="Domaine de l'entreprise"
                isLast={isLast}
                champ={champ}
                value={newCompanyIndustry}
                updateCallback={setNewCompanyIndustry}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "company_name") {
            return <LeadField
                key={champ}
                label="Entreprise"
                isLast={isLast}
                champ={champ}
                value={newCompanyName}
                updateCallback={setNewCompanyName}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "job_started_on") {
            return <LeadField
                key={champ}
                label="Date de début d'activité"
                isLast={isLast}
                champ={champ}
                value={newJobStartedOn}
                updateCallback={setNewJobStartedOn}
                updateUICallback={updateLeadNUI}
                setToast={setToast}
            />;
        } else if (champ === "data_source") {
            return <LeadField
                key={champ}
                label="Source"
                isLast={isLast}
                champ={champ}
                value={getDataSourceDisplayValue(data_source)}
                updateCallback={() => { }}
                setToast={setToast}
            />;
        } else {
            return null;
        }
    }

    const reorderFieldsByUsefullness = (fields) => {
        const result = fields.filter(fi => isDefined(props.lead?.[fi]));
        return result.concat(fields.filter(fi => !isDefined(props.lead?.[fi])));
    }

    const getColumnFieldsContent = (fields) => {
        const fieldsOrdered = reorderFieldsByUsefullness(fields);

        return fieldsOrdered.map((nextField, idx) => {
            const isLast = idx === fieldsOrdered.length - 1;
            return getInfoField(nextField, isLast);
        });
    }

    const getColumnInfos = (title, fields) => {
        return <div key={title} className="nova_box-contact-info">
            {getColumnFieldsContent(fields)}
        </div>;
    }

    const getInformationsColumnsContent = () => {
        if (list.nickname === ListsNicknames["B2B_MEDICAL"] || list.nickname === ListsNicknames["B2B_DIRECTOR"]) {
            return [
                getColumnInfos("Identité", ["phone_number_concatenated", "email", "year_of_birth", "profession", "data_source"]),
                getColumnInfos("Localisation", ["city", "departement", "adresse", "region", "zipcode"]),
                getColumnInfos("Compléments", ["job_started_on", "legal_status",
                    "housing_status", "family", "income_tax"]),
            ];
        } else if (list.nickname === ListsNicknames["ALL"]) {
            return [
                getColumnInfos("Identité", ["phone_number_concatenated", "email", "year_of_birth"]),
                getColumnInfos("Localisation", ["city", "departement", "adresse", "region", "zipcode"]),
                getColumnInfos("Compléments", ["income_tax", "profession", "housing_status", "legal_status", "family",
                    "job_started_on", "data_source",
                ]),
            ];
        } else {
            return [
                getColumnInfos("Identité", ["phone_number_concatenated", "email", "year_of_birth", "income_tax"]),
                getColumnInfos("Localisation", ["city", "departement", "adresse", "region", "zipcode"]),
                getColumnInfos("Compléments", ["profession", "housing_status", "legal_status", "family"]),
            ];
        }
    }

    const getInformationsColumns = () => {
        return <div className="nova_holder-leads-informations">
            {getInformationsColumnsContent()}
        </div>;
    }

    const getCommentText = () => {
        return <div className="nova_form-commentaire-lead w-form">
            <div className="nova_form-input">
                <textarea
                    className="nova_commentaire-lead w-input"
                    maxLength={8000}
                    value={newComment || ""}
                    onBlur={updateComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    rows={getCommentLinesCount(newComment)} />
                <div className="nova_holer-img">
                    <img src="./images/nova/Icons-copie-6.svg" loading="lazy" alt="" />
                </div>
                {/* <div className="nova_link-button-lead w-inline-block">
                    <img src="./images/nova/Frame-copie-2.svg" loading="lazy" alt="" />
                </div> */}
            </div>
        </div>
    }

    const getStatusNPriorityFilters = () => {
        return <div className="nova_holder-status-priority">
            {getStatusCard()}
            {getPriorityCard()}
        </div>;
    }

    return <div className="nova_lead-card">
        {getTopComponent()}
        {getStatusNPriorityFilters()}
        {getInformationTitle()}
        {getInformationsColumns()}
        {getCommentText()}
    </div>;
}

export default Lead;
