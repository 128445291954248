const { LeadStatus, Departements, AllStatus } = require("../../context/enums");

function cleanDBStatut(statut) {
    if (statut === null) {
        return LeadStatus["SANS_STATUT"];
    } else if (statut === AllStatus) {
        return "Tous les statuts";
    } else {
        return statut;
    }
}

function getStatutLabel(statut) {
    if (statut === AllStatus) {
        return "Tous les statuts";
    } else {
        return statut;
    }
}

const getDisplayedDepartement = (dept) => {
    const departementName = Departements[dept];
    if (departementName) {
        return `${departementName} (${dept})`;
    } else {
        return dept;
    }
}

const getDataSourceDisplayValue = (value) => {
    if (value === "pages_jaunes") { // todo(3) tout ces trucs sont des side effects de merde a fixed
        return "Pages jaunes";
    } else if (value === "ameli") {
        return "Ameli";
    } else {
        return "";
    }
}

module.exports = {
    cleanDBStatut,
    getStatutLabel,
    getDisplayedDepartement,
    getDataSourceDisplayValue,
};
