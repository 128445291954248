import React, { useContext, useRef, useState } from "react";

import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { parse } from "papaparse";
import { uploadLeads } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { useDifferentLists } from "../../lib/leads/useDifferentLists";
import "../../style/Collaborators.css";
import ManagementContainer from "../management/management_container";
import NovaInput from "../ux/common/nova_input";
import NovaButton from "../ux/common/nova_button";


export default function ImportLeads({ setToast }) {
    const { auth, color, listsIds } = useContext(AuthContext);

    const [_, getAccessibleListsIds, _2, allLists] = useDifferentLists({ auth, accessibleListIds: listsIds, addAllLeadsList: false });
    const [selectedListId, setSelectedListId] = useState("__undefined__");

    const [leadsImported, setLeadsImported] = useState("no"); // values in "no", "progressing", "yes"
    const [file, setFile] = useState(null);
    const [leadsColumns, setLeadsColumns] = useState(null);
    const [leads, setLeads] = useState(null);
    const fileInputRef = useRef(null);

    const handleClick = () => {
      fileInputRef.current.click();
    };

    const reinitialise = () => {
        setFile(null);
        setLeadsColumns(null);
        setLeads(null);
    }

    const hanbleButtonClick = () => {
        if (selectedListId === "__undefined__") {
            setToast({ text: "Veuillez choisir une liste.", severity: "error" });
            return;
        }
        if (!file) {
            setToast({ text: "Veuillez importer un fichier, le fichier n'a peut-être pas fini de se charger.", severity: "error" });
            return;
        }

        setLeadsImported("progressing");
        uploadLeads(auth.company_id, auth.company_nickname, selectedListId, file, "leads")
            .then(() => {
                reinitialise();
                setLeadsImported("yes");
                setToast({ text: "Leads importés, vous allez être contacté d'ici 1 jour ouvré.", severity: "success" });
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err);
            });
    }

    const parseFile = (fileContent) => {
        const firstLine = fileContent.split('\n')[0];
        const delimiter = firstLine.split(',').length >= firstLine.split(';').length ? ',' : ';';

        parse(fileContent, {
            header: true,
            delimiter: delimiter,
            complete: (results) => {
                setLeadsColumns(Object.keys(results.data[0]));
                setLeads(results.data);
            },
            error: (error) => {
                console.error("Error parsing CSV:", error);
            }
        });
    }

    const handleChangeFile = (event) => {
        const file = event.target.files[0];
        setLeadsImported("no");

        if (!file || file.type !== 'text/csv') {
            console.error("Fileis not CSV");
            setToast({ text: "Votre fichier n'est pas un fichier CSV", severity: "error" });
            event.target.value = ''; // Clear the input field
            return;
        }

        setFile(file);

        const reader = new FileReader();
        reader.onload = (e) => {
            const fileContent = e.target.result;
            parseFile(fileContent);
        };
        reader.readAsText(file);
    };

    const getLeadsColumnsTable = () => {
        if (!leadsColumns) {
            return null;
        }

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Colonnes trouvées</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {leadsColumns.map((column) => {
                            return <TableRow key={column} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{column}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    if (listsIds === null || allLists === null) {
        return null;
    }

    const listOptions = [
        { value: "__undefined__", label: "Veuillez choisir" },
        ...getAccessibleListsIds().map(listId => {
            const list = allLists.find(listDetails => listDetails.id === listId);
            return { value: list.id, label: list.name };
        })
    ];

    return (
      <ManagementContainer
        title={"Importer des leads"}
        description={"Importer les leads que vous souhaitez"}>
        <Grid key='csv_phone_column' item xs={12}>
          <NovaInput
            label='Sélectionnez la liste à laquelle vous souhaitez ajouter vos Leads:'
            type='select'
            name='selectedListId'
            placeholder='Veuillez choisir'
            value={selectedListId}
            onChange={(ev) => setSelectedListId(ev.target.value)}
            options={listOptions}
            className='w-full'
          />
        </Grid>

        <Grid item xs={12}>
          <NovaButton onClick={handleClick}>Choisir un fichier</NovaButton>

          <input
            type='file'
            accept='.csv'
            ref={fileInputRef}
            onChange={handleChangeFile}
            style={{ display: "none" }}
          />
          <br />
          {file ? (
            <Typography variant='body1'>{`Fichier chargé: ${file.name}`}</Typography>
          ) : (
            <Typography variant='body1'>Aucun fichier chargé</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <NovaButton onClick={hanbleButtonClick}>
            Importer les leads
          </NovaButton>
          <br />
          {leadsImported === "yes" ? (
            <Typography variant='body1'>Leads importés avec succès</Typography>
          ) : null}
          {leadsImported === "no" ? (
            <Typography variant='body1'>Leads non importés</Typography>
          ) : null}
          {leadsImported === "progressing" ? (
            <Typography variant='body1'>
              Leads en cours d'importation...
            </Typography>
          ) : null}
          <br />
          {getLeadsColumnsTable()}
          <br />
          <br />
          <br />
        </Grid>
      </ManagementContainer>
    );
}
