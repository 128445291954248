import axios from 'axios';
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BottomLeftLegal from './component/onboarding/bottom_left_legal';
import RightHalfScreenImage from './component/onboarding/right_half_screen_image';
import TopLeftLogo from './component/onboarding/top_left_logo';
import Toast from './component/ux/toast';
import AuthContext from "./context/AuthProvider";
import { getBackendHost } from './lib/config';

// import "./style/Login.css";  // todo(2) remove those useless css


export default function Login() {
    const nav = useNavigate();
    const { auth, setAuth, color, secondaryColor } = useContext(AuthContext);
    const [values, setValues] = useState({ email: "", password: "" });
    const [toast, setToast] = useState(null);

    const URL = `${getBackendHost()}/v1/la/`;

    const axiosInstance = axios.create({
        baseURL: URL
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { email, password } = values
            const response = await axiosInstance.post('login',
                JSON.stringify({ email, password }),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response) {
                if (response.data.is_active === true) {
                    setAuth(response.data);
                    nav('/');
                } else {
                    setAuth({});
                    setToast({ text: "Votre compte a été désactivé.", severity: "error" });
                }
            }
        } catch (err) {
            setToast({ text: "Erreur de connection, veuillez réessayer puis vérifier votre mot de passe.", severity: "error" });
            // todo(1) gerer tous ces messages d'erreur
            // if (!err?.response) {
            //     setErrMsg('No Server Response');
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Missing Username or Password');
            // } else if (err.response?.status === 401) {
            //     setErrMsg('Unauthorized');
            // } else {
            //     setErrMsg('Login Failed');
            // }
        }
    }

    const updateEmail = (newEmail) => {
        let cleanedEmail = newEmail.toLowerCase();

        // exception cause one mail was created with an upper letter
        if (cleanedEmail === "i.seridi@bnvce.fr") {
            cleanedEmail = "I.seridi@bnvce.fr"
        }

        setValues({ ...values, email: cleanedEmail });
    }

    const getSubscripePart = () => {
        return <div className="nova_holder-horizontal-text">
            <div className="text-block-40">Vous n'avez pas de compte?</div>
            <Link to={'/subscribe'} style={{ textDecoration: 'none', color: 'black' }}>
                <div className="nova_signup-link">Inscrivez-vous</div>
            </Link>
        </div>;
    }

    const getLoginForm = () => {
        return <div className="nova_onboarding-login">
            <div className="nova_vertical-text">
                <div className="nova_heading">Connexion</div>
                <div className="text-block-38">Veuillez entrer vos informations pour accéder à votre compte.</div>
            </div>
            <div className="nova_holder-form-nova">
                <div className="w-form">
                    <div>
                        <label htmlFor="name" className="field-label">Email</label>
                        <div className="nova_holder-input-login">
                            <input id="email"
                                name="email"
                                value={values.email}
                                onChange={e => updateEmail(e.target.value)}
                                className="nova_form-login w-input"
                                maxLength={255}
                                placeholder="Email"
                                type="email" />
                            <div className="nova_holder-icon-input-absolut">
                                <img src="./images/nova/icon_login.svg" loading="lazy" alt="" />
                            </div>
                        </div>
                        <label htmlFor="email" className="field-label">Mot de passe</label>
                        <div className="nova_holder-input-login">
                            <input type="password"
                                id="password"
                                name="password"
                                onChange={e => { setValues({ ...values, password: e.target.value }) }}
                                className="nova_form-login w-input"
                                maxLength={255}
                                placeholder="Mot de passe" />
                            <div className="nova_holder-icon-input-absolut">
                                <img src="./images/nova/icon_password.svg" loading="lazy" alt="" />

                            </div>
                        </div>
                        {/* <div className="nova_remenberme-forgot">
                            <label className="w-checkbox nova_rememberme-checkbox">
                                <input type="checkbox" className="w-checkbox-input" />
                                <span className="checkbox-label-3 w-form-label" for="checkbox">Remember me</span>
                            </label>
                            <div className="nova_forgot-password">Forgot password?</div>
                        </div> */}
                        <input type="submit" onClick={handleSubmit} className="nova_login-button w-button" value="Se connecter" />
                    </div>
                </div>
                {getSubscripePart()}
            </div>
        </div>;
    }

    const getLeftPart = () => {
        return <div className="nova_leftside-onboarding">
            {getLoginForm()}
            <TopLeftLogo />
            <BottomLeftLegal />
        </div>;
    }

    return <div className="nova_onboarding-section">
        {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
        {getLeftPart()}
        <RightHalfScreenImage imgSrc="login_presentation.svg" isImageLargeWithCenteredContent={false} />
    </div>;
}
