import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthProvider";
import { getListInitialView, ListDefaultView } from "../../context/enums";
import { useLeadsLists } from "../../lib/leads/useLeadsLists";
import { useUpdatableLeadsCount } from "../../lib/leads/useUpdatableLeadsCount";
import Lead from "../lead";
import LeadsTable from "./leads_table";
import LeadsFilters from "./navigation/leads_filters";
import PagesNavigations from "./navigation/pages_navigations";
import SearchBar from "./navigation/search_bar";


export default function LeadsPageCenter({ setToast, setConfirmation, setPopupCreateReminderLead, addAllLeadsList = false }) {
    const { listsIds, openMenu } = useContext(AuthContext);

    const [totalCount, handleUpdateStatutCounts, getStatusLeadsCount] = useUpdatableLeadsCount({ getCompanyStats: false });

    const mainClassexSuffix = openMenu ? "" : " menu_closed";

    const {
        leads,
        getViewedLeads,
        allLists,
        listIdIdx,
        getAccessibleListsIds,
        handleStatusChange,
        handleResetAllFilters,
        handleRegionFilterChange,
        handleTaxFilterChange,
        handleJobCategoryChange,
        handleMinAgeFilterChange,
        handleMaxAgeFilterChange,
        handleDepartementFilterChange,
        handleLeadUpdated,
        handleListIdChange,
        handleSearchChange,
        handlePagesModeClickNextPage,
        handlePagesModeClickPreviousPage,
        handlePriorityChange,
        handleJobChange,
        handleDataSourceChange,
        dataSource,
        job,
        minAge,
        maxAge,
        statut,
        departement,
        jobCategory,
        region,
        highTaxOnly,
        priority,
        currentPage,
        maxPageFetched,
        itemParPage,
        reloadListsAccessibles,
        getCountActiveExtraFilters,
    } = useLeadsLists({ initialItemsPerPage: 50, startsWithAllStatus: true, addAllLeadsList });

    const [view, setView] = useState(ListDefaultView);

    // when list is updated, update defaultView
    useEffect(() => {
        if (allLists === null) return;
        let listId = getAccessibleListsIds()[listIdIdx];
        let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

        setView(getListInitialView(list));
    }, [allLists, listIdIdx]);

    const getListsSelectorsLeftPart = () => {
        const isLastPageFull = leads.length === (maxPageFetched + 1) * itemParPage;
        const areThereLeadsLeft = isLastPageFull || currentPage !== maxPageFetched;
        return <div className="nova_lists_select_right">
            {currentPage > 0 ? <div onClick={handlePagesModeClickPreviousPage} className="nova_arrow_container">
                <img src="./images/nova/arrow.svg" loading="lazy" alt="" className="nova_arrow rotated" />
            </div> : null}
            {areThereLeadsLeft ? <div onClick={() => handlePagesModeClickNextPage(currentPage + 1)}
                className="nova_arrow_container">
                <img src="./images/nova/arrow.svg" loading="lazy" width="10" alt="" className="nova_arrow" />
            </div> : null}
        </div>
    }

    const getSearchNFilterComponent = () => {
        if (listIdIdx === null || allLists === null) return null;

        let listId = getAccessibleListsIds()[listIdIdx];
        let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

        return <div className="nova_search-tool leads_sticky_view">
            <SearchBar handleSearchChange={handleSearchChange} isLarge={false} />
            <LeadsFilters
                displayToggleViewButtons={true}
                list={list}
                leads={leads}
                allLists={allLists}
                listIdIdx={listIdIdx}
                listsIds={listsIds}
                getAccessibleListsIds={getAccessibleListsIds}
                view={view}
                setView={setView}
                handleStatusChange={handleStatusChange}
                handleResetAllFilters={handleResetAllFilters}
                handleRegionFilterChange={handleRegionFilterChange}
                handleTaxFilterChange={handleTaxFilterChange}
                handleJobCategoryChange={handleJobCategoryChange}
                handleMinAgeFilterChange={handleMinAgeFilterChange}
                handleMaxAgeFilterChange={handleMaxAgeFilterChange}
                handleDepartementFilterChange={handleDepartementFilterChange}
                handlePriorityChange={handlePriorityChange}
                handleJobChange={handleJobChange}
                handleDataSourceChange={handleDataSourceChange}
                dataSource={dataSource}
                job={job}
                minAge={minAge}
                maxAge={maxAge}
                statut={statut}
                departement={departement}
                jobCategory={jobCategory}
                region={region}
                highTaxOnly={highTaxOnly}
                priority={priority}
                totalCount={totalCount}
                getStatusLeadsCount={getStatusLeadsCount}
                reloadListsAccessibles={reloadListsAccessibles}
                getCountActiveExtraFilters={getCountActiveExtraFilters}
            />
        </div>
    }

    const getListsSelectorsComponent = () => {
        let listsElements = [];

        // get lists tabs elements
        if (listsIds !== null && allLists !== null) {
            listsElements = getAccessibleListsIds().map((listId, nextIdx) => {
                const isListSelected = listIdIdx === nextIdx;
                let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

                const classSuffix = isListSelected ? " active" : "";
                return <div key={listId} onClick={() => handleListIdChange(nextIdx)}
                    className={`nova_text-selector-old ${classSuffix}`}>
                    {list.name}
                </div>;
            });
        }

        // add spaces between elments
        listsElements = listsElements.reduce((acc, el, idx) => {
            if (idx === 0) {
                acc.push(el);
            }
            else {  // add space before each element except the first one
                acc.push(<div key={`__space_${idx}`} className="nova_lists_space"></div>);
                acc.push(el);
            }
            return acc;
        }, []);

        return <div className="nova_selection-leads leads_sticky_view">
            {listsElements}
            {/* plus button to add a list 
                <div className="nova_selectors icon w-inline-block">
                    <div className="nova_relative-box">
                        <img src="./images/nova/Icons-13.svg" loading="lazy" alt="" className="nova" />
                    </div>
                </div> */}
            {getListsSelectorsLeftPart()}
        </div>;
    }

    const getLeadsComponent = () => {
        if (leads === null) {
            return null;
        }

        if (allLists == null || listsIds === null) {
            return null;
        }

        let listId = getAccessibleListsIds()[listIdIdx];
        let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

        if (view === "table") {
            return <LeadsTable
                key={list.id} // reset view preferences, and filters when update
                isDisplayedOnLeadsPage={true}
                isAlreadyContained={true}
                displayPageNavigation={true}
                viewedLeads={getViewedLeads()}
                leads={leads}
                list={list}
                currentPage={currentPage}
                itemParPage={itemParPage}
                maxPageFetched={maxPageFetched}
                handlePagesModeClickNextPage={handlePagesModeClickNextPage}
                handleLeadUpdated={handleLeadUpdated}
                handleUpdateStatutCounts={handleUpdateStatutCounts}
                setConfirmation={setConfirmation}
                setToast={setToast}
                setPopupCreateReminderLead={setPopupCreateReminderLead}
                reloadListsAccessibles={reloadListsAccessibles}
            />;
        } else if (view === "cards") {
            return getViewedLeads().map((lead) => {
                // const leadReminders = reminders ? reminders.filter(re => re.lead_id === lead.id) : [];
                return <Lead
                    key={lead.id}
                    lead={lead}
                    list={list}
                    setConfirmation={setConfirmation}
                    setPopupCreateReminderLead={setPopupCreateReminderLead}
                    // reminders={leadReminders} // todo(2) plus tard remettre, sert surtout a afficher reminder existnt avant d n rajouter pour eviter d'en remettre un en double
                    setToast={setToast}
                    handleLeadUpdated={handleLeadUpdated}
                    handleUpdateStatutCounts={handleUpdateStatutCounts}
                />;
            });
        }
    }

    return <div className={`nova_crm leads_sticky_view${mainClassexSuffix}`}>
        <div className="nova_holder-num-leads">
            <div className="nova_num-leads">{totalCount} leads</div>
        </div>
        {getListsSelectorsComponent()}
        {getSearchNFilterComponent()}
        <div className="leads_sticky_view_stable_part">
            {getLeadsComponent()}
            {view === "cards" ? <PagesNavigations mainClass="nova_cards-view-pagination-contaner"
                leads={leads}
                currentPage={currentPage}
                itemParPage={itemParPage}
                maxPageFetched={maxPageFetched}
                handlePagesModeClickNextPage={handlePagesModeClickNextPage}
            /> : null}
        </div>
    </div>;
}
