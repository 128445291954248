import { useEffect, useRef, useState } from "react";
import { getUserReminders, setAsTriggered } from "../../api/axios";
import { isDateToday, timeDiffFromNowInSeconds } from "../tools/datetime";
import { addNotification } from "../tools/notifications";
import { useVisibility } from "../ui/useVisibility";


/*
    // todo(3) popup.js pas reajoute a nouveau design
    CONTEXT: dans popup.js et notification.js se tiens le code pour demander l'autorisation pour les notifications

    an active reminder is a reminder whose trigger datetime already has passed and that has not been marked as viewed

    features:
        * for active dailyt events: notification in web browser
        * for active dailyt events: popup inside all tabs -- todo(2) readd
*/


// todo(3) a chaque fois qu'on recreer des triggers, on doit supprimer ceux du passe sinon ca en fait trop
// todo(3) il faudrait aussi verifier que le reminder n'a pas ete activé avant de rajouter un trigger
// todo(1) remove random time before triggering event, and sync several tab with the same app opened to only have 1 trigger
// todo(2) it is heavy on the database, AND it is not optimised for when there will be thousands of reminders per user
export function useReminders({ auth }) {
    const [reminders, setReminders] = useState([]); // contains only active reminders
    const timeoutsRef = useRef([]); // Store all scheduled timeouts

    const isVisible = useVisibility();

    useEffect(() => {
        if (isVisible) {
            reloadReminders(auth);
        }
    }, [auth, isVisible]);

    const markReminderLocallyAsViewed = (reminderId, newValue) => {
        setReminders(prevReminders => {
            if (prevReminders === null) {
                return null;
            }

            const newReminders = [...prevReminders];
            return newReminders.map(rem => {
                if (rem.id === reminderId) {
                    return { ...rem, marked_as_viewed: newValue };
                } else {
                    return rem;
                }
            })
        });
    }

    const triggersNotificationForTodayReminders = (newReminders) => {
        // Cancel previously scheduled timeouts
        timeoutsRef.current.forEach(clearTimeout);
        timeoutsRef.current = [];

        for (const nextRm of newReminders) {
            if (!isDateToday(nextRm.reminder_datetime)) {
                continue;
            }

            if (nextRm.triggered === true) {
                continue;
            }

            const delayInSeconds = Math.max(timeDiffFromNowInSeconds(nextRm.reminder_datetime), 0);
            const timeoutId = setTimeout(() => {
                addNotification(`Rappel: ${nextRm.lead_firstname} ${nextRm.lead_lastname}`, nextRm.reason || "");
                setAsTriggered(nextRm.id);
            }, delayInSeconds * 1000);
            timeoutsRef.current.push(timeoutId);
        }
    }

    async function reloadReminders(auth) {
        if (auth?.tmp_has_access_to_reminder !== true) {
            return;
        }
        console.log("reload reminders");

        getUserReminders(auth.id).then((newReminders) => {
            triggersNotificationForTodayReminders(newReminders);
            setReminders(newReminders);
        });
    }

    useEffect(() => {
        if (!auth?.id) {
            return;
        }

        reloadReminders(auth);
        const intervalId = setInterval(() => {
            reloadReminders(auth);
        }, 30 * 60 * 1000); // Update every 30 minutes

        return () => clearInterval(intervalId);
    }, [auth]);

    return [reminders, reloadReminders, markReminderLocallyAsViewed];
};
