import React from "react";
import { isDefined } from "../../lib/tools/basics";
import Transfer2SalesmanNotification from "./confirmations/transfer2salesman";

const ConfirmNotification = ({ title, text, confirmCallback, setConfirmation, showYesButton, showNoButton, specialPopup, color, setToast }) => {
    const handleYes = () => {
        confirmCallback();
        setConfirmation(null);
    };

    const handleNo = () => {
        setConfirmation(null);
    };

    if (isDefined(specialPopup) && specialPopup.type === "transfer2Salesman") {
        return <Transfer2SalesmanNotification
            text={text}
            setConfirmation={setConfirmation}
            color={color}
            confirmCallback={confirmCallback}
            companyId={specialPopup.companyId}
            leadId={specialPopup.leadId}
            emailNotification={specialPopup.emailNotification === true}
            setToast={setToast}
        />;
    }

    const hasButtons = () => {
        return showYesButton || showNoButton;
    }

    const getButtons = () => {
        if (!hasButtons()) return null;

        return <div className="nova_holder-buttons-popup">
            {showYesButton ? <div onClick={handleYes} className="nova_button-delete w-button">
                Oui
            </div> : null}
            {showNoButton ? <div onClick={handleNo} className="nova_cancel-button-reminder w-button">
                Non
            </div> : null}
        </div>;
    }

    const popupSuffix = hasButtons() ? "" : " no_button";
    return <div className="fixed_shadow">
        <div className="popup_positionner">
            <div className="nova_popup_confirmation">
                <div className={`nova_holder-msg-popup${popupSuffix}`}>
                    <img src="./images/nova/big_warning.svg" loading="lazy" alt="" />
                    <div className="nova_holder-text-popup">
                        <div className="text-block-36">{title}</div>
                        <div className="text-block-37">{text}</div>
                    </div>
                    {getButtons()}
                </div>
            </div>
        </div>
    </div>;
};

export default ConfirmNotification;
