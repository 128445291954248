import { Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { addWorker, getCompanyUsers } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { UserType } from "../../context/enums";
import { capitalizeFirstLetter } from "../../lib/tools/strings";
import UpdatePassword from "./update_password";
import ManagementContainer from "../management/management_container";
import NovaInput from "../ux/common/nova_input";
import NovaButton from "../ux/common/nova_button";


// todo(2) ajouter un toast si ca marche
export default function AddWorker({ setToast }) {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [office, setOffice] = useState("__undefined__");
    const [userType, setUserType] = useState(UserType["USER"]);

    const [collaborators, setCollaborators] = useState([]);

    const { auth } = useContext(AuthContext);

    const reinitialize = () => {
        setFirstname("");
        setLastname("");
        setEmail("");
        setPassword("");
        setOffice("__undefined__");
        setUserType(UserType["USER"]);
    }

    const handleAddWorker = () => {
        if (
            !firstname || firstname.length <= 1 ||
            !lastname || lastname.length <= 1 ||
            !email || email.length <= 2 ||
            !password || password.length <= 2
        ) {
            setToast({ text: "Echec, email ou mot de passe ou prénom ou nom imcomplet.", severity: "error" });
            return;
        }

        const cleanedOffice = office === "__undefined__" ? null : office;

        addWorker(firstname, lastname, email, password, auth.company_id, userType, cleanedOffice)
            .then(() => {
                reinitialize();
                setToast({ text: "Utilisateur créé.", severity: "success" });
                getCompanyUsers(auth.company_id).then(res => setCollaborators(res.data));
            })
            .catch(err => {
                setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
                console.error(err)
            });
    };

    useEffect(() => {
        if (!auth) {
            return;
        }

        getCompanyUsers(auth.company_id).then(res => setCollaborators(res.data));
    }, [auth]);

    const getOfficeSelectorComponent = () => {
        if (!auth.company_offices) {
            return null;
        }

        const offices = JSON.parse(auth.company_offices);
        if (offices.length === 0) {
            return null;
        }

        return <Grid item xs={12}>
            <InputLabel>Bureau:</InputLabel>
            <Select value={office} onChange={ev => setOffice(ev.target.value)}>
                <MenuItem key="__undefined__" value="__undefined__">Choisissez un bureau</MenuItem>
                {offices.map((value) => {
                    return <MenuItem key={value} value={value}>{capitalizeFirstLetter(value)}</MenuItem>;
                })}
            </Select>
        </Grid>;
    }

    const getCollaboratorsTableComponent = () => {
        let companyHasOffices = auth && auth.company_offices;
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Prénom</TableCell>
                            <TableCell>Email</TableCell>
                            {companyHasOffices ? <TableCell>Bureau</TableCell> : null}
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collaborators.map((user) => {
                            return <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">{user.lastname}</TableCell>
                                <TableCell>{user.firstname}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                {companyHasOffices ? <TableCell>{user.office}</TableCell> : null}
                                <TableCell>{user.user_type}</TableCell>
                            </TableRow>;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    if (!auth) {
        return null;
    }
     // TODO: Check the style={{ color }} importance on header line 131

    return (
      <>
        <ManagementContainer
          title='Ajouter un collaborateur'
          description='Ajouter un collaborateur à votre entreprise.'>
          <Grid container columnSpacing={"12px"} rowSpacing={"24px"}>
            <Grid item xs={6}>
              <NovaInput
                name={firstname}
                placeholder={"Prénom"}
                onChange={(ev) =>
                  setFirstname(capitalizeFirstLetter(ev.target.value))
                }
                required
              />
            </Grid>
            <Grid item xs={6}>
              <NovaInput
                name={lastname}
                placeholder={"Nom"}
                onChange={(ev) =>
                  setLastname(capitalizeFirstLetter(ev.target.value))
                }
                required
              />
            </Grid>
            <Grid item xs={6}>
              <NovaInput
                name={email}
                placeholder={"Email"}
                onChange={(ev) => setEmail(ev.target.value.toLowerCase())}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <NovaInput
                name={password}
                placeholder={"Mot de passe"}
                onChange={(ev) => setPassword(ev.target.value)}
                required
              />
            </Grid>
          </Grid>
          {getOfficeSelectorComponent()}

          <Grid item xs={12}>
            {/* Todo: create the css from the webflow in the label */}
            <NovaInput
              label={"Type d'utilisateur"}
              type='select'
              name='userType'
              value={userType}
              onChange={(ev) => setUserType(ev.target.value)}
              options={Object.values(UserType).map((value) => ({
                label: value,
                value: value,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <NovaButton onClick={handleAddWorker}>
              Ajouter
            </NovaButton>
            <br />
            <br />
            <br />
          </Grid>
        </ManagementContainer>

        <ManagementContainer
          title={"Liste des collaborateurs"}
          description={"Liste de tous les collaborateurs"}>
          <Grid item xs={12}>
            {getCollaboratorsTableComponent()}
            <br />
            <br />
            <br />
          </Grid>
        </ManagementContainer>

        <UpdatePassword setToast={setToast} />
        <Grid item xs={12}>
          <br />
          <br />
          <br />
        </Grid>
      </>
    );
}
