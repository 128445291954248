import { useEffect, useState } from "react";
import { searchJobs } from "../../api/axios";


export function useSearchJobs({ selectedJob }) {
    /* 
        use selectedJob input text to return in jobsFound, the jobs to display in a dropdown list
    */
    const [jobsFound, setJobsFound] = useState([]);

    useEffect(() => {
        if (selectedJob.length < 3) {
            setJobsFound([]);
            return;
        }
        searchJobs(selectedJob).then(data => setJobsFound(data.data.jobs));
    }, [selectedJob]);

    return jobsFound;
}
