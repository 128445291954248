import React, { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import { canUseChatbot } from "../context/enums";
import { isReminderActive } from "../lib/reminders/tools";

export default function PageTitle({ title, setDisplayNotificationCenter, setDisplayChatbot, reminders }) {
    const { auth, openMenu } = useContext(AuthContext);

    const isThereOneActiveReminder = reminders.filter(isReminderActive).length > 0;
    const imgSrcSuffix = isThereOneActiveReminder ? "_active" : "";

    const mainClassexSuffix = openMenu ? "" : " menu_closed";
    return <div className={`nova-leads-notifications${mainClassexSuffix}`}>
        <div className="nova_title-leads">{title}</div>
        <div className="nova_buttons">
            {canUseChatbot(auth) ? <div onClick={() => setDisplayChatbot(true)}
                className="nova_button-notification w-inline-block">
                <img src="./images/nova/star.svg" loading="lazy" alt="" />
            </div> : null}
            <div onClick={() => setDisplayNotificationCenter(true)} className="nova_button-notification w-inline-block">
                <img src={`./images/nova/notification${imgSrcSuffix}.svg`} loading="lazy" alt="" />
            </div>
        </div>
    </div>;
}
