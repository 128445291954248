import React, { useContext, useState } from "react";
import LeadsTable from "./component/leads/leads_table";
import LeadsFilters from "./component/leads/navigation/leads_filters";
import SearchBar from "./component/leads/navigation/search_bar";
import MenuBottom from "./component/menu_bottom";
import MenuTop from "./component/menu_top";
import GraphRatioMeetingsPlanned from "./component/metrics/graphs/graph_ratio_meetings_planned";
import NRPPieCharts from "./component/metrics/graphs/nrp_pie_charts";
import { useUserDaysUsage } from "./component/metrics/useUserDaysUsage";
import PageTitle from "./component/page_title";
import PopupAddReminder from "./component/popups/popup_add_reminder";
import PopupChatbot from "./component/popups/popup_chatbot";
import NotificationCenter from "./component/reminders/notification_center";
import DashboardFirstStats from "./component/stats/dashboard_first_stats";
import ConfirmNotification from "./component/ux/confirmation";
import Toast from "./component/ux/toast";
import AuthContext from "./context/AuthProvider";
import { LeadStatus, UserType } from "./context/enums";
import { useLeadsLists } from "./lib/leads/useLeadsLists";
import { useUpdatableLeadsCount } from "./lib/leads/useUpdatableLeadsCount";
import { useReminders } from "./lib/reminders/useReminders";
import { useVersionChecking } from "./lib/versions/useVersionChecking";

function MainDashboard() {
    const { auth, listsIds, openMenu, color } = useContext(AuthContext);

    const [popupCreateReminderLead, setPopupCreateReminderLead] = useState(null);
    const [displayNotificationCenter, setDisplayNotificationCenter] = useState(false);

    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [displayChatbot, setDisplayChatbot] = useState(false);
    useVersionChecking({ setConfirmation, type: UserType.USER }); // todo(1) ajouter celle la aussi pour les pages admin

    const [reminders, reloadReminders, markReminderLocallyAsViewed] = useReminders({ auth });

    // todo(1) on peut pas mettre ca avec le graph ?.... ou au niveau de auth pour le garder en commun ?
    const [daysUserUsageData] = useUserDaysUsage({ userId: auth.id, duration: 14, job: null });

    const [totalCount, handleUpdateStatutCounts, getStatusLeadsCount] = useUpdatableLeadsCount({ getCompanyStats: false }); // todo(2) mettre dans auth

    const {
        leads,
        getViewedLeads,
        allLists,
        listIdIdx,
        getAccessibleListsIds,
        handleStatusChange,
        handleResetAllFilters,
        handleRegionFilterChange,
        handleTaxFilterChange,
        handleJobCategoryChange,
        handleMinAgeFilterChange,
        handleMaxAgeFilterChange,
        handleDepartementFilterChange,
        handleLeadUpdated,
        handleListIdChange,
        handleSearchChange,
        handlePagesModeClickNextPage,
        handlePagesModeClickPreviousPage,
        handlePriorityChange,
        handleJobChange,
        handleDataSourceChange,
        dataSource,
        job,
        minAge,
        maxAge,
        statut,
        jobCategory,
        region,
        highTaxOnly,
        departement,
        priority,
        currentPage,
        maxPageFetched,
        itemParPage,
        reloadListsAccessibles,
        getCountActiveExtraFilters,
    } = useLeadsLists({ initialItemsPerPage: 5, startsWithAllStatus: true });

    const getRatioRdvPlannedByDay = () => {
        return <div className="nova_complex_leads graph large">
            <div className="nova_complex_stats_top">
                <div className="nova_stats_title darkgrey">Taux de prise de RDV</div>
            </div>
            <div className="nova_complex_stats_bottom small_bottom_margin">
                <GraphRatioMeetingsPlanned daysUserUsageData={daysUserUsageData} />
            </div>
        </div>;
    }

    const getRatioNRP = () => {
        return <div className="nova_complex_leads graph">
            <div className="nova_complex_stats_top">
                <div className="nova_stats_title darkgrey">Ratio de NRP</div>
            </div>
            <div className="nova_complex_stats_bottom more_side_paddings less_top_padding">
                <NRPPieCharts totalCount={totalCount} nrpCount={getStatusLeadsCount(LeadStatus.NRP) || 0} />
            </div>
        </div>;
    }

    const getSecondLineOfStats = () => {
        return <div className="nova_onedim_stats">
            {getRatioRdvPlannedByDay()}
            {getRatioNRP()}
        </div>;
    }

    const getLeadsTopPart = () => {
        return <div className="nova_dashboards_lead_top">
            <div className="nova_stats_title darkgrey">Listes de leads</div>
            <div className="noa_dashboard_leads_multibuttons">
                <SearchBar handleSearchChange={handleSearchChange} isLarge={true} />
                {/* <div className="div-block"></div>
                <Link to="/leads" style={{ textDecoration: 'none', color: 'black' }}>
                    <div className="nova_leads_filter_button">
                        <div className="text-block-17">Voir plus</div>
                    </div>
                </Link> */}
            </div>
        </div>
    }

    // todo(2) pleinsde code en double dans la partie logique
    const getListsSelectorsComponent = () => {
        let listsElements = [];

        // get lists tabs elements
        if (listsIds !== null && allLists !== null) {
            listsElements = getAccessibleListsIds().map((listId, nextIdx) => {
                const isListSelected = listIdIdx === nextIdx;
                let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

                const classSuffix = isListSelected ? " selected" : "";
                return <div key={listId}
                    onClick={() => handleListIdChange(nextIdx)}
                    className={`nova_leads_filter_button${classSuffix}`}>
                    <div className={`text-block-17${classSuffix}`}>{list.name}</div>
                </div>;
            });
        }

        return <div className="noa_dashboard_leads_multibuttons small_gap">
            {listsElements}
        </div>;
    }

    const getLeadsSecondTopPart = () => {
        if (listIdIdx === null || allLists === null) return null;

        let listId = getAccessibleListsIds()[listIdIdx];
        let list = allLists?.filter((listDetails) => listDetails["id"] === listId)[0];

        return <div className="nova_dashboards_lead_second_top">
            {getListsSelectorsComponent()}
            <LeadsFilters
                displayToggleViewButtons={false}
                list={list}
                leads={leads}
                allLists={allLists}
                listIdIdx={listIdIdx}
                listsIds={listsIds}
                getAccessibleListsIds={getAccessibleListsIds}
                view="table"
                setView={() => { }}
                handleStatusChange={handleStatusChange}
                handleResetAllFilters={handleResetAllFilters}
                handleRegionFilterChange={handleRegionFilterChange}
                handleTaxFilterChange={handleTaxFilterChange}
                handleJobCategoryChange={handleJobCategoryChange}
                handleMinAgeFilterChange={handleMinAgeFilterChange}
                handleMaxAgeFilterChange={handleMaxAgeFilterChange}
                handleDepartementFilterChange={handleDepartementFilterChange}
                handlePriorityChange={handlePriorityChange}
                handleJobChange={handleJobChange}
                handleDataSourceChange={handleDataSourceChange}
                dataSource={dataSource}
                job={job}
                minAge={minAge}
                maxAge={maxAge}
                statut={statut}
                departement={departement}
                jobCategory={jobCategory}
                region={region}
                highTaxOnly={highTaxOnly}
                priority={priority}
                totalCount={totalCount}
                getStatusLeadsCount={getStatusLeadsCount}
                reloadListsAccessibles={reloadListsAccessibles}
                getCountActiveExtraFilters={getCountActiveExtraFilters}
            />
        </div>;
    }

    const getLeadsPart = () => {
        if (listsIds === null || allLists === null) return null;

        const listId = getAccessibleListsIds()[listIdIdx];
        let list = allLists?.filter((listDetails) => listDetails.id === listId)[0];

        return <div className="nova_card">
            {getLeadsTopPart()}
            {getLeadsSecondTopPart()}
            <LeadsTable
                key={list.id} // reset view preferences, and filters when update
                isDisplayedOnLeadsPage={false}
                isAlreadyContained={false}
                displayPageNavigation={false}
                viewedLeads={getViewedLeads()}
                leads={leads}
                list={list}
                currentPage={currentPage}
                itemParPage={itemParPage}
                maxPageFetched={maxPageFetched}
                handlePagesModeClickNextPage={handlePagesModeClickNextPage}
                handleLeadUpdated={handleLeadUpdated}
                handleUpdateStatutCounts={handleUpdateStatutCounts}
                setConfirmation={setConfirmation}
                setToast={setToast}
                setPopupCreateReminderLead={setPopupCreateReminderLead}
                reloadListsAccessibles={reloadListsAccessibles}
            />
        </div>;
    }

    const getDashboardContent = () => {
        const mainClassexSuffix = openMenu ? "" : " menu_closed";
        return <div className={`nova_crm${mainClassexSuffix}`}>
            <DashboardFirstStats totalCount={totalCount} getStatusLeadsCount={getStatusLeadsCount} />
            {getSecondLineOfStats()}
            {getLeadsPart()}
        </div>;
    }

    if (!auth) {
        return null;
    }

    // range the popup here too
    return <>
        {displayChatbot ? <PopupChatbot handleCloseButton={() => setDisplayChatbot(false)} /> : null}
        <section className="nova_holder-software-grid">
            <NotificationCenter
                display={displayNotificationCenter}
                setDisplay={setDisplayNotificationCenter}
                reminders={reminders}
                reloadReminders={reloadReminders}
                markReminderLocallyAsViewed={markReminderLocallyAsViewed}
                setToast={setToast}
            />
            {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
            {confirmation !== null && <ConfirmNotification title={confirmation.title}
                text={confirmation.text}
                confirmCallback={confirmation.confirmCallback}
                setConfirmation={setConfirmation}
                color={color}
                showYesButton={confirmation.showYesButton}
                showNoButton={confirmation.showNoButton}
                specialPopup={confirmation.specialPopup}
                setToast={setToast} />}
            {popupCreateReminderLead !== null ? <PopupAddReminder key={popupCreateReminderLead.id}
                lead={popupCreateReminderLead}
                setToast={setToast}
                successCallback={() => reloadReminders(auth)} /* todo(1) mettre de cote avec tout ca popupCreateReminderLeadId*/
                closingCallback={() => setPopupCreateReminderLead(null)}
            /> : null}
            <MenuTop currentView="dashboard" />
            <PageTitle
                title="Dashboard"
                setDisplayNotificationCenter={setDisplayNotificationCenter}
                setDisplayChatbot={setDisplayChatbot}
                reminders={reminders}
            />
            <MenuBottom currentView="main_dashboard" />
            {getDashboardContent()}
        </section>
    </>;
}

export default MainDashboard;
