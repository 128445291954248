import React from "react";

export default function ChatBotBottomMenu({ reinitializeConversation }) {
    return <div className="nova_menu-chatbot">
        <div className="nova_holder-button-new-conversation no_bottom_border">
            <div onClick={reinitializeConversation} className="nova_button-create-new-conversation w-inline-block">
                <img src="./images/nova/Icons-13.svg" loading="lazy" alt="" className="nova_icon-18" />
                <div>Créer une conversation</div>
            </div>
        </div>
    </div>;
}
