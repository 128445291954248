import React from "react";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { date2shortFrenchString } from "../../../lib/tools/datetime";
import { findNextMultiple, getArrayMaxValue } from "../../../lib/tools/numbers";
import ManagementContainer from "../../management/management_container";


export default function GraphUsersWork({ daysUsageData }) {

    if (!daysUsageData) {
        return null;
    }

    const maxYAxisWork = findNextMultiple(Math.round(getArrayMaxValue(daysUsageData.map(row => row.work_done)) * 1.2 + 1), 4);
    const maxYAxisRdv = findNextMultiple(Math.round(getArrayMaxValue(daysUsageData.map(row => row.rdv_taken)) * 1.2 + 1), 4);

    const getGraph = (fieldName, maxValue, title) => {
        return <ManagementContainer title={title}>
            <div style={{ width: '100%', height: 280 }}>
                <ResponsiveContainer>
                    <LineChart
                        data={daysUsageData.map(row => {
                            return { ...row, "Nombre de leads": row[fieldName], displayDate: date2shortFrenchString(new Date(row.day)) }
                        })}
                        margin={{ top: 0, right: 45, left: 0, bottom: 30 }}>
                        <XAxis dataKey="displayDate" angle={45} height={70} textAnchor="start" />
                        <YAxis domain={[0, maxValue]} />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Nombre de leads" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </ManagementContainer>;
    }

    return (
        <>
            {getGraph("work_done", maxYAxisWork, "Leads utilisés par jour")}
            {getGraph("rdv_booked", maxYAxisRdv, "Rendez-vous pris par jour")}
        </>
    );
}
