import React, { useState } from "react";

export default function NovaInput({
  label,
  type = "text",
  name,
  placeholder,
  value,
  onChange,
  options = [], // Utilisé pour le dropdown
  required = false,
  className = ""
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || "");

  const handleSelect = (option) => {
    setSelectedValue(option.value);
    onChange({ target: { name, value: option.value } });
    setIsOpen(false);
  };
  // TODO 
  return (
    <div className={`nova_input-wrapper ${className}`}>
      {label && <label className="">{label}</label>}
      {type === "select" ? (
        <div className="relative">
          <div
            className="nova_input-name w-input nova_input-field"
            onClick={() => setIsOpen(!isOpen)}
          >
            {options.find((option) => option.value === selectedValue)?.label || placeholder || "Sélectionnez une option"}
          </div>
          {isOpen && (
            <div className="nova_dropdown-item-container">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`nova_dropdown-item ${selectedValue === option.value ? "selected" : ""}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <input
          className="nova_input-name w-input"
          type={type}
          name={name}
          placeholder={placeholder}
          value={value || ""}
          onChange={onChange}
          required={required}
          maxLength={256}
        />
      )}
    </div>
  );
}