import React, { useState } from "react";
import { Link } from "react-router-dom";
import { subscribe } from "../../api/axios";
import Toast from "../ux/toast";
import BottomLeftLegal from "./bottom_left_legal";
import RightHalfScreenImage from "./right_half_screen_image";
import Step from "./step";
import TopLeftLogo from "./top_left_logo";


export default function SubscribePage({ setView }) {
    const [toast, setToast] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (firstName.length < 2) {
            setToast({ text: "Le prénom doit contenir au moins 2 caractères", severity: "error", duration: 5000 });
            return;
        }
        if (lastName.length < 2) {
            setToast({ text: "Le nom de famille doit contenir au moins 2 caractères", severity: "error", duration: 5000 });
            return;
        }
        if (password.length < 4) {
            setToast({ text: "Le mot de passe doit contenir au moins 4 caractères", severity: "error", duration: 5000 });
            return;
        }
        if (email.length < 4) {
            setToast({ text: "L'email doit contenir au moins 4 caractères", severity: "error", duration: 5000 });
            return;
        }

        subscribe(firstName, lastName, email, password)
            .then(response => {
                setView("success");
                setToast({ text: "Inscription réussie", severity: "success", duration: 5000 });
            }).catch(error => {
                console.log(error);
                setToast({ text: "Erreur lors de l'inscription", severity: "error", duration: 5000 });
            });
    }

    const getBackButton = () => {
        return <Link to={'/login'} style={{ textDecoration: 'none', color: 'black' }} className="la_link-page w-inline-block">
            <div className="nova_back_button_container">
                <div className="nova_button-back w-inline-block">
                    <img src="./images/nova/arrow.svg" loading="lazy" alt="" className="onboarding_back_button _180" />
                    <div className="text-block-42">Retour</div>
                </div>
            </div>
        </Link>;
    }

    const getSubscriptionForm = () => {
        return <div className="nova_holder-form-button">
            <div>
                <div className="nova_heading m40">Commençons par créer votre compte</div>
                <label className="field-label small">Prénom</label>
                <div className="nova_holder-input-login _2">
                    <input
                        className="text-field-2 small w-input"
                        maxLength={255}
                        placeholder="Prénom"
                        type="text"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                    />
                </div>
                <label className="field-label small">Nom de famille</label>
                <div className="nova_holder-input-login _2">
                    <input
                        className="text-field-2 small w-input"
                        maxLength={255}
                        placeholder="Nom de famille"
                        type="text"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                    />
                </div>
                <label className="field-label small">Email</label>
                <div className="nova_holder-input-login _2">
                    <input
                        className="text-field-2 small w-input"
                        maxLength={255}
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <label className="field-label small">Mot de passe</label>
                <div className="nova_holder-input-login _2">
                    <input
                        className="text-field-2 small w-input"
                        maxLength={255}
                        placeholder="Mot de passe"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
            </div>
            <input type="submit" className="nova_login-button small w-button" value="Inscription" onClick={handleSubmit} />
        </div>;
    }

    const getSubscribePart = () => {
        return <div className="nova_second-screen-onboarding">
            <div className="nova_uppart-second-screen">
                {getBackButton()}
                {getSubscriptionForm()}
            </div>
            <Step step={1} totalSteps={2} />
        </div>;
    }

    const getLeftPart = () => {
        return <div className="nova_leftside-onboarding">
            {getSubscribePart()}
            <TopLeftLogo />
            <BottomLeftLegal />
        </div>;
    }

    return <div className="nova_onboarding-section">
        {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
        {getLeftPart()}
        <RightHalfScreenImage imgSrc="login_presentation.svg" isImageLargeWithCenteredContent={false} />
    </div>;
}
