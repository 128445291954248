import { useContext, useEffect, useState } from "react";
import { getAllCompanyLeadsByStatus, getAllCountUser, getCompanyLeadsCount, getUserLeadsCount } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { LeadStatus } from "../../context/enums";


// const [totalCount, handleUpdateStatutCounts, getStatusLeadsCount] = useUpdatableLeadsCount({ getCompanyStats: auth.user_type === UserType.MANAGER });
export function useUpdatableLeadsCount({ getCompanyStats }) {
    const { auth } = useContext(AuthContext);

    const [totalCount, setTotalCount] = useState("");
    const [statutCount, setStatutCount] = useState([]);

    const setup = (auth) => {
        if (!auth) {
            return;
        }

        if (getCompanyStats) {
            getAllCompanyLeadsByStatus(auth.company_id).then((res) => { setStatutCount(res.data) });
            getCompanyLeadsCount(auth.company_id).then((res) => setTotalCount(res.data));
        } else {
            getAllCountUser(auth.id).then((res) => { setStatutCount(res.data) });
            getUserLeadsCount(auth.id).then((res) => setTotalCount(res.data));
        }
    }

    useEffect(() => {
        setup(auth);
    }, [auth]);

    // Update on UI leads count per statut when a lead's statut is updated
    const handleUpdateStatutCounts = (prevStatut, newStatut) => {
        const prevStatutCleaned = prevStatut === null ? LeadStatus["SANS_STATUT"] : prevStatut;
        const statutAlreadyExists = statutCount.filter((row) => row.statut === newStatut).length > 0;

        if (statutAlreadyExists) {
            setStatutCount(prevStatutCount => {
                return prevStatutCount.map((row) => {
                    if (row.statut === prevStatutCleaned) {
                        return { statut: row.statut, count_statut: Math.max(row.count_statut - 1, 0) };
                    } else if (row.statut === newStatut) {
                        return { statut: row.statut, count_statut: row.count_statut + 1 };
                    }
                    return row;
                });
            });
        } else {
            setStatutCount(prevStatutCount => {
                return prevStatutCount.map((row) => {
                    if (row.statut === prevStatutCleaned) {
                        return { statut: row.statut, count_statut: Math.max(row.count_statut - 1, 0) };
                    }
                    return row;
                }).concat({ statut: newStatut, count_statut: 1 });
            });
        }
    }

    const getStatusLeadsCount = (statut) => {
        return statutCount?.filter((s) => s.statut === statut)?.[0]?.count_statut;
    }

    return [
        totalCount,
        handleUpdateStatutCounts,
        getStatusLeadsCount
    ];
}
