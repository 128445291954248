import React from "react";

export default function SearchBar({ handleSearchChange, isLarge }) {
    const classSuffix = isLarge ? " large" : "";

    return <div className="nova_search-bar w-form">
        <div className="nova_relative-input-search">
            <input className={`nova_search-input${classSuffix} w-input`}
                maxLength={255}
                placeholder="Recherche"
                type="text"
                onChange={(e) => handleSearchChange(e)} />
            <div className="nova_search-button w-inline-block">
                <img src="./images/nova/Icons-14.svg" loading="lazy" alt="" />
            </div>
        </div>
    </div>;
}
