import { useContext, useState } from "react";
import { addB2BMedicalLeads } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { DataSources } from "../../context/enums";
import { useJobCategories } from "../leads/useJobCategories";
import { useSearchJobs } from "../search/useSearchJobs";

export function useAddLeads({ handleNoLeadCountProvided, handleAddLeadsStarted, handleSuccess, handleFailure }) {
    const { auth } = useContext(AuthContext);

    const jobCategories = useJobCategories({ auth });

    const [leadsCount, setLeadsCount] = useState("");
    const [selectedJobCategory, setSelectedJobCategory] = useState("__all__");
    const [selectedDataSource, setSelectedDataSource] = useState("__all__");
    const [selectedJob, setSelectedJob] = useState("");
    const jobsFound = useSearchJobs({ selectedJob });

    const jobCatOptions = [{ key: "__all__", value: "__all__", label: "Toutes les catégories" }]
        .concat(jobCategories.map((jobCategory) => {
            return { key: jobCategory.id, value: jobCategory.id, label: jobCategory.name };
        }));

    const dataSourceOptions = [{ key: "__all__", value: "__all__", label: "Toutes les sources" }]
        .concat(Object.values(DataSources).map((r, i) => {
            return { key: i, value: r.value, label: r.name };
        }));

    const handleAddB2BMedLeads = () => {
        if (!leadsCount) {
            handleNoLeadCountProvided("Veuillez indiquer un nombre de leads.");
            return;
        }

        handleAddLeadsStarted();
        addB2BMedicalLeads(leadsCount ? leadsCount : 0,
            selectedJobCategory === "__all__" ? null : selectedJobCategory,
            selectedJob === "" ? null : selectedJob,
            selectedDataSource === "__all__" ? null : selectedDataSource)
            .then((data) => {
                const countAdded = data.data.count_added;
                const creditsLeft = data.data.credits_left;
                const error = data.data.error;

                let message = '';
                if (error === true) {
                    message = "Erreur, veuillez réessayer.";
                } else if (countAdded < creditsLeft) {
                    message = `${countAdded} leads ont été ajoutés, il n'y en avait plus de disponibles selon vos critères.`;
                } else if (creditsLeft < leadsCount) {
                    message = `${countAdded} leads ont été ajoutés, il ne vous restait que ${creditsLeft} crédits disponible aujourd'hui.`;
                } else {
                    message = `Les ${countAdded} leads ont été ajoutés avec succès.`;
                }

                handleSuccess(message);
            }).catch(err => {
                handleFailure("Erreur, veuillez réessayer.");
                console.error(err);
            });
    };

    return {
        leadsCount,
        setLeadsCount,
        setSelectedJobCategory,
        jobCatOptions,
        setSelectedDataSource,
        dataSourceOptions,
        selectedJob,
        setSelectedJob,
        jobsFound,
        handleAddB2BMedLeads,
    };
}
