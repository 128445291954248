import React from "react";

export default function ViewButtons({ handleCloseButton }) {
    return <div className="nova_absolut-holder-buttons">
        {/* <div className="nova_button-shrink-grow-chatbot w-inline-block">
        <img src="./images/nova/Icons_7.svg" loading="lazy" alt="" />
    </div> */}
        <div onClick={handleCloseButton} className="nova_button-shrink-grow-chatbot w-inline-block">
            <img src="./images/nova/cross_darkgrey.svg" loading="lazy" alt="" />
        </div>
    </div>;
}
