import React, { useContext, useEffect, useState } from "react";
import { storeFeatureUsage } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { DataSources, hasListAgeFilter, hasListDataSourceFilter, hasListDepartementFilter, hasListHighTaxOnlyFilter, hasListJobCatFilter, hasListJobFilter, hasListRegionFilter, Region } from "../../../context/enums";
import { DataSourceDefaultValue, JobCatDefaultValue, JobDefaultValue, TaxDefaultValue } from "../../../context/filter_constants";
import { useJobCategories } from "../../../lib/leads/useJobCategories";
import { useSearchJobs } from "../../../lib/search/useSearchJobs";
import { useActionWhenMouseLeaves } from "../../../lib/ui/useActionWhenMouseLeaves";


/*
    IMPORTANT: side effect, lists filters are written in code

    IMPORTANT: side effect, adding a new filter requires to update the resetFilters functions and handlers in LeadsPage
        need also to update mouseLeavesCallback here to manage the popup adding those filters to the view

    IMPORTANT: a unique reset function that reset labels should be implemented and used both when a filter is removed
        or when all filters are reset
*/


export default function AdditionnalFiltersPopup({
    list,
    handleResetAllFilters,
    handleRegionFilterChange,
    handleTaxFilterChange,
    handleJobCategoryChange,
    minAge,
    maxAge,
    jobCategory,
    region,
    highTaxOnly,
    handleMinAgeFilterChange,
    handleMaxAgeFilterChange,
    departement,
    handleDepartementFilterChange,
    job,
    handleJobChange,
    dataSource,
    handleDataSourceChange,
}) {
    // todo(1) mettre filters dans ordre ajoutés et pas dans l'ordre voulu
    // todo(1) enlever les w-form
    const { auth } = useContext(AuthContext);

    const jobCategories = useJobCategories({ auth });

    const [displayAddFilterDropDown, setDisplayAddFilterDropDown] = useState(false);

    // region filter  -- ALSO SERVE AS EXAMPLES
    const [displayRegionFilter, setDisplayRegionFilter] = useState(false); // reset in UI
    const [goingToFilterRegion, setGoingToFilterRegion] = useState(false); // reset with hook when displayRegionFilter is false
    const [regionLabel, setRegionLabel] = useState(null); // to reset in a unique resetCallback used for reset
    const [showRegionDropD, setShowRegionDropD] = useState(false); // reset automatically when mouse leaves

    // tax filter
    const [displayTaxFilter, setDisplayTaxFilter] = useState(false);
    const [goingToFilterTax, setGoingToFilterTax] = useState(false);
    const [taxLabel, setTaxLabel] = useState(null);
    const [showTaxDropD, setShowTaxDropD] = useState(false);

    // job categories filter
    const [displayJobCatFilter, setDisplayJobCatFilter] = useState(false);
    const [goingToFilterJobcat, setGoingToFilterJobcat] = useState(false);
    const [jobCatLabel, setJobCatLabel] = useState(null);
    const [showJobCatDropD, setShowJobCatDropD] = useState(false);

    // age filter
    const [displayAgeFilter, setDisplayAgeFilter] = useState(false);
    const [goingToFilterAge, setGoingToFilterAge] = useState(false);

    // departement filter
    const [displayDprtFilter, setDisplayDprtFilter] = useState(false);
    const [goingToFilterDprt, setGoingToFilterDprt] = useState(false);
    const [showDprtDropD, setShowDprtDropD] = useState(false);

    // job filter
    const [displayJobFilter, setDisplayJobFilter] = useState(false);
    const [goingToFilterJob, setGoingToFilterJob] = useState(false);
    const [selectedJob, setSelectedJob] = useState("");
    const jobsFound = useSearchJobs({ selectedJob });
    const [showJobDropD, setShowJobDropD] = useState(false);

    // data source filter
    const [displayDataSourceFilter, setDisplayDataSourceFilter] = useState(false);
    const [goingToFilterDataSource, setGoingToFilterDataSource] = useState(false);
    const [dataSourceLabel, setDataSourceLabel] = useState(null);
    const [showDataSourceDropD, setShowDataSourceDropD] = useState(false);

    // initially set the 4 most priority filters available
    useEffect(() => {
        let filtersAdded = 0;
        // age
        if (hasListAgeFilter(list) && filtersAdded < 4) {
            setDisplayAgeFilter(true);
            setTimeout(() => setGoingToFilterAge(true), 50);
            filtersAdded++;
        }
        if (hasListRegionFilter(list) && filtersAdded < 4) {
            setDisplayRegionFilter(true);
            setTimeout(() => setGoingToFilterRegion(true), 50);
            filtersAdded++;
        }

        if (hasListDataSourceFilter(list) && filtersAdded < 4) {
            setDisplayDataSourceFilter(true);
            setTimeout(() => setGoingToFilterDataSource(true), 50);
            filtersAdded++;
        }

        if (hasListJobCatFilter(list) && filtersAdded < 4) {
            setDisplayJobCatFilter(true);
            setTimeout(() => setGoingToFilterJobcat(true), 50);
            filtersAdded++;
        }

        if (hasListJobFilter(list) && filtersAdded < 4) {
            setDisplayJobFilter(true);
            setTimeout(() => setGoingToFilterJob(true), 50);
            filtersAdded++;
        }

        if (hasListDepartementFilter(list) && filtersAdded < 4) {
            setDisplayDprtFilter(true);
            setTimeout(() => setGoingToFilterDprt(true), 50);
            filtersAdded++;
        }

        if (hasListHighTaxOnlyFilter(list) && filtersAdded < 4) {
            setDisplayTaxFilter(true);
            setTimeout(() => setGoingToFilterTax(true), 50);
            filtersAdded++;
        }
    }, [list]);

    // reset all going to display filters when they are set to false
    useEffect(() => {
        if (!displayAgeFilter) setGoingToFilterAge(false);
    }, [displayAgeFilter]);

    useEffect(() => {
        if (!displayRegionFilter) setGoingToFilterRegion(false);
    }, [displayRegionFilter]);

    useEffect(() => {
        if (!displayDprtFilter) setGoingToFilterDprt(false);
    }, [displayDprtFilter]);

    useEffect(() => {
        if (!displayJobCatFilter) setGoingToFilterJobcat(false);
    }, [displayJobCatFilter]);

    useEffect(() => {
        if (!displayTaxFilter) setGoingToFilterTax(false);
    }, [displayTaxFilter]);

    useEffect(() => {
        if (!displayJobFilter) setGoingToFilterJob(false);
    }, [displayJobFilter]);

    useEffect(() => {
        if (!displayDataSourceFilter) setGoingToFilterDataSource(false);
    }, [displayDataSourceFilter]);

    const [handleMouseLeaveJob, handleMouseEnterJob] =
        useActionWhenMouseLeaves({ wait: 0., mouseLeavesCallback: () => setShowJobDropD(false) });

    function mouseLeavesSelectFiltersCallback() {
        // set all filteres display as their going to display value
        if (!goingToFilterAge) removeAgeCallback();
        else setDisplayAgeFilter(true);

        if (!goingToFilterRegion) removeRegionCallback()
        else setDisplayRegionFilter(true);

        if (!goingToFilterDprt) removeDprtCallback()
        else setDisplayDprtFilter(true);

        if (!goingToFilterJobcat) removeJobCatCallback()
        else setDisplayJobCatFilter(true);

        if (!goingToFilterTax) removeTaxCallback()
        else setDisplayTaxFilter(true);

        if (!goingToFilterJob) removeJobCallback()
        else setDisplayJobFilter(true);

        if (!goingToFilterDataSource) removeDataSourceCallback()
        else setDisplayDataSourceFilter(true);

        setDisplayAddFilterDropDown(false);
    }

    const [handleMouseLeaveFiltersCheckboxes, handleMouseEnterFiltersCheckboxes] =
        useActionWhenMouseLeaves({ wait: 1.2, mouseLeavesCallback: mouseLeavesSelectFiltersCallback });

    const resetFilters = () => {
        removeRegionCallback(false);
        removeTaxCallback(false);
        removeJobCatCallback(false);
        removeAgeCallback(false);
        removeDprtCallback(false)
        removeJobCallback(false);
        removeDataSourceCallback(false);

        handleResetAllFilters();
    }

    const getFilterHeader = (key, title, closeCallback, imgSrc) => {
        return <div key={key} className="nova_holder-title-icon">
            <div className="nova_icon-title">
                <img src={`./images/nova/${imgSrc}`} loading="lazy" alt="" />
                <div className="text-block-25">{title}</div>
            </div>
            <div onClick={closeCallback} className="nova_updates-close w-inline-block">
                <img src="./images/nova/cross_grey.svg" loading="lazy" alt="" />
            </div>
        </div>;
    }

    const getDropDownFilterOptions = (selectedCallback, choices, showDropDown, areChoicesStrings) => {
        /*
            choices : [{value:, label:}]
        */
        const classSuffix = showDropDown ? " visible" : "";
        return <div className={`nova_hovered_filter_container${classSuffix}`}>
            <div className="nova_holder-last-update-dropbox">
                <div className="nova_holder-links-dropbox">
                    {choices.map(nextCh => {
                        const key = areChoicesStrings ? nextCh : nextCh.value;
                        const label = areChoicesStrings ? nextCh : nextCh.label;
                        return <div key={key}
                            onClick={() => selectedCallback(nextCh)}
                            className="nova_dropbox-links-lastupdate w-inline-block">
                            <div className="nova_text-linkbox">{label}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>;
    }

    const getDropDownFilter = (
        key,
        title,
        selectedLabel,
        selectedCallback,
        closeCallback,
        choices,
        imgSrc,
        showDrodown,
        setShowDropdown,
    ) => {
        const classSuffix = dropDownCount === 0 ? "" : ` z${dropDownCount}`;
        dropDownCount--;
        return <div key={key} className="nova_holder-last-updates">
            {getFilterHeader(`${key}__top`, title, closeCallback, imgSrc)}
            <div key={key} className={`nova_holder-dropdown-filter${classSuffix} w-form`}>
                <div onClick={() => setShowDropdown(prev => !prev)} className="nova_dropdown-filter w-dropdown">
                    <div className="nova_dropdown-filters w-dropdown-toggle">
                        <div className="icon-3 w-icon-dropdown-toggle"></div>
                        <div className="text-block-24">{selectedLabel}</div>
                    </div>
                </div>
                {getDropDownFilterOptions(selectedCallback, choices, showDrodown, false)}
            </div>
        </div>;
    }

    function removeRegionCallback(reload = true) {
        setDisplayRegionFilter(false);
        setRegionLabel(null);
        if (reload && region !== null) handleRegionFilterChange(null);
    }

    const getRegionFilter = () => {
        if (!displayRegionFilter) return null;

        const regionDefaultLabel = "Toute la France";

        let choices = [{
            label: regionDefaultLabel, value: null,
        }];
        const regionsChoices = Object.values(Region).map((r, i) => {
            return { label: r.display, value: r.CodePostal };
        });
        choices = choices.concat(regionsChoices);

        function selectedCallback(nextCh) {
            handleRegionFilterChange(nextCh.value);
            setRegionLabel(nextCh.label);
            setShowRegionDropD(false);
        }
        return getDropDownFilter("region_filter",
            "Région",
            regionLabel || regionDefaultLabel,
            selectedCallback,
            removeRegionCallback,
            choices,
            "icon_filter_location.svg",
            showRegionDropD,
            setShowRegionDropD
        );
    }

    function removeTaxCallback(reload = true) {
        setDisplayTaxFilter(false);
        setTaxLabel(null);
        if (reload && highTaxOnly !== TaxDefaultValue) handleTaxFilterChange(TaxDefaultValue);
    }
    const getTaxFilter = () => {
        if (!displayTaxFilter) return null;

        const defaultLabel = "Tous les impots";
        let choices = [
            { label: defaultLabel, value: TaxDefaultValue },
            { label: "+5k€ annuel", value: true },
        ];

        function selectedCallback(nextCh) {
            setShowTaxDropD(false);
            setTaxLabel(nextCh.label);
            handleTaxFilterChange(nextCh.value);
        }
        const label = (taxLabel === TaxDefaultValue || !taxLabel) ? defaultLabel : taxLabel;
        return getDropDownFilter("high_tax_only_filter",
            "Imposition",
            label,
            selectedCallback,
            removeTaxCallback,
            choices,
            "icon_filter_tax.svg",
            showTaxDropD,
            setShowTaxDropD);
    }

    function removeJobCatCallback(reload = true) {
        setDisplayJobCatFilter(false);
        setJobCatLabel(null);
        if (reload && jobCategory !== JobCatDefaultValue) handleJobCategoryChange(JobCatDefaultValue);
    }

    const getJobCatFilter = () => {
        if (!displayJobCatFilter) return null;

        const defaultLabel = "Groupes de métiers"; // todo(2) remonter en haut tous ces trusc
        let choices = [{ label: defaultLabel, value: JobCatDefaultValue }];
        const moreChoices = jobCategories.map((cat) => {
            return { label: cat.name, value: cat.id };
        });
        choices = choices.concat(moreChoices);


        function selectedCallback(nextCh) {
            setShowJobCatDropD(false);
            handleJobCategoryChange(nextCh.value);
            setJobCatLabel(nextCh.label);
        }
        const label = (jobCatLabel === JobCatDefaultValue || !jobCatLabel) ? defaultLabel : jobCatLabel;
        return getDropDownFilter("jobcat_filter",
            "Catégories de métiers",
            label,
            selectedCallback,
            removeJobCatCallback,
            choices,
            "icon_filter_job.svg",
            showJobCatDropD,
            setShowJobCatDropD);
    }

    function removeDataSourceCallback(reload = true) {
        setDisplayDataSourceFilter(false);
        setDataSourceLabel(null);
        if (reload && dataSource !== DataSourceDefaultValue) handleDataSourceChange(DataSourceDefaultValue);
    }

    const getDataSourceFilter = () => {
        if (!displayDataSourceFilter) return null;

        const defaultLabel = "Toutes les sources";
        const choices = Object.values(DataSources).map((src) => {
            return { label: src.name, value: src.value };
        });

        function selectedCallback(nextCh) {
            setShowDataSourceDropD(false);
            handleDataSourceChange(nextCh.value);
            setDataSourceLabel(nextCh.label);
        }
        const label = (dataSourceLabel === DataSourceDefaultValue || !dataSourceLabel) ? defaultLabel : dataSourceLabel;
        return getDropDownFilter("data_source",
            "Source de données",
            label,
            selectedCallback,
            removeDataSourceCallback,
            choices,
            "icon_filter_tax.svg", // todo(3) update
            showDataSourceDropD,
            setShowDataSourceDropD);
    }

    const getMinMaxFilter = (
        key,
        title,
        minValue,
        maxValue,
        minPlaceholder,
        maxPlaceholder,
        closeCallback,
        setMinCallback,
        setMaxCallback,
        imgSrc,
    ) => {
        return <div key={key} className="nova_holder-last-updates">
            {getFilterHeader(`${key}_top`, title, closeCallback, imgSrc)}
            <div key={key} className="nova_filters_horizontal_spliter">
                <input className="nova_filter_input w-input"
                    onChange={setMinCallback}
                    maxLength={255}
                    value={minValue}
                    placeholder={minPlaceholder}
                    type="text" />
                <input className="nova_filter_input w-input"
                    onChange={setMaxCallback}
                    maxLength={255}
                    value={maxValue}
                    placeholder={maxPlaceholder}
                    type="text" />
            </div>
        </div>;
    }

    function removeAgeCallback(reload = true) {
        setDisplayAgeFilter(false);
        if (reload && minAge !== "") handleMinAgeFilterChange("");
        if (reload && maxAge !== "") handleMaxAgeFilterChange("");
    }

    const getAgeFilter = () => {
        if (!displayAgeFilter) return null;
        return getMinMaxFilter(
            "filter_age",
            "Age",
            minAge,
            maxAge,
            "Age Min.",
            "Age Max.",
            removeAgeCallback,
            (e) => handleMinAgeFilterChange(e.target.value),
            (e) => handleMaxAgeFilterChange(e.target.value),
            "icon_filter_age.svg",
        );
    }

    const getInputFilter = (key, title, value, closeCallback, setCallback, imgSrc) => {
        return <div key={key} className="nova_holder-last-updates">
            {getFilterHeader(`${key}_top`, title, closeCallback, imgSrc)}
            <div key={key}>
                <input className="nova_filter_input w-input"
                    onChange={setCallback}
                    maxLength={255}
                    value={value}
                    type="text" />
            </div>
        </div>;
    }

    function removeDprtCallback(reload = true) {
        setDisplayDprtFilter(false);
        if (reload && departement !== "") handleDepartementFilterChange("");
    }
    const getDepartementFilter = () => {
        if (!displayDprtFilter) return null;

        return getInputFilter(
            "departement_filter",
            "Département",
            departement,
            removeDprtCallback,
            (e) => handleDepartementFilterChange(e.target.value),
            "icon_filter_departement.svg",
        );
    }

    const getSearchFilter = (
        key,
        title,
        selectedValue,
        selectedCallback,
        closeCallback,
        choices,
        imgSrc,
        showDrodown,
        setShowDropdown,
        handleMouseEnter,
        handleMouseLeave,
    ) => {
        const classSuffix = dropDownCount === 0 ? "" : ` z${dropDownCount}`;
        dropDownCount--;

        return <div key={key} className="nova_holder-last-updates">
            {getFilterHeader(`${key}__top`, title, closeCallback, imgSrc)}
            <div key={key}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={() => setShowDropdown(true)}
                onClick={() => setShowDropdown(true)}
                className={`nova_holder-dropdown-filter${classSuffix} w-form`}>
                <input className="nova_filter_input w-input"
                    onFocus={() => setShowDropdown(true)}
                    onChange={e => {
                        selectedCallback(e.target.value);
                        setShowDropdown(true);
                    }}
                    maxLength={255}
                    value={selectedValue}
                    type="text" />
                {getDropDownFilterOptions(selectedCallback, choices, showDrodown && choices.length, true)}
            </div>
        </div>;
    }

    function removeJobCallback(reload = true) {
        setDisplayJobFilter(false);
        setSelectedJob("");
        if (reload && job !== JobDefaultValue) handleJobChange(JobDefaultValue);
    }

    const getJobFilter = () => {
        if (!displayJobFilter) return null;
        function selectedCallback(nextCh) {
            setShowJobDropD(false);
            handleJobChange(nextCh);
            setSelectedJob(nextCh);
            setShowJobDropD(false);
        }
        return getSearchFilter("job_filter",
            "Métier",
            selectedJob,
            selectedCallback,
            removeJobCallback,
            jobsFound,
            "icon_filter_job.svg",
            showJobDropD,
            setShowJobDropD,
            handleMouseEnterJob,
            handleMouseLeaveJob,
        );
    }

    // todo(2) Checkbox text sert a quoi, vient d ou ?
    const getAdditionnalFilterCheckbox = (key, text, imageSrc, value, setValue) => {
        return <div key={key} className="nova_center-checkbox">
            <div className="nova_holder-icon-text">
                <img src={`./images/nova/${imageSrc}`} loading="lazy" alt="" />
                <div className="text-block-33">{text}</div>
            </div>
            <div className="nova_form-checkbox w-form">
                <label className="w-checkbox">
                    <input
                        type="checkbox"
                        className="w-checkbox-input"
                        checked={value}
                        onChange={(e) => setValue(e.target.checked)}
                    />
                    <span className="checkbox-label-2 w-form-label">Checkbox</span>
                </label>
            </div>
        </div>;
    }

    const getCheckboxAddFilterAge = () => {
        return getAdditionnalFilterCheckbox("age", "Age", "icon_filter_age.svg", goingToFilterAge, setGoingToFilterAge);
    }

    const getCheckboxAddFilterRegion = () => {
        return getAdditionnalFilterCheckbox("region", "Région", "icon_filter_location.svg", goingToFilterRegion, setGoingToFilterRegion);
    }

    const getCheckboxAddFilterDepartement = () => {
        return getAdditionnalFilterCheckbox("departement", "Département", "icon_filter_departement.svg", goingToFilterDprt, setGoingToFilterDprt);
    }

    const getCheckboxAddFilterJobcat = () => {
        return getAdditionnalFilterCheckbox("jobcat", "Catégorie de métiers", "icon_filter_job.svg", goingToFilterJobcat, setGoingToFilterJobcat);
    }

    const getCheckboxAddFilterHighTaxOnly = () => {
        return getAdditionnalFilterCheckbox("high_tax_only", "Imposition", "icon_filter_tax.svg", goingToFilterTax, setGoingToFilterTax);
    }

    const getCheckboxAddFilterJob = () => {
        return getAdditionnalFilterCheckbox("job", "Métiers", "icon_filter_job.svg", goingToFilterJob, setGoingToFilterJob);
    }

    const getCheckboxAddDataSource = () => {
        // todo(3) update image
        return getAdditionnalFilterCheckbox(
            "data_source",
            "Sources de données",
            "icon_filter_tax.svg",
            goingToFilterDataSource,
            setGoingToFilterDataSource
        );
    }

    const getAddFiltersDropDown = () => {
        let canUseAgeFilters = hasListAgeFilter(list); // todo(1) adapt to b2b now just works for company age
        let canUseDepartementFilter = hasListDepartementFilter(list);
        let canUseRegionFilter = hasListRegionFilter(list);
        let canUseJobCatgoryFilter = hasListJobCatFilter(list); // todo(2) change icon associated
        let canUseJobFilter = hasListJobFilter(list);
        let canUseHighTaxOnlyFilter = hasListHighTaxOnlyFilter(list); // todo(2) change icon associated
        let canUseDataSourceFilter = hasListDataSourceFilter(list);

        return <div className="nova_hovered_filter_list visible">
            <div className="nova_checkbox-selection">
                {canUseAgeFilters && getCheckboxAddFilterAge()}
                {canUseRegionFilter && getCheckboxAddFilterRegion()}
                {canUseDepartementFilter && getCheckboxAddFilterDepartement()}
                {canUseJobCatgoryFilter && getCheckboxAddFilterJobcat()}
                {canUseHighTaxOnlyFilter && getCheckboxAddFilterHighTaxOnly()}
                {canUseJobFilter && getCheckboxAddFilterJob()}
                {canUseDataSourceFilter && getCheckboxAddDataSource()}
            </div>
        </div>;
    }

    const getAddFiltersButton = () => {
        function handleClick() {
            storeFeatureUsage(auth.id, "lead_view_show_filters", null);
            setDisplayAddFilterDropDown(prev => !prev);
        }

        return <div className="nova_add_filter_button_container"
            onMouseLeave={handleMouseLeaveFiltersCheckboxes}
            onMouseEnter={handleMouseEnterFiltersCheckboxes}>
            <div className="nova_button-add-filter w-inline-block"
                onClick={handleClick}>
                <img src="./images/nova/Icons-copie-2.svg" loading="lazy" alt="" />
                <div className="text-block-26">Ajouter un filtre</div>
            </div>
            {displayAddFilterDropDown && getAddFiltersDropDown()}
        </div>;
    }

    let dropDownCount = 4;

    return <div className="nova_hovered_filter_container visible">
        <div className="nova_holder-filter-selection">
            <div className="nova_up-filters">
                <div className="text-block-21">Filtres</div>
                <div onClick={resetFilters} className="nova_clear-filters w-inline-block">
                    <div className="text-block-22">Réinitialiser</div>
                </div>
            </div>
            <div className="nova_middle-filters">
                {/* <div className="nova_holder-title-icon">
                            <div className="nova_icon-title">
                                <img src="../../images/Icons_2.svg" loading="lazy" alt="" />
                                <div className="text-block-25">Last updated</div>
                            </div>
                            <div className="nova_updates-close w-inline-block">
                                <img src="./images/nova/cross_grey.svg" loading="lazy" alt="" />
                            </div>
                        </div> */}
                {getAgeFilter()}
                {getRegionFilter()}
                {getDataSourceFilter()}
                {getJobCatFilter()}
                {getJobFilter()}
                {getTaxFilter()}
                {getDepartementFilter()}

                {getAddFiltersButton()}
            </div>
        </div>
    </div>
}
