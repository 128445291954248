import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { updateUserViewPreferences } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";
import { getListInitialColumnsOrder, LeadsFieldsName } from "../../context/enums";
import LeadRow from "../leads_row";
import PagesNavigations from "./navigation/pages_navigations";

// todo(3) gerer taille maximale par case
// todo(3) mettre label dans une config afin de trouver les columns plus facilement plus bas dans code
export default function LeadsTable({
    isDisplayedOnLeadsPage,
    isAlreadyContained,
    displayPageNavigation,
    viewedLeads,
    leads,
    list,
    currentPage,
    itemParPage,
    maxPageFetched,
    handlePagesModeClickNextPage,
    handleLeadUpdated,
    handleUpdateStatutCounts,
    setConfirmation,
    setToast,
    setPopupCreateReminderLead,
    reloadListsAccessibles,
}) {
    const { auth } = useContext(AuthContext);

    // those columns titles are update immediately when we drag and drop a column header
    const [columnsTitles, setColumnsTitles] = useState(getListInitialColumnsOrder(list));
    const tempColumnsTitles = useRef([...columnsTitles]); // temporary column state before last Enter event
    // columns data actually displayed
    const [columns, setColumns] = useState(getListInitialColumnsOrder(list));

    // store the index of the column we are dragging, this index is updated as columns header moves
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    // store the index of the column we are dragging at the beginning of the drag, ignoring the header updates
    const originalDragItem = useRef(null);

    const handleDragStart = (e, index) => {
        // we start dragging a column of index dragOverItem.current
        dragItem.current = index;
        originalDragItem.current = index;
        e.dataTransfer.effectAllowed = 'move'; // remove the ugly + icon while dragging
    };

    const handleDragEnter = (e, index) => {
        // we enter a new column of index dragOverItem.current
        dragOverItem.current = index;

        // reorder the columns headers live
        if (dragItem.current !== index) {
            const columnCopy = [...tempColumnsTitles.current];
            const dragItemContent = columnCopy[dragItem.current];
            columnCopy.splice(dragItem.current, 1);
            columnCopy.splice(index, 0, dragItemContent);

            tempColumnsTitles.current = columnCopy; // Update temporary state
            setColumnsTitles(tempColumnsTitles.current); // Update the visible columns
            dragItem.current = index;
        }
    };

    const handleDragEnd = (e) => {
        // we end the dragging a column of index lastDragItem.current onto dragOverItem.current
        if (originalDragItem.current === null || dragOverItem.current === null) return;

        // reorder the columns  -  we use lastDragItem instead of dragItem because dragItem is updated everytime 
        //      a column is entered because
        if (originalDragItem.current !== dragOverItem.current) {
            const newColumns = [...columns];
            const dragStartContent = newColumns[originalDragItem.current];
            newColumns.splice(originalDragItem.current, 1);
            newColumns.splice(dragOverItem.current, 0, dragStartContent);
            setColumns(newColumns);

            // save UI preferences in DB
            updateUserViewPreferences(auth.id, list.id, "columns", JSON.stringify(newColumns))
                .then(reloadListsAccessibles(auth));
        }

        // reset all references to null
        dragItem.current = null;
        originalDragItem.current = null;
        dragOverItem.current = null;
    };

    const getPagesNavigation = () => {
        if (!displayPageNavigation) return null;

        return <PagesNavigations mainClass="nova_down-part-leads-info"
            leads={leads}
            currentPage={currentPage}
            itemParPage={itemParPage}
            maxPageFetched={maxPageFetched}
            handlePagesModeClickNextPage={handlePagesModeClickNextPage}
        />;
    }

    const getButtonSeeMoreLeads = () => {
        return <Link to="/leads" style={{ textDecoration: 'none', color: 'white' }}>
            <div className="nova_table_more_leads">
                <div className="nova_button-add-user w-button">Voir plus de leads</div>
            </div>
        </Link>;
    }

    const mainClass = isAlreadyContained ? "nova_holder-crm-infoleads" : "nova_holder-crm-infoleads_already_contained";
    return (
        <div className={mainClass}>
            <div className="nova_table_overflower">
                <table className="nova_table">
                    <thead className="nova_thead">
                        <tr className="nova_up-bar-info2">
                            {leads.length ? <th key="action_add_reminder" className="nova_th_empty"></th> : null}
                            {leads.length ? <th key="action_copy_paste" className="nova_th_empty"></th> : null}
                            {leads.length ? <th key="action_transfer2salesman" className="nova_th_empty"></th> : null}
                            {columnsTitles.map((title, index) => (
                                <th
                                    key={title}
                                    className="nova_th"
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, index)}
                                    onDragEnter={(e) => handleDragEnter(e, index)}
                                    onDragEnd={handleDragEnd}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{ cursor: 'grabbing' }}
                                >
                                    <div className="nova_text-up-leads">
                                        {LeadsFieldsName?.[title] || ""}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="nova_holder-vertical-leads2">
                        {viewedLeads.map((lead) => (
                            <LeadRow
                                key={lead.id}
                                lead={lead}
                                // list={list} // todo(3) add  list
                                setConfirmation={setConfirmation}
                                setPopupCreateReminderLead={setPopupCreateReminderLead}
                                // reminders={leadReminders} // todo(2) plus tard remettre, sert surtout a afficher reminder existnt avant d n rajouter pour eviter d'en remettre un en double
                                setToast={setToast}
                                handleLeadUpdated={handleLeadUpdated}
                                handleUpdateStatutCounts={handleUpdateStatutCounts}
                                columns={columns}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            {!isDisplayedOnLeadsPage && getButtonSeeMoreLeads()}
            {getPagesNavigation()}
        </div>
    );
}
