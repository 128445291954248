import React from "react";
import { LeadStatus } from "../../context/enums";

export default function DashboardFirstStats({ totalCount, getStatusLeadsCount }) {
    const getStaticStatsCard = (key, imgSrc, label, value) => {
        return <div key={key} className="nova_total-leads">
            <div className="nova_holder-icon-fonctions">
                <div className="nova_icon-text">
                    <img src={`./images/nova/${imgSrc}.svg`} loading="lazy" alt="" />
                    <div className="nova_stats_title">{label}</div>
                </div>
                <div className="nova_holder-fontions-total-leads w-inline-block">
                    {/* <img src="./images/nova/Icons-9.svg" loading="lazy" alt="" /> */}
                </div>
            </div>
            <div className="nova_holder-number-stats">
                <div className="nova_number-total-leads">{value}</div>
                {/* update percentage <div className="nova_stats-total-leads">
                    <img src="./images/nova/Icons-copie.svg" loading="lazy" alt="" />
                    <div className="nova-text-stats">20%</div>
                </div> */}
            </div>
        </div>;
    }

    return <div className="nova_onedim_stats">
        {getStaticStatsCard("total_leads", "stats_leads", "Nombre de leads", totalCount)}
        {getStaticStatsCard("to_call", "stats_to_call", "A rappeler", getStatusLeadsCount(LeadStatus.RAPPELER) || 0)}
        {getStaticStatsCard("meetings_planned", "stats_meetings_planned", "RDV pris", getStatusLeadsCount(LeadStatus.RDV_PRIS) || 0)}
        {getStaticStatsCard("signings", "stats_signings", "Signés", getStatusLeadsCount(LeadStatus.SIGNE) || 0)}
    </div>;
}
