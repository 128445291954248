import React from 'react';

export default function AIMessage({ children, containsForm }) {
    const classSuffix = containsForm ? "formulaire" : "";
    return <div className="nova_chat-ai">
        <div className="nova_holder-img-ai">
            <img src="./images/nova/ai_logo.svg" loading="lazy" alt="" />
        </div>
        <div className={`nova_parent-holder-msgs ${classSuffix}`}>
            {children}
        </div>
    </div>;
}
