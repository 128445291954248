import React from "react";


export default function MessageFormSearch({ label, placeholder, value, setValue, potentialValuesFound }) {
    return <div className="nova_holder-form-conversation-chatbot">
        <label className="field-label-2">{label}</label>
        <div className="nova_relative-box-dropbox">
            <input
                className="text-field-3 w-input"
                maxLength={255}
                placeholder={placeholder}
                type="text" value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            {potentialValuesFound.length > 0 ? <div className="nova_dropbox-formulaire">
                {potentialValuesFound.map((choice) => <div key={choice}
                    onClick={() => setValue(choice)}
                    className="nova_drop-navlink w-inline-block">
                    <div className="nova_text">{choice}</div>
                </div>)}
            </div> : null}
        </div>
    </div>;
}
