import React, { useEffect, useState } from "react";
import { attributesSalesman, getActiveRecentlyCompanyUsers } from "../../../api/axios";
import Dropdown from "../forms/dropdown";


const Transfer2SalesmanNotification = ({
    text,
    setConfirmation,
    companyId,
    leadId,
    confirmCallback,
    setToast,
    color,
    emailNotification
}) => {
    const [collaborators, setCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState("__undefined__");

    useEffect(() => {
        getActiveRecentlyCompanyUsers().then(res => setCollaborators(res.data));
    }, [companyId]);

    const handleFinished = () => {
        setConfirmation(null);
    };

    function getCollabdisplayString(collab) {
        let prefix = collab.office ? `[${collab.office.toUpperCase()}] ` : "";
        return `${prefix}${collab.lastname.toUpperCase()} ${collab.firstname}`;
    }

    const handleAttributeClick = () => {
        if (selectedCollaborator === "__undefined__") {
            return;
        }

        attributesSalesman(leadId, selectedCollaborator, emailNotification).then(() => {
            handleFinished();
            confirmCallback(selectedCollaborator);
            setToast({ text: "Lead transféré", severity: "success" });
        }).catch(err => {
            console.error(err);
            setToast({ text: "Echec, veuillez réessayer.", severity: "error" });
        });
    }

    let optionsSalesmen = collaborators.map((collab) => {
        return {
            key: collab.id,
            value: collab.id,
            label: getCollabdisplayString(collab),
        };
    });

    return <div className="fixed_shadow">
        <div className="popup_positionner">
            <div className="nova_transfert-lead-popup">
                <div className="nova_uppart-popup">
                    <div className="popup_title">Transférer ce lead à un vendeur</div>
                    <Dropdown
                        initialText={"Veuillez choisir"}
                        mainImgSrc="transfer.svg"
                        options={optionsSalesmen}
                        handleChoice={setSelectedCollaborator} />
                </div>

                <div onClick={handleAttributeClick} className="button-2 w-button">Attribuer</div>
                <div onClick={handleFinished} className="nova_popup-close-button w-inline-block">
                    <img src="./images/nova/cross_darkgrey.svg" loading="lazy" alt="" />
                </div>
            </div>
        </div>
    </div>;
};

export default Transfer2SalesmanNotification;
