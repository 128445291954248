import React, { useContext, useState } from "react";
import { storeFeatureUsage } from "./api/axios";
import ManagementContainer from "./component/management/management_container";
import MenuBottom from "./component/menu_bottom";
import MenuTop from "./component/menu_top";
import PageTitle from "./component/page_title";
import PopupAddReminder from "./component/popups/popup_add_reminder";
import PopupChatbot from "./component/popups/popup_chatbot";
import NotificationCenter from "./component/reminders/notification_center";
import NovaButton from "./component/ux/common/nova_button";
import NovaInput from "./component/ux/common/nova_input";
import NovaSearchInput from "./component/ux/common/nova_search_input";
import ConfirmNotification from "./component/ux/confirmation";
import Toast from "./component/ux/toast";
import AuthContext from "./context/AuthProvider";
import { UserType } from "./context/enums";
import { useAddLeads } from "./lib/add_leads/useAddLeads";
import { useReminders } from "./lib/reminders/useReminders";
import { setNumericInput } from "./lib/tools/strings";
import { useVersionChecking } from "./lib/versions/useVersionChecking";


export default function NewLeadsPage() {
    const { auth, openMenu, color } = useContext(AuthContext);

    const [popupCreateReminderLead, setPopupCreateReminderLead] = useState(null);
    const [displayNotificationCenter, setDisplayNotificationCenter] = useState(false);
    const [toast, setToast] = useState(null);
    const [confirmation, setConfirmation] = useState(null);
    const [displayChatbot, setDisplayChatbot] = useState(false);

    useVersionChecking({ setConfirmation, type: UserType.USER });

    const [reminders, reloadReminders, markReminderLocallyAsViewed] = useReminders({ auth });

    const [showDropdownJobs, setShowDropDownJobs] = useState(false);

    const handleNoLeadCountProvided = (message) => {
        setToast({ text: message, severity: "error", duration: 5000 });
    }

    const handleAddLeadsStarted = () => {
        storeFeatureUsage(auth.id, "chatbot_add_b2bmed", null); // todo(2)(quick) merged et enlever chatbot_ de partout
    }

    const handleSuccess = (newMessage) => {
        setToast({ text: newMessage, severity: "success", duration: 5000 });
    }

    const handleFailure = (errorMessage) => {
        setToast({ text: errorMessage, severity: "error", duration: 5000 });
    }

    const {
        leadsCount,
        setLeadsCount,
        setSelectedJobCategory,
        jobCatOptions,
        setSelectedDataSource,
        dataSourceOptions,
        selectedJob,
        setSelectedJob,
        jobsFound,
        handleAddB2BMedLeads,
    } = useAddLeads({
        handleNoLeadCountProvided,
        handleAddLeadsStarted,
        handleSuccess,
        handleFailure,
    });
    // todo(2) move metiers a la fin, et resoudre avec des classsuffix probleme quand autres drop down se mettent par dessus
    //   j pense meme pas besoin, en fait il y a juste un z-index mal mis
    const getCentralPart = () => {
        return <div className={`nova_crm leads_sticky_view${mainClassexSuffix}`}>
            <ManagementContainer
                title='Ajouter des leads'
                description='Sélectionnez les leads B2B Médical que vous voulez vous attribuez.'>
                <NovaInput
                    label={"Nombre de leads"}
                    placeholder={"Entrer le nombre"}
                    value={leadsCount}
                    onChange={(ev) => setNumericInput(ev, setLeadsCount)}
                    required
                />
                <NovaSearchInput
                    label="Métier"
                    showDrodown={showDropdownJobs}
                    setShowDropdown={setShowDropDownJobs}
                    classSuffix=""
                    selectedValue={selectedJob}
                    selectedCallback={setSelectedJob}
                    choices={jobsFound}
                />
                <NovaInput
                    label={"Catégories de métiers"}
                    type='select'
                    onChange={(ev) => setSelectedJobCategory(ev.target.value)}
                    options={jobCatOptions}
                />
                <NovaInput
                    label={"Sources de données"}
                    type='select'
                    onChange={(ev) => setSelectedDataSource(ev.target.value)}
                    options={dataSourceOptions}
                />
                <NovaButton onClick={handleAddB2BMedLeads}>
                    Obtenir les leads
                </NovaButton>
            </ManagementContainer>
        </div>;
    }

    if (!auth || auth.has_access_to_b2bmed !== true) {
        return null;
    }

    const mainClassexSuffix = openMenu ? "" : " menu_closed";
    return <>
        {displayChatbot ? <PopupChatbot handleCloseButton={() => setDisplayChatbot(false)} /> : null}
        <section className="nova_holder-software-grid">
            <NotificationCenter
                display={displayNotificationCenter}
                setDisplay={setDisplayNotificationCenter}
                reminders={reminders}
                reloadReminders={reloadReminders}
                markReminderLocallyAsViewed={markReminderLocallyAsViewed}
                setToast={setToast}
            />
            {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
            {confirmation !== null && <ConfirmNotification title={confirmation.title}
                text={confirmation.text}
                confirmCallback={confirmation.confirmCallback}
                setConfirmation={setConfirmation}
                color={color}
                showYesButton={confirmation.showYesButton}
                showNoButton={confirmation.showNoButton}
                specialPopup={confirmation.specialPopup}
                setToast={setToast} />}
            {popupCreateReminderLead !== null ? <PopupAddReminder key={popupCreateReminderLead.id}
                lead={popupCreateReminderLead}
                setToast={setToast}
                successCallback={() => { reloadReminders(auth) }} //  todo(1) mettre de cote avec tout ca popupCreateReminderLeadId*/}
                closingCallback={() => setPopupCreateReminderLead(null)}
            /> : null}
            <MenuTop currentView={"new_leads_view"} />
            <PageTitle
                title="Leads"
                setDisplayNotificationCenter={setDisplayNotificationCenter}
                setDisplayChatbot={setDisplayChatbot}
                reminders={reminders}
            />
            <MenuBottom currentView="new_leads_view" />
            {getCentralPart()}
        </section>
    </>;
}
