import React, { useContext, useEffect, useRef, useState } from "react";
import { handleNRP } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import { LeadStatus, StatusColor } from "../../../context/enums";
import { cleanDBStatut } from "../../../lib/tools/app_context";
import StatusDropdown from "../status_dropdown";

export default function StatusValue({
    leadId,
    displayOpeningArrow,
    newStatus,
    updateStatut,
    setConfirmation,
    setToast,
    positionAtRight
}) {
    // displayOpeningArrow: boolean  - not implemented
    const { auth } = useContext(AuthContext);

    const [displayStatusChange, setDisplayStatusChange] = useState(false);

    const dropdownRef = useRef(null);

    // todo(3) set aside this logic in use**** and use it everywhere it should be
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDisplayStatusChange(false);
            }
        };

        // Add when the dropdown is open
        if (displayStatusChange) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Clean up
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [displayStatusChange]);

    const handleIncreaseNRP = () => {
        setConfirmation({
            "title": "Augmenter le nombre de NRP",
            "text": "Venez-vous d'appeler cet utilisateur sans qu'il ne réponde ?",
            "showYesButton": true,
            "showNoButton": true,
            "specialPopup": null,
            "confirmCallback": (userId) => {
                handleNRP(leadId, auth.id)
                    .catch(err => {
                        setToast({ text: "Echec, mise à jour du nom", severity: "error" });
                        console.error(err);
                    });
            }
        });
    }

    const classSuffix = StatusColor[cleanDBStatut(newStatus)];
    return <div ref={dropdownRef} className="nova_holder-status_dropdown">
        <div className="nova_status_blocs">
            <div onClick={() => setDisplayStatusChange(!displayStatusChange)} className={`nova_status-type noborder ${classSuffix}`}>
                <div className={`nova_dot-status ${classSuffix}`}></div>
                <div className={`nova_status-text ${classSuffix}`}>{cleanDBStatut(newStatus)}</div>
            </div>
            {newStatus === LeadStatus.NRP ? <div onClick={handleIncreaseNRP} className="nova_status-type noborder no_button nrp">
                <div className="nova_status-text nrp">+</div>
            </div> : null}
        </div>
        {displayStatusChange
            && <StatusDropdown
                automaticalyVisible={true}
                handleStatusClicked={(newStatus) => {
                    updateStatut(newStatus);
                    setDisplayStatusChange(false);
                }}
                isStatusUpdate={true}
                useAllStatus={false}
                positionAtRight={positionAtRight}
            />}
    </div>
}
