import React, { useState } from "react";
import SubscribePage from "./component/onboarding/subscribe_page";
import SuccessPage from "./component/onboarding/success_page";


export default function Subscribe() {
    const [view, setView] = useState("subscribe");

    if (view === "subscribe") {
        return <SubscribePage setView={setView} />;
    } else if (view === "success") {
        return <SuccessPage />;
    }
}
