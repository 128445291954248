import React from "react";
import { getIntegersInRange } from "../../../lib/tools/numbers";

export default function PagesNavigations({
    mainClass,
    leads,
    currentPage,
    itemParPage,
    maxPageFetched,
    handlePagesModeClickNextPage,
}) {
    const isLastPageFull = leads.length === (maxPageFetched + 1) * itemParPage;
    const areThereLeadsLeft = isLastPageFull || currentPage !== maxPageFetched;

    const maxIndexAddition = areThereLeadsLeft ? 1 : 0;
    let maxIndex = Math.min(maxPageFetched + maxIndexAddition, currentPage + 2);
    const minIndex = Math.max(maxIndex - 4, 0);
    // increase maxIndex if more can be added to the right
    if (maxIndex - minIndex < 4 && maxIndex < maxPageFetched) {
        maxIndex = Math.min(maxPageFetched + maxIndexAddition, minIndex + 4);
    }
    const pagesIndexes = getIntegersInRange(minIndex, maxIndex);

    return <div className={mainClass}>
        <div className="nova_text-number-pages">{`Page ${currentPage + 1}`}</div>
        <div className="nova_holder-page-sectors">
            {/* <div className="nova_pass-page w-inline-block">
                <img src="./images/nova/arrow_down.svg" loading="lazy" width="12" alt="" className="nova_arrow" />
                <div className="nova_prev">Prev</div>
            </div> */}
            <div className="nova_holder-number-pages">
                {pagesIndexes.map(pageIdx => {
                    const isClicked = currentPage === pageIdx;
                    const classesAdditionnalElement = isClicked ? " selected" : "";

                    const onclickFunc = () => {
                        if (pageIdx === currentPage) {
                            return;
                        } else {
                            handlePagesModeClickNextPage(pageIdx);
                        }
                    }

                    return <div key={pageIdx} onClick={onclickFunc} className={`nova_number-page w-inline-block${classesAdditionnalElement}`}>
                        <div className={`nova_number-page-text${classesAdditionnalElement}`}>{pageIdx + 1}</div>
                    </div>
                })}
            </div>
            {/* <div className="nova_pass-page w-inline-block">
                <img src="./images/nova/arrow_down.svg" loading="lazy" width="12" alt="" className="nova_arrow" />
                <div className="nova_prev">Prev</div>
            </div> */}
        </div>
    </div>;
}
