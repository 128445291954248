function getFileExtension(filename) {
    return filename.split(".").pop();
}

function renameStringIntoFileName(originalString) {
    return originalString.replace(/ /g, "_");
}

function removeWhitespace(inputString) {
    return inputString.replace(/\s/g, '');
}

function extractPattern(inputString, regex) {
    const match = inputString.match(regex);
    return match ? match[0] : null;
}

function countWordsOfMinSize(inputString, minSize) {
    return inputString.split(/\s+/).filter(word => word.trim().length >= minSize).length;
}

function capitalizeFirstLetter(string) {
    if (string === null) return null;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function getFirstLetterCapitalised(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase()
}

function getNameInitials(firstname, lastname) {
    return `${getFirstLetterCapitalised(firstname)}${getFirstLetterCapitalised(lastname)}`;
}

function formatPhoneNumberWoParenthesis(str) {
    return str.replace(/[^0-9+]/g, '');
}

function getLastNCharacters(string, N) {
    if (string.length >= N) {
        return string.substring(string.length - N);
    } else {
        return string;
    }
}

function getPhoneNumberUniqueIdentifierPart(phone) {
    return getLastNCharacters(formatPhoneNumberWoParenthesis(phone), 9);
}

function keppOnlyNumericCharacters(inputString) {
    return inputString.replace(/\D/g, '');
}

function setDepartementInput(ev, callbackFunction) {
    const value = ev.target.value;
    setDepartementRawInput(value, callbackFunction);
}

function setDepartementRawInput(value, callbackFunction) {
    if (!value) {
        callbackFunction("");
        return;
    }

    const processedValue = keppOnlyNumericCharacters(value);
    if (!processedValue || isNaN(processedValue)) {
        callbackFunction("");
        return;
    }

    callbackFunction(`${processedValue}`);
}

function setNumericInput(ev, callbackFunction) {
    const value = ev.target.value;
    setNumericRawInput(value, callbackFunction);
}

function setNumericRawInput(value, callbackFunction) {
    if (!value) {
        callbackFunction("");
        return;
    }

    const processedValue = parseInt(value);
    if (!processedValue || isNaN(processedValue)) {
        callbackFunction("");
        return;
    }

    callbackFunction(`${processedValue}`);
}

const getCommentLinesCount = (txt) => {
    if (!txt) {
        return 1;
    }

    const potentialLines = txt.length / 50; // one line could rarely have more than 50 characters so we split
    const lineCount = txt.split('\n').length;
    return Math.max(Math.min(3, lineCount), 1, Math.floor(potentialLines));
}

module.exports = {
    getFileExtension,
    renameStringIntoFileName,
    removeWhitespace,
    extractPattern,
    countWordsOfMinSize,
    capitalizeFirstLetter,
    getNameInitials,
    getFirstLetterCapitalised,
    formatPhoneNumberWoParenthesis,
    getLastNCharacters,
    getPhoneNumberUniqueIdentifierPart,
    keppOnlyNumericCharacters,
    setDepartementInput,
    setDepartementRawInput,
    setNumericInput,
    setNumericRawInput,
    getCommentLinesCount,
};
