import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import { UserType } from "../context/enums";

export default function MenuTop({ currentView }) {
    const { auth, openMenu, setOpenMenu } = useContext(AuthContext);

    const getTopPart = () => {
        if (openMenu) {
            return <div className="nova_brand_positioner">
                <div className="nova_brand w-inline-block">
                    <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
                        <img src="./images/nova/logo-1.svg" loading="lazy" alt="" height="30" />
                    </Link>
                </div>
                <div onClick={() => setOpenMenu(false)} className="nova_close-button w-inline-block">
                    <img src="./images/nova/Icons-12.svg" loading="lazy" alt="" />
                </div>
            </div>;
        } else {
            return <div className="menu_closed_logo_container">
                <div className="nova_brand-closd w-inline-block">
                    <Link to='/' style={{ textDecoration: 'none', color: 'black' }}>
                        <img src="./images/nova/Vector-9.svg" loading="lazy" alt="" height="30" className="image-36" />
                    </Link>
                </div>
                <div onClick={() => setOpenMenu(true)} className="nova_button-open-menu w-inline-block">
                    <img src="./images/nova/Icons-12.svg" loading="lazy" alt="" className="nova_image-opn-menu" />
                </div>
            </div>;
        }
    }

    const getTitle = (title) => {
        if (openMenu) {
            return <div key={title} className="nova_title-fonctions">{title}</div>;
        }
        return null;
    }

    const getSectionLink = (id, title, link, icon, iconExt) => {
        const isActive = currentView === id;
        const classSuffix = isActive ? " active" : "";
        const iconSuffix = isActive ? "_active" : "";
        if (openMenu) {
            return <Link key={`${title}_${link}}]`} to={link} style={{ textDecoration: 'none', color: 'black' }}>
                <div className={`nova_fontions w-inline-block${classSuffix}`}>
                    <div className="nova_relative-box">
                        <img src={`./images/nova/menu_icons/${icon}${iconSuffix}.${iconExt}`} loading="lazy" alt="" className="nova" />
                    </div>
                    <div className={`nova_text-fonctions${classSuffix}`}>{title}</div>
                </div>
            </Link>;
        } else {
            return <Link key={`${title}_${link}}]`} to={link} style={{ textDecoration: 'none', color: 'black' }}>
                <div className="nova_fontions center w-inline-block">
                    <div className="nova_relative-box">
                        <img src={`./images/nova/menu_icons/${icon}${iconSuffix}.${iconExt}`} loading="lazy" alt="" className="nova close" />
                    </div>
                </div>
            </Link>;
        }
    }

    const getGeneralMenu = () => {
        const hasAccessToNewLeads = auth.has_access_to_b2bmed === true;
        const classSuffix = openMenu ? "" : " closed";
        return <div key="general" className={`nova_holder-fonctions${classSuffix}`}>
            {getTitle("General")}
            {getSectionLink("dashboard", "Dashboard", "/", "dashboard", "png")}
            {getSectionLink("leads_view", "Leads", "/leads", "leads", "png")}
            {hasAccessToNewLeads && getSectionLink("new_leads_view", "New Leads", "/new_leads", "add_leads", "svg")}
        </div>
    }

    const getAdminMenu = () => {
        let elements = [
            getTitle("Management"),
            getSectionLink("admin_users", "Utilisateurs", "/admin_users", "users", "png"),
            getSectionLink("admin_leads", "Leads", "/admin_leads", "leads", "png"),
            getSectionLink("admin_perf", "Performance", "/admin_performance", "performance", "png"),
        ]

        if (auth.email.endsWith('lead-access.fr')) {
            elements.push(getSectionLink("superadmin", "Superadmin", "/superadmin", "settings", "png"))
        }

        const classSuffix = openMenu ? "" : " closed";
        return <div key="admin" className={`nova_holder-fonctions${classSuffix}`}>
            {elements}
        </div>;
    }

    const getMenuContent = () => {
        let elements = [
            getGeneralMenu(),
        ]

        if (auth.user_type === UserType.MANAGER) {
            elements.push(getAdminMenu());
        }

        return elements;
    }

    const getMenuPart = () => {
        if (openMenu) {
            return <div className="nova_menu_content">
                {getMenuContent()}
            </div>;
        } else {
            return <div className="nova_menu_content closed">
                {getMenuContent()}
            </div>;
        }
    }

    if (!auth || !auth?.user_type) {
        return null;
    }

    if (openMenu) {
        return <div className="nova_brand-cont">
            {getTopPart()}
            {getMenuPart()}
        </div>
    } else {
        return <div className="nova_brand-cont close">
            <div className="nova_brand-close-old closed">
                {getTopPart()}
            </div>
            {getMenuPart()}
        </div>
    }
}
