import React from "react";


export default function MessageFormInput({ label, value, setValue, setValueFromRawEvent, placeholder }) {
    function setValueImpl(e) {
        if (setValueFromRawEvent) {
            setValue(e);
        } else {
            setValue(e.target.value);
        }
    }

    return <div className="nova_holder-form-conversation-chatbot">
        <label className="field-label-2">{label}</label>
        <input
            value={value}
            onChange={setValueImpl}
            className="text-field-3 w-input"
            maxLength={255}
            placeholder={placeholder}
            type="text"
        />
    </div>;
}
