import React, { useState } from "react";
import { Link } from "react-router-dom";
import Toast from "../ux/toast";
import BottomLeftLegal from "./bottom_left_legal";
import RightHalfScreenImage from "./right_half_screen_image";
import Step from "./step";
import TopLeftLogo from "./top_left_logo";


// todo(3) changer image ici!!! right side
export default function SuccessPage() {
    const [toast, setToast] = useState(null);

    const getCongratulationPart = () => {
        return <div className="nova_second-screen-onboarding">
            <div className="nova_uppart-second-screen">
                <div className="nova_celebration-login">
                    <img src="./images/nova/onboarding_success.svg" loading="lazy" alt="" />
                    <div className="nova_holder-vertacal-text">
                        <div className="nova_heading">Bienvenue chez Nova!</div>
                        <div className="text-block-43">Nova, c'est l'outil ultime pour obtenir, acheter, gérer et suivre vos prospects. Pour vous et toute votre équipe.</div>
                    </div>
                    <Link to={'/'} style={{ textDecoration: 'none', color: 'white' }} className="nova_login-button w-button">
                        Continuer
                    </Link>
                </div>
            </div>
            <Step step={2} totalSteps={2} />
        </div>;
    }

    const getLeftPart = () => {
        return <div className="nova_leftside-onboarding">
            <TopLeftLogo />
            {getCongratulationPart()}
            <BottomLeftLegal />
        </div>;
    }

    return <div className="nova_onboarding-section">
        {toast !== null && <Toast text={toast.text} severity={toast.severity} duration={toast.duration} setToast={setToast} />}
        {getLeftPart()}
        <RightHalfScreenImage imgSrc="presentation_chatbot.png" isImageLargeWithCenteredContent={true} />
    </div>;
}
