import React, { useContext } from "react";
import { removeLeads, storeFeatureUsage } from "../../../api/axios";
import AuthContext from "../../../context/AuthProvider";
import AIMessage from "../basics/ai_message";
import AIMessageText from "../basics/ai_message_text";


export default function RemoveLeads({ setInputText, setIsWaiting, setMessages }) {
    const { auth } = useContext(AuthContext);

    const handleRemoveNotInterestedLeads = () => {
        setInputText("");
        setIsWaiting(true);
        storeFeatureUsage(auth.id, "chatbot_remove_leads_except_interste", null);
        removeLeads(auth.id, true)
            .then((data) => {
                const removedCount = data.data.count_removed;
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: `${removedCount} leads enlevés avec succès.` }]);
                setIsWaiting(false);
            }).catch(err => {
                setIsWaiting(false);
                setMessages(prevMessage => [...prevMessage, { role: "assistant", text: "Erreur, veuillez réessayer." }]);
            });
    }

    return <AIMessage containsForm={false}>
        <AIMessageText
            text="Vous pouvez enlever vos leads.
                    Vos leads dont les status sont: Intéressés, RDV pris, RDV annulé, RDV non pris, ou Clients ne seront pas enlevés."
        />
        <div className="nova_holder-submit-button">
            <div onClick={handleRemoveNotInterestedLeads} className={`nova_button w-button`}>
                Enlever les leads
            </div>
        </div>
    </AIMessage>;
}
